/* eslint-disable react/jsx-fragments */
import { Form, Input, InputNumber, message, Progress, Radio, Row, Space, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { ConfirmButtons, Modal, RadioContentWithTooltip } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import _ from "lodash";
import { prospectData } from "../../info";
import { HeaderTagsBar } from "../ExploreTable/ui/HeaderTagsBar";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { prospectDataSelector, resetFilterFormData } from "src/store/prospect_data";
import { userSelector } from "src/store/user";
import { exportProspects, getProsFiles } from "src/store/prospect_data/asyncRequests";
import { AppDispatch } from "src/store";

type ExportContactsProps = {
  open: boolean;
  onCancel: VoidFunction;
  totalSelectedProspects: number;
  setTotalSelectedProspects: React.Dispatch<React.SetStateAction<React.Key[]>>;
  selectedRowKeys: React.Key[];
};

const exportTooltipText = (
  <>
    Meetz will reverify your prospects information by going into your prospects LinkedIn in real time. <br />
    <br />
    {`If we can't confirm that they are still working at the same company, we won't charge you those prospect credits and won't extract them.`}
    <br />
    This option does not cost extra and is <b>recommended to use.</b>
  </>
);

export const ExportProspectsModal: FC<ExportContactsProps> = ({ open, onCancel, totalSelectedProspects, setTotalSelectedProspects, selectedRowKeys }) => {
  const [form] = Form.useForm();
  const [bulkLength, setBulkLength] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const { latestAppliedFilters, latestAppliedFiltersTog, selectProspects, totalProspectsAvailable, gotFirstHearbeatFiles, exportFiles } =
    useSelector(prospectDataSelector);
  let {
    // split_between_prosp_to_personalize,
    // new_prospectdata_or_personalized_email_permonth,
    // ai_personalized_emails_left,
    prospect_data_credits,
    new_payment_status,
    split_between_prosp_to_personalize,
    new_prospectdata_or_personalized_email_permonth,
    trial_credits,
  } = useSelector(userSelector);
  const isTrial = new_payment_status === "trial";

  if (isTrial) {
    const credUsed = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - prospect_data_credits;
    prospect_data_credits = trial_credits - credUsed;
    if (prospect_data_credits < 0) {
      prospect_data_credits = 0;
    }
  }

  const [maxBulk, setMaxBulk] = useState(0);
  const [selectedAmountCnt, setSelectedAmountCnt] = useState(0);
  useEffect(() => {
    setSelectedAmountCnt(Object.values(selectProspects).reduce((total, keys) => total + (keys?.length || 0), 0));
  }, [selectProspects]);

  useEffect(() => {
    // console.log(prospect_data_credits, selectedAmountCnt, "asdfasf");
    setMaxBulk(Math.min(prospect_data_credits || 0, totalProspectsAvailable || 0));
  }, [prospect_data_credits, totalProspectsAvailable]);

  const handleInputChange = (e) => {
    // console.log(maxBulk, "maxBulk");
    let value = e.target.value;
    // Remove leading zeros
    value = value.replace(/^0+/, "");
    if (isNaN(value) || value <= 0) {
      setErrorMessage("Please enter a valid number greater than 0");
      setBulkLength(0);
      form.setFieldsValue({ bulkAmount: 0 });
    } else if (value > maxBulk) {
      setErrorMessage(maxBulk === prospect_data_credits ? "Maximum credits" : "Maximum prospects that match your filters");
      setBulkLength(+(maxBulk || 0));
      form.setFieldsValue({ bulkAmount: +(maxBulk || 0) });
    } else {
      setErrorMessage("");
      setBulkLength(Number(value));
      form.setFieldsValue({ bulkAmount: Math.floor(Number(value)) });
    }
  };

  const handleMaxInputChange = (e) => {
    let value = e.target.value;
    // Remove leading zeros
    value = value.replace(/^0+/, "");
    if (isNaN(value) || value <= 0) {
      form.setFieldsValue({ bulkAmountCmpny: 0 });
    } else if (value > 100) {
      form.setFieldsValue({ bulkAmountCmpny: 100 });
    } else {
      form.setFieldsValue({ bulkAmountCmpny: Math.floor(Number(value)) });
    }
  };
  const [amntPerComp, setAmntPerComp] = useState<number>(0);

  const [formData, setFormData] = useState<any>();
  //  useEffect(() => {
  //   const formValues = form.getFieldsValue()
  //   setFormData(formValues)
  //   console.log(formValues,'formValues');
  // }, [form])
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    const filterFormDataCopy = _.cloneDeep(latestAppliedFilters);
    function mergeRanges(ranges) {
      const numericRanges = [];
      const nonNumericRanges = [];

      ranges.forEach((range) => {
        const [start, end] = range.split("-");
        const startNum = parseInt(start.replace(/\D/g, ""), 10);
        const endNum = end ? parseInt(end.replace(/\D/g, ""), 10) : null;

        if (!isNaN(startNum) && endNum !== null && !isNaN(endNum)) {
          numericRanges.push({ start: startNum, end: endNum });
        } else {
          nonNumericRanges.push(range);
        }
      });

      numericRanges.sort((a, b) => a.start - b.start);

      const mergedRanges = [];
      let currentRange = null;

      numericRanges.forEach((range) => {
        if (!currentRange) {
          currentRange = range;
        } else if (currentRange.end >= range.start - 1) {
          currentRange.end = Math.max(currentRange.end, range.end);
        } else {
          mergedRanges.push(currentRange);
          currentRange = range;
        }
      });

      if (currentRange) {
        mergedRanges.push(currentRange);
      }

      const mergedNumericRanges = mergedRanges.map((range) => `${range.start}-${range.end}`);
      return [...mergedNumericRanges, ...nonNumericRanges].join("&");
    }

    function mergeRevenueRanges(ranges) {
      const numericRanges = [];
      const nonNumericRanges = [];

      ranges.forEach((range) => {
        const [start, end] = range.split("-").map((str) => {
          if (str.includes("B")) {
            return parseInt(str.replace(/[$M+B]/g, ""), 10) * 1000;
          } else {
            return parseInt(str.replace(/[$M+B]/g, ""), 10);
          }
        });

        if (!isNaN(start) && !isNaN(end)) {
          numericRanges.push({ start, end });
        } else {
          nonNumericRanges.push(range);
        }
      });

      numericRanges.sort((a, b) => a.start - b.start);

      const mergedRanges = [];
      let currentRange = null;

      numericRanges.forEach((range) => {
        if (!currentRange) {
          currentRange = range;
        } else if (currentRange.end >= range.start - 1) {
          currentRange.end = Math.max(currentRange.end, range.end);
        } else {
          mergedRanges.push(currentRange);
          currentRange = range;
        }
      });

      if (currentRange) {
        mergedRanges.push(currentRange);
      }

      const mergedNumericRanges = mergedRanges.map((range) => {
        const start = range.start >= 1000 ? `${range.start / 1000}B` : `${range.start}M`;
        const end = range.end >= 1000 ? `${range.end / 1000}B` : `${range.end}M`;
        return `$${start}-$${end}`;
      });

      return [...mergedNumericRanges, ...nonNumericRanges].join("&");
    }

    filterFormDataCopy.headcount = filterFormDataCopy.headcount ? mergeRanges(filterFormDataCopy.headcount) : [];
    filterFormDataCopy.revenue = filterFormDataCopy.revenue ? mergeRevenueRanges(filterFormDataCopy.revenue) : [];

    function createFileName(data) {
      const keysOrder = [
        "jobTitle",
        "function",
        "seniorityLevel",
        "industry",
        "headcount",
        "revenue",
        "company",
        "companyUrl",
        "companySicCodes",
        "skills",
        "country",
        "state",
        "city",
      ];
      const shorthand = {
        jobTitle: "JOB",
        function: "FUNC",
        seniorityLevel: "LEVEL",
        industry: "IND",
        headcount: "HC",
        revenue: "REV",
        company: "COMP",
        companyUrl: "URL",
        companySicCodes: "SIC",
        skills: "SKILLS",
        country: "COUNTRY",
        state: "STATE",
        city: "CITY",
      };

      let fileName = keysOrder
        .map((key) => {
          if (Array.isArray(data[key])?.length) {
            return `${shorthand[key]}_${data[key]?.join("&")?.replace(/[\s,:]+/g, "")}`;
          }
          if (data[key]?.toString()?.replace(/[\s,:]+/g, "")) {
            return `${shorthand[key]}_${data[key]?.toString()?.replace(/[\s,:]+/g, "")}`;
          }
        })
        ?.filter(Boolean)
        ?.join("+");

      // return fileName.length > 100 ? fileName.substring(0, 100) : fileName;
      return fileName;
    }
    // console.log(filterFormDataCopy, "filterFormDataCopy");
    const fileNmae = createFileName(filterFormDataCopy);
    setFileName(fileNmae);
    form.setFieldsValue({ exportType: "Export Only The Verified Prospects", name: fileNmae });
  }, [latestAppliedFiltersTog]);

  const [pendingFile, setPendingFile] = useState(false);
  useEffect(() => {
    setPendingFile(exportFiles?.some((file) => file.id === "pending"));
  }, [exportFiles]);

  // console.log(filterFormDataCopy,'filterFormData',fileNmae);

  // useEffect(() => {
  //   const selectedBulkByInput: string[] = []
  //   for (let i = 0; i < bulkLength && i < prospectData.length; i++) {
  //     selectedBulkByInput.push((i + 1).toString())
  //   }

  //   setTotalSelectedProspects(selectedBulkByInput)
  // }, [bulkLength])

  const closeModal = () => {
    onCancel();
    // form.resetFields()
    setBulkLength(0);
  };

  // const onValueChange = (changedValues: any, allValues: any) => {
  //   // setFormData(allValues)
  //   console.log(allValues,'allValues');

  // }

  const startHeartBeatGetFiles = () => {
    // console.log("Starting Prospect Export startHeartBeatGetFiles");
    setInterval(() => {
      dispatch(getProsFiles());
    }, 2000);
  };

  const dispatch = useDispatch<AppDispatch>();
  const handleFormSubmit = () => {
    const formValues = form.getFieldsValue();
    // console.log(selectProspects,'selectProspects',formValues);
    message.destroy();
    message.info("Starting Prospect Export");
    dispatch(exportProspects({ latestAppliedFilters, selectProspects: Object?.values(selectProspects)?.flat() || [], ...formValues }));

    // more than  1 edxport will need it
    if (!gotFirstHearbeatFiles) {
      // to not duplicate the heartbeat
      // console.log("activated this");
      startHeartBeatGetFiles();
    }
    closeModal();
  };

  const fileNameFrm = form.getFieldValue("name");
  const typeSelected = form.getFieldValue("type");
  const amountToExport = form.getFieldValue("bulkAmount");

  // console.log(typeSelected,'typeSelected',amountToExport);

  const onValueChange = (changedValues: any, allValues: any) => {
    setFormData(allValues);
  };
  // console.log(selectProspects, "selectProspectsselectProspects");
  return (
    <Modal width={569} customClassName={"export-selected-contacts-modal"} open={open} title={"Save Prospect List"} onCancel={closeModal}>
      <HeaderTagsBar totalAmount={totalProspectsAvailable} selectedAmount={selectedAmountCnt} />
      <Typography.Paragraph className={"hint"} style={{ marginTop: "20px" }}>
        {prospect_data_credits ? (
          <>
            {" "}
            <b>Prospect Credits: </b>
            {prospect_data_credits} left
          </>
        ) : (
          ""
        )}
      </Typography.Paragraph>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onValuesChange={onValueChange}
        // onFinish={handleFormSubmit}
      >
        <Form.Item
          className={"export-name-item"}
          name={"name"}
          label={
            <>
              Prospect List Name{" "}
              <Tooltip
                placement={"top"}
                title={
                  <>
                    <b>{`Best Practice:`}</b> Use a name that easily explains who the audience is. Just like in Facebook ads the only thing you need to measure
                    in Meetz is which audience responds best to your value proposition.
                  </>
                }
              >
                <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
              </Tooltip>
            </>
          }
          rules={[{ required: true, message: "Add a export name" }]}
        >
          <Input placeholder={"Extraction Name"} defaultValue={fileName} />
        </Form.Item>
        <Form.Item
          className={"export-type-item"}
          name={"type"}
          label={
            <>
              Which prospects to save
              <Typography.Paragraph className={"hint"} style={{ marginLeft: "10px", marginTop: "2px" }}>
                {prospect_data_credits ? "" : " - No prospect credits left, upgrade your account."}
              </Typography.Paragraph>
            </>
          }
          rules={[{ required: true, message: "Select Export Type" }]}
        >
          <Radio.Group style={{ width: "100%" }}>
            <Space direction={"vertical"}>
              <Radio value={"Bulk Export"} disabled={!prospect_data_credits}>
                <>
                  Bulk Save{" "}
                  <Tooltip placement={"top"} title={"Meetz will bulk export prospects that match your filters"}>
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                  </Tooltip>
                </>
              </Radio>
              {selectedAmountCnt && prospect_data_credits ? (
                <Radio value={"Export Selected Prospects"}>
                  {<>Save Selected Prospects {prospect_data_credits < selectedAmountCnt ? `- Maximum Export ${prospect_data_credits}` : ""}</>}
                </Radio>
              ) : (
                <Tooltip placement={"right"} title={"No specific prospects selected"}>
                  <Radio value={"Export Selected Prospects"} disabled>
                    {"Export Selected Prospects"}
                  </Radio>
                </Tooltip>
              )}
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          className={"export-method-item"}
          name={"exportType"}
          label={"Real time verification of prospects?"}
          rules={[{ required: true, message: "Choose how to export" }]}
        >
          <Radio.Group style={{ width: "100%" }} defaultValue={"Export Only The Verified Prospects"}>
            <Space direction={"vertical"}>
              <Radio value={"Export Only The Verified Prospects"}>
                <RadioContentWithTooltip radioTitle={"Save Only The Verified Prospects"} tooltipTitle={exportTooltipText} />
              </Radio>
              <Radio value={"Export All"}>{"Export All"}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {typeSelected === "Bulk Export" && (
          <Form.Item
            name={"bulkAmount"}
            label={"Amount To Bulk Save"}
            rules={[{ required: true, message: "Add amount" }]}
            validateStatus={errorMessage ? "error" : ""}
            help={errorMessage}
            // value
          >
            <Input placeholder={"1"} onChange={handleInputChange} value={bulkLength} max={maxBulk} controls={false} />
          </Form.Item>
        )}
        {typeSelected === "Bulk Export" && (
          <Form.Item name={"bulkAmountCmpny"} label={"Maximum Contacts To Get From Every Company: Recommended 3"} rules={[{ required: false }]}>
            {/* <Input placeholder={"No Limit"} onChange={value => setAmntPerComp(Number(value))} min={1}
           max={10} controls={false} /> */}
            <Input placeholder={"No Limit"} onChange={handleMaxInputChange} />
          </Form.Item>
        )}

        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Save"}
          onCancel={closeModal}
          disabled={!prospect_data_credits || !typeSelected || (typeSelected === "Bulk Export" && !amountToExport) || pendingFile || !fileNameFrm?.length}
          disabledExplanation={pendingFile ? "You have a file being saved now." : ""}
          handleSubmit={() => handleFormSubmit()}
        />
      </Form>
    </Modal>
  );
};
