import React, { FC, ForwardedRef, Suspense, forwardRef, lazy, useContext, useEffect, useState } from "react";

import PageLayout from "../../components/layout/PageLayout";
import { MeetingLinks } from "../../components/pages/meeting-links";
import { AdditionalAvailabilityDrawer } from "../../components/pages/meeting-links/ui/AdditionalAvailabilityDrawer";
import { DeleteMeetingLinkModal } from "../../components/pages/meeting-links/ui/DeleteMeetingLinkModal";
import { MeetingLinkDrawer } from "../../components/pages/meeting-links/ui/MeetingLinkDrawer";
import { ConfirmButtons } from "../../components/ui";
import { useBreakpoints } from "../../helpers/useBreakpoints";
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal";
import { message } from "antd";
import { reactiveLinksSelector, resetOutcome } from "src/store/reactive-links-settings";
import { addCalBe, addMeetingLink, zoomIntegrate } from "src/store/reactive-links-settings/asyncRequests";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { MeetingLinksContext } from "src/components/app/context/MeetingLinksContext";
import { Loader } from "src/components/layout";
import { AssistantSettingsDrawer } from "src/components/layout/AssistantSettingsDrawer";
// import {AssistantSettingsDrawer} from "src/components/layout/AssistantSettingsDrawer"
// import dynamic from 'next/dynamic';
// const {AssistantSettingsDrawer} = lazy(() => import("src/components/layout/AssistantSettingsDrawer"));

// const AssistantSettingsDrawer = dynamic(() => import("src/components/layout/AssistantSettingsDrawer"), { ssr: false })

interface MeetingLinksPageProps {
  meetingLinksAddLinkRef?: ForwardedRef<HTMLDivElement>;
  meetingLinksAdditionalAvailabilityRef?: ForwardedRef<HTMLDivElement>;
  meetingLinksCardRef?: ForwardedRef<HTMLDivElement>;
  isTourStep?: boolean;
  openAdditionalAvailabilityDrawer?: boolean;
  disableModals?: boolean;
}

const MeetingLinksPage: FC<MeetingLinksPageProps> = forwardRef<HTMLDivElement, MeetingLinksPageProps>(
  (
    { isTourStep, meetingLinksCardRef, meetingLinksAddLinkRef, meetingLinksAdditionalAvailabilityRef, openAdditionalAvailabilityDrawer, disableModals },
    ref
  ) => {
    const [isAdditionalDrawerOpened, setIsAdditionalDrawerOpened] = useState(false);
    const [isAddMeetingLinkDrawerOpened, setIsAddMeetingLinkDrawerOpened] = useState(false);
    const [isEditAiMeeting, setIsEditAiMeeting] = useState(false);
    const [isEditMeetingLinkDrawerOpened, setIsEditMeetingLinkDrawerOpened] = useState(false);
    const [isDeleteMeetingLinkModalOpened, setIsDeleteMeetingLinkModalOpened] = useState(false);
    const [isAssistantSettingsDrawerOpened, setIsAssistantSettingsDrawerOpened] = useState(false);
    const { isDesktop } = useBreakpoints();

    const openAdditionalDrawer = () => {
      setIsAdditionalDrawerOpened(true);
    };

    const closeAdditionalDrawer = () => {
      setIsAdditionalDrawerOpened(false);
    };

    const openEditMeetingLinkDrawer = (isAiEditing) => {
      setIsEditAiMeeting(isAiEditing);
      setIsEditMeetingLinkDrawerOpened(true);
    };

    const closeEditMeetingLinkDrawer = () => {
      setIsEditMeetingLinkDrawerOpened(false);
    };

    const [drawerKey, setDrawerKey] = useState(0);
    const openDeleteMeetingLinkModal = () => {
      setIsDeleteMeetingLinkModalOpened(true);
    };
    const openAiAssistantSettings = () => {
      setDrawerKey((prevKey) => prevKey + 1);
      setIsAssistantSettingsDrawerOpened(true);
    };

    const closeDeleteMeetingLinkModal = () => {
      setIsDeleteMeetingLinkModalOpened(false);
    };

    // const openAddMeetingLinkModal = () => {
    //   setIsAddMeetingLinkDrawerOpened(true)
    // }

    const closeAddMeetingLinkModal = () => {
      setIsAddMeetingLinkDrawerOpened(false);
    };

    const dispatch = useDispatch<AppDispatch>();

    const { successfullUpdateMsg, failedUpdateMsg } = useSelector(reactiveLinksSelector);

    useEffect(() => {
      if (successfullUpdateMsg) {
        message.destroy();
        message.success(successfullUpdateMsg);
        dispatch(resetOutcome());
      }
    }, [successfullUpdateMsg]);
    useEffect(() => {
      if (failedUpdateMsg) {
        message.destroy();
        message.error(failedUpdateMsg);
        dispatch(resetOutcome());
      }
    }, [failedUpdateMsg]);
    useEffect(() => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => typeof prop === "string" && searchParams.get(prop),
      });
      const err = params?.error;
      const email = params?.em;
      const id = params?.id;
      const type = params?.type;
      const iframe = params?.iframe;
      const meetplat = params?.meetplat;
      // console.log(params, "paramsss");
      // const connPlatformInfo = params?.connPlatformres;
      // const connPlatformError = params?.connPlatformError;
      if (meetplat === "zoom") {
        if (err) {
          let value = err;
          if (!value || value === "undefined") {
            value = "Failed Zoom authorization";
          }

          message.destroy();
          message.error(value || "Couldn't connect your Zoom. Make sure you have permission to connect.", 7000);
        } else {
          // const  id = params?.id;
          // const  account_id = params?.account_id;
          // const  timezone = params?.timezone;
          // const  encoded_access_token = params?.encoded_access_token;
          // const  encoded_refresh_token = params?.encoded_refresh_token;
          // const  email = params?.email;
          const zoomcode = params?.zoomcode;
          // console.log(zoomcode, "dddddddddd", params);
          const data = { zoomcode };
          message.destroy();
          message.info("Connecting to your Zoom now...");
          dispatch(zoomIntegrate(data));
          openAdditionalDrawer();
        }
      } else if (meetplat === "teams") {
        const status = params?.status;

        if (status === "teamssuccess") {
          message.destroy();
          message.success("Successfully connected to your Teams conferencing");
          openAdditionalDrawer();
        } else if (status === "noaccess") {
          message.destroy();
          message.error(
            "Notice: You MUST use either a Work or School account in order to create a Teams meeting. If you just created your Microsoft account wait up to 24 hours before trying again.",
            15000
          );
        } else if (status === "teamserror") {
          message.destroy();
          message.error("Couldn't connect to your Teams conferencing platform. Make sure you have permission to connect.");
        }
      }
      //     if(connPlatformInfo){
      //       if(connPlatformInfo==='zoom'){
      //       message.destroy()
      // message.info("Connecting to your Zoom now...") }
      //       if(connPlatformInfo==='teams'){
      //       message.destroy()
      // message.success("Successfully connected to your Teams conferencing!") }
      //     }
      //     if(connPlatformError){
      //       if(connPlatformError==='zoom'){
      //    message.destroy()
      //     message.error("Couldn't connect your Zoom. Make sure you have permission to connect.")}
      //     if(connPlatformError==='teamnoaccess'){
      //         message.destroy()
      //     message.error("Notice: You MUST use either a Work or School account in order to create a Teams meeting. If you just created your Microsoft account wait up to 24 hours before trying again.",15000)
      //     }
      //     if(connPlatformError==='teamserror'){
      //         message.destroy()
      //     message.error("Couldn't connect to your Teams conferencing platform. Make sure you have permission to connect.")
      //     }
      //     }
      else if (err) {
        // dispatch(calErr({ email, type }));
        message.destroy();
        message.error("Failed to connect your email address. Allow all permissions in order for it to work.");
        openAdditionalDrawer();
      } else if (email && id) {
        dispatch(addCalBe({ id }));
        message.destroy();
        message.info("Connecting your calendar now..."); // not 100% true still has to go to backend in above dispatch but most likely will be ok in this case

        if (iframe === "true") {
          const jwt = localStorage.getItem("token");
          if (jwt) {
            window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
          } else {
            window.location.href = `https://marketing.deal.ai/apps/app/meetz`;
          }
          return;
        }
        openAdditionalDrawer();
      }
      window.history.pushState({}, "", "/meeting-links");
    }, []);

    const addLink = () => {
      message.destroy();
      message.info("Adding your meeting link...");
      dispatch(addMeetingLink());
    };
    const { meetingLinks, setSelectedMeetingLinkId } = useContext(MeetingLinksContext);

    return isDesktop ? (
      <PageLayout
        pageTitle={"Scheduling Links"}
        rightHeaderContent={
          <ConfirmButtons
            leftBtnRef={meetingLinksAddLinkRef}
            leftButtonTitle={meetingLinks?.length >= 9 ? "" : "Add link"}
            rightButtonTitle={"Meeting settings"}
            width={"fit-content"}
            style={{ gap: 18 }}
            handleSubmit={openAdditionalDrawer}
            onCancel={addLink}
          />
        }
        isTableContent={false}
      >
        <MeetingLinks
          meetingLinksCardRef={meetingLinksCardRef}
          openEditMeetingLinkDrawer={openEditMeetingLinkDrawer}
          openDeleteMeetingLinkDrawer={openDeleteMeetingLinkModal}
          openAiAssistantSettings={openAiAssistantSettings}
        />
        {!disableModals && (
          <React.Fragment>
            {openAdditionalAvailabilityDrawer && <AdditionalAvailabilityDrawer onClose={closeAdditionalDrawer} />}
            {isAdditionalDrawerOpened && <AdditionalAvailabilityDrawer onClose={closeAdditionalDrawer} />}
            {isAddMeetingLinkDrawerOpened && <MeetingLinkDrawer isEditAiMeeting={false} isNewMeeting={true} onClose={closeAddMeetingLinkModal} />}
            {isEditMeetingLinkDrawerOpened && <MeetingLinkDrawer isEditAiMeeting={isEditAiMeeting} onClose={closeEditMeetingLinkDrawer} />}
            {isDeleteMeetingLinkModalOpened && <DeleteMeetingLinkModal onClose={closeDeleteMeetingLinkModal} />}

            {isAssistantSettingsDrawerOpened && (
              <React.Fragment key={drawerKey}>
                <AssistantSettingsDrawer
                  editorKey={drawerKey}
                  onClose={() => {
                    setDrawerKey((prevKey) => prevKey + 1);
                    setIsAssistantSettingsDrawerOpened(false);
                  }}
                />{" "}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </PageLayout>
    ) : (
      <GoToDesktopModal />
    );
  }
);

export default MeetingLinksPage;
