/* eslint-disable react/jsx-fragments */
import { CollapseProps, Input, Radio, Space, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, ConfirmButtons, CustomLabel, Modal, TextEditor } from "src/components/ui";
import { AppDispatch } from "src/store";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { generateOneAiEmail } from "src/store/outreach-campaigns/asyncRequests";
import { userSelector } from "src/store/user";

type ViewAiEmailModalProps = {
  open: boolean;
  isAuto: boolean;
  onCancel: VoidFunction;
  prospectId: number;
};

export const ViewAiEmailModal: FC<ViewAiEmailModalProps> = ({ isAuto, open, onCancel, prospectId }) => {
  // const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6", "Step 7"]
  const closeModal = () => {
    onCancel();
  };

  const handleFormSubmit = () => {
    console.log("Created");
    onCancel();
  };
  let { split_between_prosp_to_personalize, new_prospectdata_or_personalized_email_permonth, ai_personalized_emails_left, trial_credits, new_payment_status } =
    useSelector(userSelector);
  const isTrial = new_payment_status === "trial";
  if (isTrial) {
    const credUsedAi = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - ai_personalized_emails_left;
    ai_personalized_emails_left = trial_credits - credUsedAi;
    if (ai_personalized_emails_left < 0) {
      ai_personalized_emails_left = 0;
    }
  }
  const [pros, setPros] = useState({});

  const { eachStepsAnalytics, loadingGenAiForPros } = useSelector(outreachCampaignSelector);

  // console.log(eachStepsAnalytics,'eachStepsAnalytics')

  useEffect(() => {
    let found = false;

    for (const key in eachStepsAnalytics) {
      // console.log(key,'etsaojkfn',eachStepsAnalytics);
      if (eachStepsAnalytics[key] && eachStepsAnalytics[key]?.firstTenProsReady?.length > 0) {
        for (let i = 0; i < eachStepsAnalytics[key].firstTenProsReady.length; i++) {
          // console.log(`12312312312Found: ${eachStepsAnalytics[key][i]}`);
          const pros = eachStepsAnalytics[key].firstTenProsReady[i];
          // console.log(pros,'12312312312');
          if (pros.id === prospectId) {
            setPros(pros);
            found = true;
            break;
          }
        }
      }
      if (found) {
        break;
      }
    }
  }, [eachStepsAnalytics, prospectId]);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Do I need to generate Ai Emails from this window every time?",
      children: (
        <>
          No, Meetz will auto generate the personalized emails for you.{" "}
          {isAuto
            ? `The emails in this step will be automatically generated right before they are sent out, so there is no need for you to do anything else on your side.`
            : `You need to approve this step in order for the Ai Personalized Emails to be generated and sent out.`}
        </>
      ),
    },
    {
      key: "2",
      label: "Can I send emails from this window?",
      children: (
        <>
          No, you can't send emails from this window.{" "}
          {isAuto
            ? `Meetz will automatically send out the email when it's the prospects turn to get it. Meetz sends out emails slowly to ensure you don't get into the spam folder, click on "Manage Emails" if you want to increase or decrease daily limits.`
            : `You need to approve this step in order for the Ai Personalized Emails to be generated and sent out.`}
        </>
      ),
    },
    // {
    //   key: "3",
    //   label: "How to change the output?",
    //   children: (
    //     <>
    //       Ai personalized emails are generated on an individual level based on a few different sources including your prospects LinkedIn activity. If you want
    //       to change the style of the Ai personalized email click on "Edit Sequence" and edit the Ai output.
    //     </>
    //   ),
    // },
    {
      key: "4",
      label: "What if I want an email template that doesn't change?",
      children: (
        <>
          That's what the Templated Email steps are for. 🙂
          <br />
          <br />
          In that case go to "Edit Sequence" remove the Ai Personalized Email step and add the Templated Email.
        </>
      ),
    },
    {
      key: "5",
      label: "What if I run out of Ai Personalized Emails mid campaign?",
      children: <>There's a backup templated email that will be sent out instead</>,
    },
  ];

  const subject = pros?.ai_generated_email?.subject;
  const body = pros?.ai_generated_email?.body;

  const dispatch = useDispatch<AppDispatch>();

  const genAiEmail = () => {
    // message.info('sdafdkaslfn')
    message.destroy();
    message.info({
      content: `Generating Ai email, this can take up to 3 minutes since we check your prospect's online activity and try to match it with your business.`,
      duration: 240,
    });
    dispatch(generateOneAiEmail({ prosId: prospectId }));
  };

  const haveAiCreds = ai_personalized_emails_left > 0;
  // console.log(haveAiCreds, "haveAiCreds");
  return (
    <Modal width={530} open={open} title={"Ai Personalized Email"} onCancel={closeModal}>
      {/* <Radio.Group style={{ display: "flex", flexDirection: "column", gap: 8, marginBottom: 28 }}>
        {steps.map((step, index) => (
          <Radio key={`${index}-${step}`} value={step}>
            {step}
          </Radio>
        ))}
      </Radio.Group> */}
      Prospect's detail:
      <Space
        // direction="vertical"
        size={12}
        style={{
          padding: "10px",
          border: "1px solid #eaeaea",
          borderRadius: "8px",
          marginBottom: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Paragraph className="contact-name" style={{ marginBottom: 0 }}>
          <div>
            <span style={{ fontWeight: 500 }}>Name:</span> {pros.sales_prospect?.first_name || ""} {pros.sales_prospect?.last_name || ""}
          </div>
          {pros.sales_prospect?.title && (
            <div>
              <span style={{ fontWeight: 500 }}>Title:</span> {pros.sales_prospect?.title}
            </div>
          )}
          {pros.sales_prospect?.company_name && (
            <div>
              <span style={{ fontWeight: 500 }}>Company Name:</span> {pros.sales_prospect?.company_name}
            </div>
          )}
        </Typography.Paragraph>
        {/* {aiEmailId===idSel && <Button className="link" style={{ marginLeft: 'auto' }}>View Ai Email</Button>} TODOF later add call 1 person */}
      </Space>
      <div style={{ marginTop: "20px" }}>
        <CustomLabel title={"Subject Line"} />
        <Input value={subject} style={{ pointerEvents: "none", backgroundColor: "#f7f7f7" }} />
      </div>
      <div style={{ marginTop: "20px" }}>
        <CustomLabel title={"Email Body"} />
        <TextEditor
          content={body || "No Ai Personalized Email Generated Yet, Generate Below"}
          readOnly
          // isMaxHeight
        />
        <br />
        <br />
      </div>
      <ConfirmButtons
        leftButtonTitle={body ? "Save & Go Back" : "Back"}
        rightButtonTitle={body ? "Replace And Generate New Email" : "Generate Email"}
        onCancel={closeModal}
        handleSubmit={genAiEmail}
        loading={loadingGenAiForPros}
        loadingText="Generating"
        disabled={!haveAiCreds}
        disabledExplanation="No Ai Personalization credits left, Meetz will send this step with your backup email"
      />
      <br />
      FAQ:
      <br />
      <br />
      <Collapse items={items} />
      <div>* Every Ai Personalized Email generation will use 1 Ai Personalized Email credit</div>
    </Modal>
  );
};
