import { Form, FormInstance, Input, message } from "antd";
import React, { FC, useEffect } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { valuePropUpd } from "src/store/outreach-campaigns/asyncRequests";

type SaveValueModalProps = {
  editId?: number;
  open: boolean;
  onCancel: VoidFunction;
  defaultVal: string;
  defaultTitle: string;
};

export const SaveValueModal: FC<SaveValueModalProps> = ({ defaultVal, open, onCancel, editId, defaultTitle }) => {
  const [form] = Form.useForm();

  const closeModal = () => {
    onCancel();
  };

  useEffect(() => {
    // console.log(defaultVal,'callToAction');
    form.setFieldsValue({ body: defaultVal, name: defaultTitle });
  }, [defaultVal, open, defaultTitle]);

  // const handleFormSubmit = () => {
  //   console.log("Saved")
  //   onCancel()
  // }
  const dispatch = useDispatch();
  const handleFormSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        // console.log(values, "updatedValues");
        message.info(`${editId ? "Updating the" : "Saving your"} value proposition...`, 20);

        dispatch(valuePropUpd({ action: editId ? "edit" : "save", title: values?.name, value_prop: values?.body, indexToEdit: editId }));
        onCancel();
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
        return;
      });
  };
  // .finally(() => {

  // })}

  return (
    <Modal width={530} open={open} title={editId ? "Edit Saved Value Proposition " : "Save Value Proposition"} onCancel={closeModal}>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        // onFinish={handleFormSubmit}
      >
        <Form.Item name={"name"} label={"Name"} initialValue={null} rules={[{ required: true, message: "Please enter a title for the value proposition" }]}>
          <Input placeholder={editId ? "Finance and C-level" : "Example: Helping marketing departments with design"} />
        </Form.Item>
        <Form.Item
          name={"body"}
          rules={[{ required: true, message: "Please enter the value proposition" }]}
          label={"Value proposition"}
          initialValue={editId ? "A design system for enterprise-level products. Create an efficient and enjoyable work experience." : null}
        >
          <TextArea placeholder={"A design system for enterprise-level products. Create an efficient and enjoyable work experience."} />
        </Form.Item>
      </Form>
      <ConfirmButtons rightButtonTitle={"Save"} leftButtonTitle={"Back"} onCancel={closeModal} handleSubmit={handleFormSubmit} />
    </Modal>
  );
};
