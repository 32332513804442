import { Button, Checkbox, Flex, Input, Radio, Row, Select, Tooltip, Typography } from "antd";
import { FC, Fragment, useEffect, useState } from "react";
import { ConfirmButtons, CustomSelect, Loader, Modal, StatusTag, TableHeader } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { phonesAvailable } from "../../info";
import { CheckAddressModal } from "./CheckAddressModal";
import "./index.less";
import { COUNTRIES_TWILIO, ISOCOUNTRIES } from "src/pages/constant";
import { useDispatch, useSelector } from "react-redux";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { addFreeNum, listPhoneNums } from "src/store/outreach-phones/asyncRequests";
import { userSelector } from "src/store/user";

export const AddNumberModal: FC<{
  open: boolean;
  onCancel: VoidFunction;
}> = ({ open, onCancel }) => {
  const { phone_numbers_available, loadingNewPhoneNum, addressComplete, phoneNumbersLeft } = useSelector(outreachPhonesSelector);
  // console.log(addressComplete, "addressComplete");
  const [countrySelected, setCountrySelected] = useState("");
  const [checked, setChecked] = useState(true);
  const [areaCode, setAreaCode] = useState(true);
  const [tempValue, setTempValue] = useState("");
  const [searchAreaCode, setSearchAreaCode] = useState(null);

  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  const handleSearchClick = () => {
    setSearchAreaCode(tempValue);
  };
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>({});
  // const [isPhonesAvailable, setIsPhonesAvailable] = useState(true)
  const [openCheckAddressModal, setOpenCheckAddress] = useState(false);
  // const countries = ["United States", "Ukraine", "United Arabian Emirates"]

  const dispatch = useDispatch();

  useEffect(() => {
    if (countrySelected?.length) {
      dispatch(
        listPhoneNums({
          country: countrySelected,
          areaCode: (countrySelected === "US" || countrySelected === "CA") && searchAreaCode ? searchAreaCode : null,
          onlySmsNum: checked,
        })
      );
    }
  }, [countrySelected, checked, searchAreaCode]);

  const closeModal = () => {
    setCountrySelected("");
    setSelectedPhoneNumber(null);
    onCancel();
  };

  const showCheckAddressModal = () => {
    setOpenCheckAddress(true);
  };
  const closeCheckAddressModal = () => {
    setOpenCheckAddress(false);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleCountryChange = (value: string) => {
    setCountrySelected(value);
  };

  const handleSubmit = () => {
    const foundIsoCode =
      ISOCOUNTRIES.find((country) => country.label === (addressComplete?.iso_country || addressComplete?.isoCountry)?.trim())?.value ||
      addressComplete?.iso_country ||
      addressComplete?.isoCountry?.trim();
    // console.log(addressComplete, "addressCompleteaddressComplete");
    dispatch(
      addFreeNum({
        compeleteAddress: { ...addressComplete, postalCode: addressComplete?.postalCode || addressComplete?.postal_code, isoCountry: foundIsoCode },
        phoneCountry: selectedPhoneNumber?.isoCountry,
        phoneNum: selectedPhoneNumber?.phoneNumber,
      })
    );
    onCancel();
  };
  const client = useSelector(userSelector);

  const isButtonDisabled =
    !selectedPhoneNumber?.phoneNumber || !addressComplete?.street || addressComplete?.street === "notloaded" || !client?.new_subscription_plans;
  // console.log(phone_numbers_available,'phone_numbers_available');

  return (
    <Modal customClassName={"add-number-modal"} open={open} title={"Get Phone Number"} closeIcon={<SvgIcon type={"close"} />} onCancel={closeModal}>
      <Typography.Paragraph className={"block-title"}>Select the country to get a number from</Typography.Paragraph>
      <CustomSelect options={COUNTRIES_TWILIO} onChange={handleCountryChange} value={countrySelected} />
      {/* <Select
        placeholder={"Select Country"}
        suffixIcon={<SvgIcon type={"selectArrow"} />}
        onChange={handleCountryChange}
        
      >
        {COUNTRIES_TWILIO.map((value, index) => (
          <Select.Option key={index} value={value?.countryCode}>
            {value?.country}
          </Select.Option>
        ))}
      </Select> */}
      {/* <Checkbox className={"checkbox-sms"} onChange={handleCheck} checked={checked}>
        Display numbers that can get SMS
      </Checkbox> */}
      {countrySelected !== "" && (
        <Fragment>
          {(countrySelected === "US" || countrySelected === "CA") && (
            <Input.Search
              className={"search-input"}
              placeholder={"Search Area Code..."}
              allowClear
              // prefix={<SvgIcon type={"search"} />}
              enterButton
              value={tempValue}
              onSearch={handleSearchClick}
              onChange={handleInputChange}
              style={{ maxWidth: "unset", marginBottom: "25px" }}
            />
          )}
          {loadingNewPhoneNum ? (
            <div style={{ height: "100px", padding: "30px" }}>
              {" "}
              <Loader size="large" />
            </div>
          ) : phone_numbers_available?.length ? (
            <Radio.Group
              className={"phones-available-radio-group"}
              value={selectedPhoneNumber?.phoneNumber}
              // onChange={e => setSelectedPhoneNumber(e.target.value)}
            >
              {phone_numbers_available.map((value, index) => (
                <Row key={index} justify={"space-between"}>
                  <Radio
                    value={value?.phoneNumber}
                    onClick={() => {
                      setSelectedPhoneNumber(value);
                    }}
                  >
                    {value?.friendlyName || value?.phoneNumber}
                  </Radio>
                  {/* <Button className={"link check-address"} onClick={showCheckAddressModal}>
                    Check Address
                  </Button> */}
                </Row>
              ))}
            </Radio.Group>
          ) : (
            <Typography.Paragraph className={"empty-state"}>No Numbers Found. Try a Different Area Code or Check Your Input</Typography.Paragraph>
          )}
          <Flex style={{ marginBottom: "20px", alignItems: "center" }}>
            <div>
              Your address
              <Tooltip
                placement={"top"}
                title={
                  <>Meetz provides a phone number for free as part of the subscription. In order to proceed a local address is required to get a phone number</>
                }
              >
                <SvgIcon type={"tooltip"} style={{ marginLeft: 2, cursor: "pointer" }} />
              </Tooltip>
            </div>

            <StatusTag
              value={addressComplete?.street === "notloaded" ? "Loading..." : addressComplete?.street ? <u>Update</u> : <u>None</u>}
              color={addressComplete?.street === "notloaded" ? "processing" : addressComplete?.street ? "success" : "error"}
              style={{ marginLeft: 15, marginTop: 5, padding: "6px 16px", cursor: addressComplete?.id === "notloaded" ? "default" : "pointer" }}
              onClick={() => addressComplete?.id !== "notloaded" && showCheckAddressModal()}
            />
          </Flex>
        </Fragment>
      )}
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Add Number"}
        onCancel={onCancel}
        handleSubmit={handleSubmit}
        disabled={isButtonDisabled}
        disabledExplanation={
          !client?.new_subscription_plans
            ? "Phone number included only in active subscriptions"
            : !phoneNumbersLeft
            ? "No more free phone numbers left in your subscription, reach out to support if you need more"
            : ""
        }
      />
      <CheckAddressModal open={openCheckAddressModal} onCancel={closeCheckAddressModal} />
    </Modal>
  );
};
