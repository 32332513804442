/* eslint-disable react/jsx-fragments */
import { Col, Row, Tag, Typography } from "antd";
import { FC, useState } from "react";
import avatar from "src/shared/images/avatar.png";
import { SvgIcon } from "src/shared/icons";
import { ConnectWithSpotifyButton } from "../ConnectWithSpotifyButton";
import { Modal } from "src/components/ui";
import "./index.less";
import { useSelector } from "react-redux";
import { userSelector } from "src/store/user";
import { dialerSelector } from "src/store/dialer";

export const ConnectSpotifyModal: FC<{
  open: boolean;
  onCancel: VoidFunction;
}> = ({ open, onCancel }) => {
  const { spotify } = useSelector(userSelector);

  const { spotify: spotifyObjectOfUser } = useSelector(dialerSelector);
  const [isConnected, setIsConnected] = useState(false);
  // console.log(spotifyObjectOfUser, "spotifyObjectOfUser");
  const handleConnectClick = () => {
    setIsConnected((prevState) => !prevState);
  };

  return (
    <Modal
      customClassName={"connect-spotify-modal"}
      open={open}
      title={"Dial And Vibe With Spotify"}
      closeIcon={<SvgIcon type={"close"} />}
      onCancel={onCancel}
    >
      {spotify?.access_token ? (
        <>
          {" "}
          <Typography.Paragraph className={"block-title"}>Connected Account</Typography.Paragraph>
          <Row justify={"space-between"}>
            <div className={"account-wrapper"}>
              {spotifyObjectOfUser?.photo ? <img src={spotifyObjectOfUser?.photo} height={40} width={40} alt={"Profile Photo"} /> : ""}
              <Col className={"account-data-wrapper"}>
                {spotifyObjectOfUser?.email ? <Typography.Text>Spotify Account: {spotifyObjectOfUser?.email}</Typography.Text> : ""}
                {spotifyObjectOfUser?.activeDeviceName ? <Typography.Text>Active Device: {spotifyObjectOfUser?.activeDeviceName}</Typography.Text> : ""}
              </Col>
            </div>
            <Tag color={"success"} bordered={false} className={"table-tag"}>
              Connected
            </Tag>
          </Row>
        </>
      ) : (
        ""
      )}
      <ConnectWithSpotifyButton
        handleClick={() => {
          window.location.href = `${import.meta.env.VITE_REACT_APP_SERVER_API}/spotify/connectSpotifyAccForFun`;
        }}
        integrate
      />
      <Typography.Text className={"block-title"}>Listen to Spotify while dialing and once a prospect answers we&apos;ll pause your music!</Typography.Text>
      <br />
      <br />
      <Typography.Text>*Press &quot;Play&quot; in the Spotify app on one of your devices to control Spotify in Meetz.</Typography.Text>
      {/* <Typography.Paragraph className={"block-title"}>Control</Typography.Paragraph>
      {isConnected ? (
        <Row align={"middle"} justify={"space-between"}>
          <SvgIcon type={"shuffle"} />
          <Col className={"control-middle-wrapper"}>
            <SvgIcon type={"rewindBack"} />
            <SvgIcon type={"play"} />
            <SvgIcon type={"rewindBack"} style={{ transform: "rotateY(180deg)" }} />
          </Col>
          <SvgIcon type={"undo"} />
        </Row>
      ) : (
        <Typography.Text className={"empty-state"}>
          No account is currently connected. Add account above.
        </Typography.Text>
      )} */}
    </Modal>
  );
};
