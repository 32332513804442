import { Menu, MenuProps, Row, Space } from "antd";
import { FC, Key, ReactNode, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Logo } from "src/components/logo";
import { SvgIcon } from "src/shared/icons";
import "./index.less";
import { fullUserSelector, SetColapsedSdBar } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";

type MenuItem = Required<MenuProps>["items"][number] & { route: string };

const SideBarMenu: FC<{ isLogoLetter?: boolean; onSelect?: () => void; selectedItem?: string }> = ({ isLogoLetter, onSelect, selectedItem }) => {
  const [wasOnSettings, setWasOnSettings] = useState(false);
  const [selectedKey, setSelectedKey] = useState(selectedItem || "");
  const location = useLocation();

  const getIcon = (itemKey: string, itemRoute: string) => {
    return (
      <NavLink to={itemRoute} className={"icons-wrapper"}>
        {selectedKey === itemKey && !isLogoLetter ? <SvgIcon className={"active-bar"} type={"activeBar"} /> : null}
        <SvgIcon type={selectedKey === itemKey ? `${itemKey}Active` : `${itemKey}`} />
      </NavLink>
    );
  };

  function getItem(label: ReactNode, key: Key, route?: string, icon?: ReactNode, children?: MenuItem[], type?: "group"): MenuItem | null {
    return {
      label,
      key,
      route,
      icon,
      children,
      type,
    } as MenuItem;
  }

  const dividerItem = {
    type: "divider",
  };

  const items: MenuItem[] = [
    getItem(
      "DASHBOARD",
      "1",
      "",
      null,
      [getItem("Dashboard", "dashboard", "dashboard", getIcon("dashboard", "dashboard"))]?.filter(Boolean) as MenuItem[],
      "group"
    ),

    dividerItem,
    getItem(
      "OUTREACH",
      "3",
      "",
      null,
      [
        getItem("Campaigns", "campaigns", "campaigns", getIcon("campaigns", "campaigns")),
        // {
        //   label: "Analytics & Setup",
        //   key: "subtitle1",
        //   style: { cursor: "default", color: "grey", fontSize: "larger" },
        //   disabled: true, // This makes the item non-clickable
        // },
        // getItem(
        //   "Emails",
        //   "emails",
        //   "email-analytics",
        //   getIcon("emails", "email-analytics")
        //   // getIcon("emails", "email-stats"), TODOF add later
        // ),
        // getItem("Phone calls", "phoneCalls", "phone-calls", getIcon("phoneCalls", "phone-calls")), // TODOF add back in
      ]?.filter(Boolean) as MenuItem[],
      "group"
    ),
    getItem(
      "ANALYTICS & SETUP",
      "subtitle1",
      "",
      null,
      [
        getItem("Emails", "emails", "email-analytics", getIcon("emails", "email-analytics")),
        getItem("Calls", "phoneCalls", "phone-calls", getIcon("phoneCalls", "phone-calls")),
      ]?.filter(Boolean) as MenuItem[],
      "group"
    ),
    dividerItem,
    getItem(
      "CRM",
      "2",
      "",
      null,
      [
        getItem("Contacts", "contacts", "contacts", getIcon("prospectData", "contacts")),
        // getItem("Prospects", "prospectData", "prospect-data", getIcon("prospectData", "prospect-data")),
        // getItem("Deals", "deals", "deals", getIcon("deals", "deals")),
      ]?.filter(Boolean) as MenuItem[],
      "group"
    ),
    dividerItem,
    getItem(
      "SCHEDULING",
      "4",
      "",
      null,
      [getItem("Scheduling Links", "meetingLinks", "meeting-links", getIcon("meetingLinks", "meeting-links"))]?.filter(Boolean) as MenuItem[],
      "group"
    ),
    // dividerItem,
    // getItem("CRM", "4", "", null, [getItem("Deals", "deals", "deals", getIcon("deals", "deals"))] as MenuItem[], "group"), // TODOF
    //  TODOC selected make light blue  https://chatgpt.com/c/27d010d1-2d59-4ca8-98f3-7e780c08bda6 + could use url if needed. mnake like in the email selected filter option
    // dividerItem, TODOF
    // getItem(
    //   "OTHER",
    //   "5",
    //   "",
    //   null,
    //   [getItem(
    //     "Team members",
    //     "teamMembers",
    //     "team-members",
    //     getIcon("teamMembers", "team-members"),
    //   ),]?.filter(Boolean) as MenuItem[],   'group'
    // ),
  ]?.filter(Boolean) as MenuItem[];

  useEffect(() => {
    if (selectedItem) {
      setSelectedKey(selectedItem);
    }
  }, [selectedItem]);
  const dispatch = useDispatch();
  const { sdBarCollapsed } = useSelector(fullUserSelector);
  useEffect(() => {
    const pathname = location.pathname;
    // console.log(pathname, "pathname", selectedItem);
    if (!selectedItem) {
      let foundKey = "";
      const findKeyByPath = (items: any) => {
        for (const item of items) {
          // console.log(item.route,pathname,'aaaaaaa');
          if (`/${item.route}` === pathname) {
            foundKey = item.key.toString();
            break;
          }
          if (item.children) {
            findKeyByPath(item.children);
            if (foundKey) {
              break;
            }
          }
        }
      };
      findKeyByPath(items);
      if (foundKey) setSelectedKey(foundKey.toString());
    }
    if (pathname === "/settings") {
      dispatch(SetColapsedSdBar(true));
      setWasOnSettings(true);
      setSelectedKey("");
    } else if (wasOnSettings) {
      setWasOnSettings(false);
      dispatch(SetColapsedSdBar(false));
    }
  }, [location.pathname, items]);

  return (
    <Space direction={"vertical"} size={74}>
      <Row className={"logo-wrapper"} justify={isLogoLetter ? "center" : "start"}>
        <Logo isLogoLetter={isLogoLetter} />
      </Row>
      <Menu theme={"light"} onSelect={onSelect} mode={"inline"} items={items} selectedKeys={[selectedKey]} />
    </Space>
  );
};

export { SideBarMenu };
