/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, Flex, Form, GetProp, Input, Select, Switch, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmButtons, CustomSelect, Modal, TableHeader } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { AppDispatch } from "src/store";
import { outreachEmailsSelector, resetAddedSMTP } from "src/store/outreach-emails";
import { addSMTP, testIMAP, testSMTP } from "src/store/outreach-emails/asyncRequests";
// import { addressesAvailable } from "../../../info"

type CheckAddressModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  informationOfEditing: any;
};

const smtpOptions = [
  {
    label: "465 - SSL",
    value: 465,
  },
  {
    label: "587 - TLS",
    value: 587,
  },
  {
    label: "25 - None",
    value: 25,
  },
];

const imapOptions = [
  {
    label: "993 - SSL (Recommended)",
    value: 993,
  },
  // {
  //   label: "143 - TLS",
  //   value: 143,
  // },
  {
    label: "143 - None",
    value: 143,
  },
];

export const SMTPConnectionModal: FC<CheckAddressModalProps> = ({ open, informationOfEditing, onCancel }) => {
  const [isSameCredentials, setIsSameCredentials] = useState(true);
  const [isSameFrom, setIsSameFrom] = useState(true);
  const { pendingAddSMTP, addedSMTP } = useSelector(outreachEmailsSelector);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (addedSMTP) {
      dispatch(resetAddedSMTP());
      closeModal();
    }
  }, [addedSMTP]);

  // const [isAddressAvailable, setIsAddressAvailable] = useState(true);
  const [form] = Form.useForm();
  const closeModal = () => {
    form.resetFields();
    onCancel();
  };

  const handleFormSubmit = () => {
    const values = form.getFieldsValue();
    message.destroy();
    message.info({
      content: `Verifying and adding your SMTP/IMAP connection... This process can take up to 1 minute to complete. Thank you for your patience.`,
      duration: 60,
    });
    // console.log(values,'valuesvalues');
    const { portSmtp, hostSmtp, emailSmtp, emailImap, passImap, passSmtp, hostImap, portImap, emailFromSmtp } = values;

    // dont change, this goes to db
    const smtp = {
      port: portSmtp,
      host: hostSmtp,
      email: emailSmtp,
      password: passSmtp,
      from: isSameFrom || !emailFromSmtp || emailFromSmtp?.toLowerCase()?.trim() === emailSmtp?.toLowerCase()?.trim() ? null : emailFromSmtp,
    };
    const imap = {
      port: portImap,
      host: hostImap,
      email: isSameCredentials ? emailSmtp : emailImap,
      password: isSameCredentials ? passSmtp : passImap,
    };

    dispatch(
      addSMTP({
        smtp,
        imap,
      })
    );
  };
  const handleSwitchChangeFrom = (checked) => {
    setIsSameFrom(checked);
  };
  const handleSwitchChange = (checked) => {
    setIsSameCredentials(checked);
  };

  useEffect(() => {
    if (!informationOfEditing?.smtp?.port) {
      form.setFieldsValue({ portSmtp: 465, portImap: 993 });
    } else {
      // console.log(informationOfEditing, "smtp123");
      // preload data
      const { port: portImap, host: hostImap, email: emailImap, password: passImap } = informationOfEditing.imap;
      const { port: portSmtp, host: hostSmtp, email: emailSmtp, password: passSmtp, from: fromSmtp } = informationOfEditing.smtp;

      if (emailImap?.toLowerCase()?.trim() !== emailSmtp?.toLowerCase()?.trim() || passImap?.toLowerCase()?.trim() !== passSmtp?.toLowerCase()?.trim()) {
        handleSwitchChange(false);
      } else {
        handleSwitchChange(true);
      }
      let emailFromSmtp = fromSmtp;
      if (!fromSmtp || fromSmtp?.toLowerCase()?.trim() === emailSmtp?.toLowerCase()?.trim()) {
        emailFromSmtp = emailSmtp;
        handleSwitchChangeFrom(true);
      } else {
        handleSwitchChangeFrom(false);
      }
      form.setFieldsValue({ portSmtp, hostSmtp, emailSmtp, emailImap, passImap, passSmtp, hostImap, portImap, emailFromSmtp });
    }
  }, [informationOfEditing]);

  const test = (type: "smtp" | "imap") => {
    const values = form.getFieldsValue();
    message.destroy();
    message.info({
      content: `We are currently verifying your ${type === "smtp" ? "SMTP" : "IMAP"} connection. ${
        type === "smtp" ? "" : "IMAP connections can take up to 1 minute to complete."
      } Thank you for your patience.`,
      duration: 30,
    });

    // console.log(values,'valuesvalues');
    const { portSmtp, hostSmtp, emailSmtp, emailImap, passImap, passSmtp, hostImap, portImap, emailFromSmtp } = values;
    if (type === "smtp") {
      // console.log(values, "valuesvalues");

      dispatch(
        testSMTP({
          port: portSmtp,
          host: hostSmtp,
          from: isSameFrom || !emailFromSmtp || emailFromSmtp?.toLowerCase()?.trim() === emailSmtp?.toLowerCase()?.trim() ? null : emailFromSmtp,
          email: emailSmtp,
          password: passSmtp,
          type: "smtp",
        })
      );
    } else {
      dispatch(
        testIMAP({
          port: portImap,
          host: hostImap,
          email: isSameCredentials ? emailSmtp : emailImap,
          password: isSameCredentials ? passSmtp : passImap,
          fromEmail: emailFromSmtp,
          type: "imap",
        })
      );
    }
  };

  // const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
  //   console.log("checked = ", checkedValues);
  // };

  return (
    <Modal width={530} open={open} title={"SMTP/IMAP Connection"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        {/* <Form.Item name={"address"} label={"Look Up Address"} style={{ marginBottom: 0 }}>
          <TableHeader searchPlaceholder={"New York"} hasMaxWidth={false} />
        </Form.Item> */}
        {/* {isAddressAvailable ? (
          <Checkbox.Group className={"phones-available-radio-group"} onChange={onChange} style={{ width: "100%" }}>
            <Flex vertical gap={8} style={{ maxHeight: 104, overflowY: "scroll", marginBottom: 28 }}>
              {addressesAvailable.map((address, index) => (
                <Checkbox key={`${index}-${address}`} value={address}>
                  {address}
                </Checkbox>
              ))}
            </Flex>
          </Checkbox.Group>
        ) : (
          <Typography.Paragraph className={"empty-state"}>
            No Numbers Found. Try a Different Area Code or Check Your Input
          </Typography.Paragraph>
        )} */}

        <Flex gap={55} justify="space-between">
          {" "}
          <div> 1. SMTP (To send your email)</div>
          <Flex style={{ justifyContent: "center", alignItems: "center", fontSize: "13px", marginLeft: "auto" }} gap={25}>
            {" "}
            <div> Advanced: Use the same "From" email as the "SMTP Email Address"</div>
            <Switch checked={isSameFrom} onChange={handleSwitchChangeFrom} />
          </Flex>
        </Flex>
        <br />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          <Form.Item name={"emailSmtp"} label={"SMTP Email/Username"} rules={[{ required: true }]}>
            <Input placeholder={"example@yourdomain.com"} disabled={pendingAddSMTP} style={{ width: "200px" }} />
          </Form.Item>
          <Form.Item name={"passSmtp"} label={"Email Password"} rules={[{ required: true }]}>
            <Input.Password disabled={pendingAddSMTP} placeholder={"abc123"} style={{ width: "200px" }} />
          </Form.Item>
          <Form.Item name={"hostSmtp"} label={"Host"} rules={[{ required: true }]}>
            <Input disabled={pendingAddSMTP} placeholder={"smtp.gmail.com"} style={{ width: "200px" }} />
          </Form.Item>
          <Form.Item name={"portSmtp"} label={"Port - Security"} rules={[{ required: true }]} style={{ width: "200px" }}>
            <Select options={smtpOptions} disabled={pendingAddSMTP} />
          </Form.Item>
          {isSameFrom ? (
            ""
          ) : (
            <>
              {" "}
              <Form.Item
                name={"emailFromSmtp"}
                label={
                  <>
                    "From" address
                    <Tooltip
                      placement={"right"}
                      title={
                        <>
                          In case your "SMTP Email Address" is different than the "From" email address used when sending emails to your prospects. <br />
                          Once you click "Save and Continue" you will get an email to your "From" email addresses inbox, click the link in the email to validate
                          Meetz to use the email address.
                          <br />
                          <br />* This will be the "Reply To" email as well
                        </>
                      }
                    >
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>{" "}
                  </>
                }
                rules={[
                  { warningOnly: true, message: 'Enter "From" email' },
                  { type: "email", message: 'Enter "From" email' },
                ]}
              >
                <Input disabled={pendingAddSMTP} placeholder={"example@yourdomain.com"} style={{ width: "200px" }} />
              </Form.Item>
            </>
          )}
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <Button className={"link"} style={{ padding: 0, margin: 0, left: 0 }} onClick={() => test("smtp")} disabled={pendingAddSMTP}>
              Test SMTP Connection
            </Button>
          </div>{" "}
        </div>
        <hr style={{ marginTop: "20px" }} />
        <Flex gap={55} justify="space-between">
          {" "}
          <div>2. IMAP (To receive email)</div>
          <Flex style={{ justifyContent: "center", alignItems: "center", fontSize: "13px" }} gap={25}>
            {" "}
            <div>
              {" "}
              Use the same email and <br />
              password from SMTP{" "}
            </div>
            <Switch checked={isSameCredentials} onChange={handleSwitchChange} />
          </Flex>{" "}
        </Flex>
        <br />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
          {isSameCredentials ? (
            ""
          ) : (
            <>
              {" "}
              <Form.Item name={"emailImap"} label={"Email Address"} rules={[{ required: true }, { type: "email" }]}>
                <Input disabled={pendingAddSMTP} placeholder={"example@yourdomain.com"} style={{ width: "200px" }} />
              </Form.Item>
              <Form.Item name={"passImap"} label={"Email Password"} rules={[{ required: true }]}>
                <Input.Password disabled={pendingAddSMTP} placeholder={"abc123"} style={{ width: "200px" }} />
              </Form.Item>{" "}
            </>
          )}
          <Form.Item name={"hostImap"} label={"Host"} rules={[{ required: true }]}>
            <Input placeholder={"imap.gmail.com"} disabled={pendingAddSMTP} style={{ width: "200px" }} />
          </Form.Item>
          <Form.Item name={"portImap"} label={"Port - Security"} rules={[{ required: true }]} style={{ width: "200px" }}>
            <Select disabled={pendingAddSMTP} options={imapOptions} />
          </Form.Item>
          <div style={{ marginBottom: "10px" }}>
            <Button className={"link"} style={{ padding: 0, margin: 0, left: 0 }} disabled={pendingAddSMTP} onClick={() => test("imap")}>
              Test IMAP Connection
            </Button>
          </div>
        </div>
        <hr />
        <br />
        <ConfirmButtons rightButtonTitle={"Save and Continue"} leftButtonTitle={"Back"} onCancel={closeModal} disabled={pendingAddSMTP} />
        <Flex align={"center"}>
          {/* <SvgIcon type={"alarm"} width={24} height={24} /> */}

          <Typography.Paragraph className={"description"} style={{ marginTop: "15px" }}>
            Tip: Test the SMTP <b>and</b> IMAP connection before saving the information.
          </Typography.Paragraph>
        </Flex>
      </Form>
    </Modal>
  );
};
