/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, CollapseProps, Flex, List, Popconfirm, Row, Space, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { App } from "src/types";
import noActions from "src/shared/images/noActions.png";

import { CallSetupModal, MoveProspectsSelectStepModal, SelectEmailModal } from "src/components/pages/campaigns/CampaignDetails/ui";
import { Collapse, ContentCard, CustomSelect, Loader } from "src/components/ui";
import { getActionButtonTitle, getActionItemButtonTitle } from "src/helpers";
import { SvgIcon } from "src/shared/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.less";
import { outreachCampaignSelector, setOpenCampSettings, setOpenVariantFromAction, setitemIdSel } from "src/store/outreach-campaigns";
import { useDispatch, useSelector } from "react-redux";
import { outreachEmailsSelector, resetOutcome as resOutcomeEm } from "src/store/outreach-emails";
import { ManageEmailDrawer } from "src/components/pages/emails-data";
import { approveSteps, getFilteredLeadsRst, resetCampIdDiscEmails, viewMoreProsInStep } from "src/store/outreach-campaigns/asyncRequests";
import { addEmailBe } from "src/store/outreach-emails/asyncRequests";
import { ViewAiEmailModal } from "./modals/ViewAiEmailModal";
// import { useNavigate, useParams } from "react-router";
import { CampApproveModal } from "./modals/CampApproveModal";
import { userIsTrialSelector } from "src/store/user";
import { dialerSelector, resetMultiDialerPros, setFocusedCampaignToPowerDialing, setPowerDialer, setStopDialing } from "src/store/dialer";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import dayjs from "dayjs";

interface ActionsForTodayProps {
  data: App.AllAction[];
  loading: boolean;
  payingClient: boolean;
  frmtdSeq: any[];
}

export const ActionsForTodayCard: FC<ActionsForTodayProps> = ({ loading, payingClient, frmtdSeq }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    outreachCampaignsList,
    campaignDisplaying,
    eachStepsAnalytics,
    itemIdSel,
    loadingMorePros,
    openSettingsModal,
    dialerCanCallNowInTheirWorkingHours,
    sendsInLast24,
  } = useSelector(outreachCampaignSelector);
  const {
    successfullUpdateMsg: succMsg,
    failedUpdateMsg: failMsg,
    infoUpdateMsg: infoMsg,
    additionalEmails,
    additionalEmailsTimezone,
    additionalEmailsSched,
  } = useSelector(outreachEmailsSelector);
  // const [randomImgSel, setRandomImgSel] = useState();

  // useEffect(() => {
  //   setRandomImgSel(Math.random() >= 0.5);
  // }, []);

  const [openModal, setOpenModal] = useState(false);
  const [openSelectStepModal, setOpenSelectStepModal] = useState(false);
  const [openCallSetupModal, setOpenCallSetupModal] = useState(false);
  const [selectedCounts, setSelectedCounts] = useState<{ [key: string]: number }>({});
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [visibleItems, setVisibleItems] = useState<number>(7);
  // const [isPlanActivated, setIsPlanActivated] = useState(true)
  const navigate = useNavigate();

  // const [totalEmails, setTotalEmails] = useState<number>(0)
  const [totalAutoEmails, setTotalAutoEmails] = useState<number>(0);
  const [totalNoAutoEmails, setTotalNoAutoEmails] = useState<number>(0);
  const [totalPhones, setTotalPhones] = useState<number>(0);
  // const [prospectsToSee, setProspectsToSee] = useState([])
  const [actionsPerStep, setActionsPerStep] = useState<{ stepNum: number; actions: number }>([]);

  const checkTypeOfStep = (stepNum) => {
    return campaignDisplaying?.campaign?.campaign_steps?.find((step) => step?.step_number === stepNum)?.step_type;
  };
  const checkIsAutoContinuationOfStep = (stepNum) => {
    return campaignDisplaying?.campaign?.campaign_steps?.find((step) => step?.step_number === stepNum)?.auto_start;
  };
  //   const checkAnalyticsWithStepId = (stepId) =>{
  // return campaignDisplaying?.campaign?.campaign_steps?.find((step)=>step?.id===stepId)?.step_number
  //   }

  const getStepDetailsId = (stepId) => {
    return campaignDisplaying?.campaign?.campaign_steps?.find((step) => step?.id === stepId);
  };
  const [aiEmailId, setAiEmailId] = useState(null);
  const [campDisplaying, setCampDisplaying] = useState([]);
  const [emailStepsOptions, setEmailStepsOptions] = useState([]);
  const [phoneStepsOptions, setPhoneStepsOptions] = useState([]);

  useEffect(() => {
    // let totEmail=0
    let totAutoEmail = 0;
    let totNoAutoEmail = 0;
    let totPhone = 0;
    let actPerStep = [];
    for (let stepNum in eachStepsAnalytics) {
      stepNum = +stepNum;
      if (eachStepsAnalytics.hasOwnProperty(stepNum)) {
        const actions = eachStepsAnalytics[stepNum].prospectReadyStepCount || 0;
        actPerStep = [...actPerStep, { stepNum, actions }];
        const isPhoneStep = checkTypeOfStep(stepNum) === "phonecall"; // later need to add SMS and task logic
        if (isPhoneStep) {
          totPhone += actions;
        } else {
          const isAutoEm = !!checkIsAutoContinuationOfStep(stepNum);
          if (isAutoEm) {
            totAutoEmail += actions;
          } else {
            totNoAutoEmail += actions;
          }
          // totEmail  += actions
        }
      }

      const emStepOptions = [{ value: "None", label: "No Step Selected" }];
      const phoneStepOptions = [{ value: "None", label: "No Step Selected" }];
      const campSteps = campaignDisplaying?.campaign?.campaign_steps || [];
      if (campSteps?.length) {
        // console.log(campSteps,'campSteps');
        //  for (let step of campSteps) {
        for (let step of frmtdSeq) {
          // const type = checkTypeOfStep(step.step_number)
          const actionsInStep = actPerStep?.find((st) => st.stepNum === step.stepNum)?.actions;
          // console.log("actionsInStep", step);
          if (step?.type === "aiemail") {
            setAiEmailId(step.id);
          }
          if (step?.type === "phonecall") {
            phoneStepOptions.push({
              label: `Step ${step.stepNum} ${actionsInStep ? `- ${actionsInStep} calls, start power dialer` : `- No prospects are in this step now`}`,
              value: step.id,
            });
          } else {
            emStepOptions.push({
              label: `Step ${step.stepNum} ${
                actionsInStep
                  ? `- ${actionsInStep} emails ${step.autoStart ? `in queue, no action required` : ", take action."}`
                  : `- No prospects are in this step now`
              }`,
              value: step.id,
            }); // TODOF add a check if email add is not connected write to take action! QUICKLY DO THIS!!
          }
        }
      }
      // console.log(emStepOptions,'emStepOptions');
      setEmailStepsOptions(emStepOptions);
      setPhoneStepsOptions(phoneStepOptions);
      setActionsPerStep(actPerStep);
      setTotalPhones(totPhone);
      // setTotalEmails(totEmail)
      setTotalAutoEmails(totAutoEmail);
      setTotalNoAutoEmails(totNoAutoEmail);
    }
  }, [campaignDisplaying, eachStepsAnalytics, frmtdSeq]);

  // useEffect(() => {
  //     const emStepOptions = []
  //     const phoneStepOptions = []
  //     const campSteps = campaignDisplaying?.campaign?.campaign_steps || []
  //     if(campSteps?.length){
  //    for (let step in campSteps) {
  //       const type = checkTypeOfStep(step.step_number)
  //       if(type==='phonecall'){
  //         phoneStepOptions.push({label:`Step ${step.step_number} - ${}`})
  //       }
  //    }
  // }
  // }, [campaignDisplaying]);

  const onLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 7);
  };

  const {
    dialerInfo,
    dialerFocusedOnInfo,
    prospects,
    loadingCampaigns,
    loadingFocCamp,
    isLastPage,
    totalProspectCount,
    predictiveDialerOn,
    multiCallingFullyPaused,
    loadPatchDialer,
    loadingSaveOrUpdateEmailTemplate,
    readyToDial,
    isPopUpOpen,
  } = useSelector(dialerSelector);

  const { phone_numbers } = useSelector(outreachPhonesSelector);
  console.log(phone_numbers, "phone_numbers");
  const activatePowerDialer = () => {
    const hasValidPhoneNum = !!phone_numbers?.find((num) => num.is_active);
    if (!dialerCanCallNowInTheirWorkingHours || !campRecord?.overview?.isActive || !readyToDial || !hasValidPhoneNum || predictiveDialerOn || isPopUpOpen) {
      return;
    }
    const selectedCampaignId = (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000;
    const selectedCampaign = outreachCampaignsList?.find((item) => +item?.overview?.campId === selectedCampaignId);
    dispatch(resetMultiDialerPros());
    dispatch(setStopDialing(false));
    // console.log({ id, selectedCampaign }, "phone_num");
    // dispatch(setFocusedCampaignToPowerDialing(id));
    dispatch(setFocusedCampaignToPowerDialing(selectedCampaign));
    dispatch(setPowerDialer(true));
  };

  const handleUpdatePlanClick = () => {
    navigate("/settings");
  };

  const loadMoreBtn = (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button className={"link"} onClick={onLoadMore}>
        See more
      </Button>
    </div>
  );
  const campRecord = outreachCampaignsList?.find((oc) => oc?.overview?.campId === campaignDisplaying?.campaign?.id);

  const showManSendsModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const showSelectStepModalModal = () => {
    setOpenSelectStepModal(true);
  };

  const showCallSetupModal = () => {
    setOpenCallSetupModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
    setOpenCallSetupModal(false);
    setOpenSelectStepModal(false);
  };

  // const handleCheckboxChange = (itemTitle: string, checked: boolean, action: string | undefined) => {
  //   setSelectedCounts(prevCounts => ({
  //     ...prevCounts,
  //     [itemTitle]: checked ? (prevCounts[itemTitle] || 0) + 1 : (prevCounts[itemTitle] || 0) - 1,
  //   }))
  //   if (checked && action) {
  //     setSelectedActions(prevSelectedActions => [...prevSelectedActions, action])
  //   } else {
  //     setSelectedActions(prevSelectedActions => prevSelectedActions.filter(item => item !== action))
  //   }
  // }

  // const totalInfoCount = data.reduce((total, action) => total + action.info.length, 0)

  // const title = (
  //   <Row style={{ gap: 16 }}>
  //     <Typography.Paragraph>
  //       {`${selectedActions.length} of ${totalInfoCount} ${
  //         selectedActions.length === 1 ? "prospect is selected" : "prospects are selected"
  //       }`}
  //     </Typography.Paragraph>
  //     <Button
  //       className={"link"}
  //       onClick={() => {
  //         setSelectedActions([])
  //         setSelectedCounts({})
  //       }}
  //     >
  //       Deselect all
  //     </Button>
  //   </Row>

  // )

  const [aiEmailOpen, setAiEmailOpen] = useState({});
  const [controlActKeys, setControlActKeys] = useState([]);

  const [emValIdToSee, setEmValIdToSee] = useState("None");
  const [phValIdToSee, setPhValIdToSee] = useState("None");

  useEffect(() => {
    // console.log(controlActKeys, "controlActKeys");
    if (!controlActKeys?.length) {
      dispatch(setitemIdSel(null));
      setEmValIdToSee("None");
      setPhValIdToSee("None");
    } else {
      const isEmail = !!emailStepsOptions?.find((em) => em.value === itemIdSel);
      // console.log(isEmail, controlActKeys[0], "asdfkjn");
      if (controlActKeys[0] === "em" && !isEmail) {
        setPhValIdToSee("None");
        dispatch(setitemIdSel(null));
      } else if (controlActKeys[0] === "ph" && isEmail) {
        setEmValIdToSee("None");
        dispatch(setitemIdSel(null));
      }
    }
  }, [controlActKeys]);

  const handleChangeEmToSee = (newVal) => {
    // setEmValIdToSee(newVal)
    dispatch(setitemIdSel(newVal));
  };
  const handleChangePhToSee = (newVal) => {
    // setPhValIdToSee(newVal)
    dispatch(setitemIdSel(newVal));
  };

  useEffect(() => {
    // console.log(itemIdSel,'isEmail');
    if (itemIdSel) {
      const isEmail = !!emailStepsOptions?.find((em) => em.value === itemIdSel);
      // console.log(itemIdSel,isEmail,'isEmail');
      if (isEmail) {
        setEmValIdToSee(itemIdSel);
        setControlActKeys(["em"]);
        setPhValIdToSee("None");
      } else {
        setPhValIdToSee(itemIdSel);
        setControlActKeys(["ph"]);
        setEmValIdToSee("None");
      }
    }
    // else{
    //   setControlActKeys([])
    //   setPhValIdToSee('None')
    //       setEmValIdToSee('None')
    //     }
  }, [itemIdSel]);

  // const {additionalEmails} = useSelector(outreachEmailsSelector)

  //  TODO remove phone or email if its not in there and if nothign so write it.
  const idSel = emValIdToSee !== "None" ? emValIdToSee : phValIdToSee !== "None" ? phValIdToSee : null;
  const stepDetails = idSel && getStepDetailsId(idSel);
  const selectedStepNum = stepDetails && stepDetails?.step_number;
  const prospectsInStep = selectedStepNum && eachStepsAnalytics[selectedStepNum];
  const readyPros = prospectsInStep?.firstTenProsReady;
  const notReadyPros = prospectsInStep?.firstTenProsNotReadyYet;

  const handleViewMore = () => {
    const moreProsAmnt = Math.ceil(readyPros?.length / 10) * 10 + 10;
    dispatch(viewMoreProsInStep({ campId: campaignDisplaying?.campaign?.id, moreProsAmnt, stepNum: selectedStepNum }));
  };
  const approveEmails = (stepToApprove) => {
    dispatch(approveSteps({ campId: campaignDisplaying?.campaign?.id, stepToApprove }));
  };

  const selectedCampaignId = (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000;
  const selectedCampaign = outreachCampaignsList?.find((item) => +item?.overview?.campId === selectedCampaignId);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailsData, setEmailsData] = useState<any>([]);
  useEffect(() => {
    // console.log(additionalEmails, "additionalEmails");
    setEmailsData(
      [...additionalEmails]
        ?.sort((a, b) => {
          return a?.id - b?.id;
        })
        ?.filter((em) => em.connected)
        ?.map((em) => (em?.smtp?.from?.length ? em?.smtp?.from?.toLowerCase() : em?.email_alias_emailofcal?.toLowerCase()))
    );
  }, [additionalEmails]);

  useEffect(() => {
    if (emailsData?.length) {
      // make sure is loaded

      // const origActive = !!selectedCampaign?.overview?.isActive;
      const emailUsed = selectedCampaign?.overview?.emailsToSendWith || [];

      const availEmailsSelected = emailsData?.filter((emAvail) => emailUsed?.some((em) => em?.toLowerCase() === emAvail));

      // const canBeActive = (!emailStepsOptions || availEmailsSelected?.length) && prospects;

      if (availEmailsSelected?.length) {
        setSelectedEmails(availEmailsSelected);
      }

      // first set emails. then set the active otherwise it will trigger logic to change without client wanting the change
    }
  }, [selectedCampaign, emailsData]);

  //   const items: CollapseProps["items"] = [
  //     emailStepsOptions?.length > 1 && {
  //       key: "em",
  //       label: (
  //         <>
  //           In Email Step:{" "}
  //           {totalAutoEmails + totalNoAutoEmails
  //             ? `${totalAutoEmails + totalNoAutoEmails} prospect${totalAutoEmails + totalNoAutoEmails !== 1 ? "s" : ""}${
  //                 totalNoAutoEmails ? `, take action` : !campRecord?.overview?.isActive ? `, activate campaign to proceed` : ", no action required"
  //               }`
  //             : "All completed"}
  //         </>
  //       ),
  //       extra: (
  //         <Button className={"link"} onClick={() => (totalNoAutoEmails ? approveEmails("all") : showAddSetUpModal())} style={{ marginLeft: "15px" }}>
  //           {totalNoAutoEmails ? `Approve steps & send` : `Manage emails`}
  //         </Button>
  //       ),
  //       children: (
  //         <>
  //           {/* emailStepsOptions
  // phoneStepsOptions */}
  //           <CustomSelect options={emailStepsOptions} onChange={handleChangeEmToSee} value={emValIdToSee} style={{ width: "95%", marginTop: "-10px" }} />
  //           {!!readyPros?.length && (
  //             <>
  //               <br />
  //               <br />
  //               <Flex justify="space-between" style={{ width: "95%", margin: "0 auto" }}>
  //                 <div>{stepDetails?.auto_start ? `Prospects in queue for ${aiEmailId === idSel ? "Ai" : "templated"} step:` : `Prospects ready for step:`}</div>
  //                 <div>
  //                   {aiEmailId !== idSel && (
  //                     <Button
  //                       className="link"
  //                       style={{ marginLeft: "2px", marginRight: "6px" }}
  //                       onClick={() => {
  //                         dispatch(setOpenVariantFromAction(true));
  //                       }}
  //                     >
  //                       View Email Variations
  //                     </Button>
  //                   )}
  //                   {!stepDetails?.auto_start && (
  //                     <Button className="link" style={{ marginLeft: "2px" }} onClick={() => approveEmails(selectedStepNum)}>
  //                       Approve Step
  //                     </Button>
  //                   )}
  //                 </div>
  //               </Flex>
  //             </>
  //           )}
  //           {readyPros?.length ? (
  //             <div style={{ padding: "10px" }}>
  //               {readyPros?.map((pros) => (
  //                 <Space
  //                   key={pros.id}
  //                   // direction="vertical"
  //                   size={12}
  //                   style={{
  //                     padding: "10px",
  //                     border: "1px solid #eaeaea",
  //                     borderRadius: "8px",
  //                     marginBottom: "12px",
  //                     display: "flex",
  //                     justifyContent: "space-between",
  //                     alignItems: "center",
  //                   }}
  //                 >
  //                   <Typography.Paragraph className="contact-name" style={{ marginBottom: 0 }}>
  //                     <div>
  //                       <span style={{ fontWeight: 500 }}>Name:</span> {pros.sales_prospect?.first_name || ""} {pros.sales_prospect?.last_name || ""}
  //                     </div>
  //                     {pros.sales_prospect?.title && (
  //                       <div>
  //                         <span style={{ fontWeight: 500 }}>Title:</span> {pros.sales_prospect?.title}
  //                       </div>
  //                     )}
  //                     {pros.sales_prospect?.company_name && (
  //                       <div>
  //                         <span style={{ fontWeight: 500 }}>Company Name:</span> {pros.sales_prospect?.company_name}
  //                       </div>
  //                     )}
  //                   </Typography.Paragraph>
  //                   {aiEmailId === idSel && (
  //                     <Button
  //                       className="link"
  //                       style={{ marginLeft: "2px" }}
  //                       onClick={() => {
  //                         setAiEmailOpen({ pros, isAuto: stepDetails?.auto_start });
  //                       }}
  //                     >
  //                       View Ai Email
  //                     </Button>
  //                   )}
  //                 </Space>
  //               ))}
  //               <div style={{ width: "fit-content", marginLeft: "2px", marginRight: "auto", display: "flex" }}>
  //                 <div>
  //                   Displaying {readyPros?.length} out of {prospectsInStep?.prospectReadyStepCount?.toLocaleString()}{" "}
  //                 </div>{" "}
  //                 {readyPros?.length < prospectsInStep?.prospectReadyStepCount ? (
  //                   <div style={{ marginLeft: "10px", display: "flex", color: "grey !important" }}>
  //                     {" "}
  //                     -{" "}
  //                     <Button className="link" style={{ pointerEvents: loadingMorePros ? "none" : "auto" }} onClick={() => !loadingMorePros && handleViewMore()}>
  //                       View More
  //                     </Button>
  //                     {loadingMorePros && <Loader size="small" style={{ marginLeft: "20px" }} />}
  //                   </div>
  //                 ) : (
  //                   ""
  //                 )}
  //               </div>
  //             </div>
  //           ) : emValIdToSee === "None" ? (
  //             ""
  //           ) : (
  //             <>
  //               <br />
  //               <br />
  //               <Typography.Paragraph className={"contact-name"}>
  //                 <Flex justify="space-between">
  //                   <div>No prospects are in this step now </div>
  //                   <div>
  //                     {aiEmailId !== idSel && (
  //                       <Button
  //                         className="link"
  //                         style={{ marginLeft: "2px", marginRight: "6px" }}
  //                         onClick={() => {
  //                           dispatch(setOpenVariantFromAction(true));
  //                         }}
  //                       >
  //                         View Email Variations
  //                       </Button>
  //                     )}
  //                     {!stepDetails?.auto_start && (
  //                       <Button className="link" style={{ marginLeft: "2px" }}>
  //                         Approve Step
  //                       </Button>
  //                     )}
  //                   </div>
  //                 </Flex>
  //               </Typography.Paragraph>
  //             </>
  //           )}
  //         </>
  //       ),
  //     },
  //     phoneStepsOptions?.length > 1 && {
  //       key: "ph",
  //       label: <>In Phone Call Step: {totalPhones ? `${totalPhones} prospect${totalPhones !== 1 ? "s" : ""}` : "All completed"}</>,
  //       extra: (
  //         <Button className={"link"} onClick={activatePowerDialer}>
  //           {predictiveDialerOn || isPopUpOpen ? `Dialer Popup Is Open Now` : readyToDial ? `Activate parallel dialer` : `Resetting power dialer...`}
  //         </Button>
  //       ),
  //       children: (
  //         <>
  //           {/* <CustomSelect options={phoneStepsOptions} onChange={handleChangePhToSee} value={phValIdToSee} style={{ width: "95%", marginTop: "-10px" }} /> */}
  //           {/* {!!readyPros?.length && (
  //             <>
  //               <br />
  //               <br />
  //               <span>{stepDetails?.auto_start ? `Prospects in queue for ${aiEmailId === idSel ? "Ai" : "templated"} step:` : `Prospects ready for step:`}</span>
  //             </>
  //           )} */}
  //           {
  //             // readyPros?.length ? (
  //             //   <div style={{ padding: "10px" }}>
  //             //     {readyPros?.map((pros) => (
  //             //       <Space
  //             //         key={pros.id}
  //             //         // direction="vertical"
  //             //         size={12}
  //             //         style={{
  //             //           padding: "10px",
  //             //           border: "1px solid #eaeaea",
  //             //           borderRadius: "8px",
  //             //           marginBottom: "12px",
  //             //           display: "flex",
  //             //           justifyContent: "space-between",
  //             //           alignItems: "center",
  //             //         }}
  //             //       >
  //             //         <Typography.Paragraph className="contact-name" style={{ marginBottom: 0 }}>
  //             //           <div>
  //             //             <span style={{ fontWeight: 500 }}>Name:</span> {pros.sales_prospect?.first_name || ""} {pros.sales_prospect?.last_name || ""}
  //             //           </div>
  //             //           {pros.sales_prospect?.title && (
  //             //             <div>
  //             //               <span style={{ fontWeight: 500 }}>Title:</span> {pros.sales_prospect?.title}
  //             //             </div>
  //             //           )}
  //             //           {pros.sales_prospect?.company_name && (
  //             //             <div>
  //             //               <span style={{ fontWeight: 500 }}>Company Name:</span> {pros.sales_prospect?.company_name}
  //             //             </div>
  //             //           )}
  //             //         </Typography.Paragraph>
  //             //         {/* {aiEmailId===idSel && <Button className="link" style={{ marginLeft: 'auto' }}>View Ai Email</Button>} TODOF later add call 1 person */}
  //             //       </Space>
  //             //     ))}
  //             //     <div style={{ width: "fit-content", marginLeft: "2px", marginRight: "auto", display: "flex" }}>
  //             //       <div>
  //             //         Displaying {readyPros?.length} out of {prospectsInStep?.prospectReadyStepCount?.toLocaleString()}{" "}
  //             //       </div>{" "}
  //             //       {readyPros?.length < prospectsInStep?.prospectReadyStepCount ? (
  //             //         <div style={{ marginLeft: "10px", display: "flex", color: "grey !important" }}>
  //             //           {" "}
  //             //           -{" "}
  //             //           <Button className="link" style={{ pointerEvents: loadingMorePros ? "none" : "auto" }} onClick={() => !loadingMorePros && handleViewMore()}>
  //             //             View More
  //             //           </Button>
  //             //           {loadingMorePros && <Loader size="small" style={{ marginLeft: "20px" }} />}
  //             //         </div>
  //             //       ) : (
  //             //         ""
  //             //       )}
  //             //     </div>
  //             //   </div>
  //             // ) : phValIdToSee === "None" ? (
  //             //   ""
  //             // ) : (
  //             //   <>
  //             //     <br />
  //             //     <br />
  //             //     <Typography.Paragraph className={"contact-name"}>No prospects are in this step now</Typography.Paragraph>
  //             //   </>
  //             // )
  //           }
  //         </>
  //       ),
  //     },
  //   ]?.filter(Boolean);

  // console.log(items, "controlActKeys", controlActKeys);

  // const items: CollapseProps["items"] = [].map((item, index) => {
  //   const selectedCount = selectedCounts[item.title] || 0
  //   const label = selectedCount !== undefined && selectedCount !== 0 ? `${item.title} (${selectedCount})` : item.title

  //   return {
  //     key: item.id,
  //     label: label,
  //     extra: (
  //       <Button
  //         key={`${index}-${item.id}`}
  //         className={"link"}
  //         onClick={getActionButtonTitle(item.title) === "Send All" ? showModal : showCallSetupModal}
  //       >
  //         {getActionButtonTitle(item.title)}
  //       </Button>
  //     ),
  //     children: (
  //       <List
  //         key={`${index}-${item.id}`}
  //         itemLayout={"horizontal"}
  //         loadMore={loadMoreBtn}
  //         dataSource={item.info.slice(0, visibleItems)}
  //         renderItem={(it: any, index) => (
  //           <List.Item
  //             key={`${index}-${it.id}`}
  //             actions={[<Button key={1} className={"link"} onClick={() => (item.title === "Emails" || item.title === "SMS" ? showModal() : closeModal())}>{getActionItemButtonTitle(item.title)}</Button>]}
  //           >
  //             <Space direction={"vertical"} size={4}>
  //               {item.title === "Tasks" ? (
  //                 <Typography.Paragraph>{it.name}</Typography.Paragraph>
  //               ) : (
  //                 <>
  //                   <Popconfirm
  //                     title={title}
  //                     placement={"topLeft"}
  //                     showCancel={false}
  //                     style={{ width: 528 }}
  //                     okType={"link"}
  //                     okText={"Move"}
  //                     onConfirm={showSelectStepModalModal}
  //                     icon={false}
  //                   >
  //                     <Checkbox
  //                       checked={selectedActions.includes(it.id)}
  //                       onChange={e => handleCheckboxChange(item.title, e.target.checked, it.id)}
  //                     >
  //                       <Typography.Paragraph className={"contact-name"}>{it.name}</Typography.Paragraph>
  //                     </Checkbox>
  //                   </Popconfirm>
  //                   <Typography.Paragraph className={"contact-data"}>{it.contact}</Typography.Paragraph>
  //                 </>
  //               )}
  //             </Space>
  //         </List.Item>
  //         )}
  //       ></List>
  //     ),
  //   }
  // })

  const location = useLocation();

  //  for when reconnecting emails this is necessary to show whats happening
  useEffect(() => {
    localStorage.removeItem("latestMeetzPg");

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => typeof prop === "string" && searchParams.get(prop),
    });
    const err = params?.error;
    const email = params?.em;
    const id = params?.id;
    const type = params?.type;
    const iframe = params?.iframe;
    if (err) {
      // push('/ai_email_campaign');
      // dispatch(emailErr({ email, type }));
      message.destroy();
      message.error(
        `Error integrating your ${
          type === "google" ? "Google" : "Outlook"
        } email. Make sure that you accepted the necessary permissions. Try to connect to it again soon.`
      );
    } else if (email && id) {
      dispatch(addEmailBe({ id, email }));
      message.destroy();
      message.info("Adding your email address now...");

      if (iframe === "true") {
        const jwt = localStorage.getItem("token");
        if (jwt) {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
        } else {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz`;
        }
      }
      window.history.pushState({}, "", "/campaigns");
      setOpenDrawer(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (succMsg) {
      message.destroy();
      message.success(succMsg);
      dispatch(resOutcomeEm());
    }
  }, [succMsg]);
  useEffect(() => {
    if (failMsg) {
      message.destroy();
      message.error(failMsg);
      dispatch(resOutcomeEm());
    }
  }, [failMsg]);
  useEffect(() => {
    if (infoMsg) {
      message.destroy();
      message.info(infoMsg);
      dispatch(resOutcomeEm());
    }
  }, [infoMsg]);

  const trialClient = useSelector(userIsTrialSelector);
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClsDrawer = () => {
    setOpenDrawer(false);
  };

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [tabAppModal, setTabAppModal] = useState("app");

  useEffect(() => {
    if (openSettingsModal) {
      setTabAppModal("app");
      setOpenApproveModal(true);
      dispatch(setOpenCampSettings(false));
    }
  }, [openSettingsModal]);
  const showAddSetUpModal = () => {
    setTabAppModal("emSetUp");
    setOpenApproveModal(true);
  };
  const showAddSetUpDialerModal = () => {
    setTabAppModal("phoneSteps");
    setOpenApproveModal(true);
  };
  //   const showAppSetUpModal = () => {
  //     setTabAppModal('app')
  //   setOpenApproveModal(true);
  // };
  const onCloseAppModal = () => {
    setOpenApproveModal(false);
    dispatch(setOpenCampSettings(false));
    setTabAppModal("app");
  };

  const actionsForTodayCount = totalAutoEmails + totalNoAutoEmails + totalPhones;

  const calculateEmailMarginRight = (number) => {
    return "160px";
    if (number === 1) {
      // 1 is small pixel amount so below logic no good
      return `162px`;
    }
    return `${160 - ((number || 0)?.toString()?.length - 1) * 7}px`;
  };
  const [emSched, setEmSchedTimezone] = useState<any>(null);
  const [minWorkingInNext24Hrs, setMinWorkingInNext24Hrs] = useState(0);

  useEffect(() => {
    if (additionalEmailsTimezone?.length) {
      // console.log(additionalEmailsTimezone, "additionalEmailsTimezone");
      setEmSchedTimezone(additionalEmailsTimezone);
    }
  }, [additionalEmailsTimezone]);
  const [schedAvail, setSchedAvail] = useState<any>([]);
  const [isWithinSchedule, setIsWithinSchedule] = useState(false);
  useEffect(() => {
    setSchedAvail(
      Object.fromEntries(
        Object.entries(additionalEmailsSched).map(([key, value]) => [
          key,
          value.map((timerange) => [dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")]),
        ])
      )
    );
    // setSchedAvail(Object.fromEntries(Object.entries(additionalEmailsSched).map(([key, value]) => [key, value.map(timerange => ([timerange.start, timerange.end] ))])))
  }, [additionalEmailsSched]);
  useEffect(() => {
    const defaultTimezone = emSched || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTime = new Date();
    const currentLocalTime = currentTime.toLocaleString("en-US", { timeZone: defaultTimezone });
    const dayOfWeek = currentTime.toLocaleString("en-US", { weekday: "long", timeZone: defaultTimezone }).toLowerCase();

    const isCurrentTimeInSchedule = (schedAvail) => {
      // console.log(defaultTimezone, "def");

      if (schedAvail[dayOfWeek]) {
        const daySchedule = schedAvail[dayOfWeek];
        return daySchedule.some(([start, end]) => {
          const startTime = new Date(start);
          const endTime = new Date(end);

          return new Date(startTime) <= new Date(currentLocalTime) && new Date(currentLocalTime) <= new Date(endTime);
        });
      }
      return false;
    };
    setIsWithinSchedule(isCurrentTimeInSchedule(schedAvail));

    // calculating how many minutes in the email sending schedule in the next 24 hours

    const timeIn24Hours = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
    const dayOfWeekIn24Hours = timeIn24Hours.toLocaleString("en-US", { weekday: "long", timeZone: defaultTimezone }).toLowerCase();
    // console.log(dayOfWeekIn24Hours, "timeIn24Hours");

    let minutesInEmailSendingScheduleInNxt24Hrs = 0;

    const countTimeInSchedule = (day) => {
      const daySchedule = schedAvail[day];
      return daySchedule.forEach(([start, end]) => {
        const startTime = new Date(start);
        const endTimeSched = new Date(end);
        const endTime = endTimeSched < timeIn24Hours ? endTimeSched : timeIn24Hours;
        if (endTime < startTime) {
          return;
        }
        // console.log(endTimeSched, timeIn24Hours, "endTimeSched", endTime);

        if (new Date(startTime) <= new Date(currentLocalTime) && new Date(currentLocalTime) <= new Date(endTime)) {
          const minutesUntilEnd = Math.floor((endTime?.getTime() - new Date(currentLocalTime)?.getTime()) / (1000 * 60));
          minutesInEmailSendingScheduleInNxt24Hrs += minutesUntilEnd;
          // console.log("Minutes until endTime:", minutesUntilEnd, currentLocalTime, endTime);
        } else if (new Date(currentLocalTime) <= new Date(endTime)) {
          // not in the middle of this date range
          const minutesUntilEnd = Math.floor((endTime?.getTime() - startTime?.getTime()) / (1000 * 60));
          minutesInEmailSendingScheduleInNxt24Hrs += minutesUntilEnd;
        }
      });
    };
    if (schedAvail[dayOfWeek]) {
      countTimeInSchedule(dayOfWeek);
    }
    if (schedAvail[dayOfWeekIn24Hours]) {
      countTimeInSchedule(dayOfWeekIn24Hours);
    }
    setMinWorkingInNext24Hrs(minutesInEmailSendingScheduleInNxt24Hrs);
  }, [schedAvail, emSched]);

  const [emailsUsedInThisCamp, setEmailsUsedInThisCamp] = useState([]);
  useEffect(() => {
    const emailUsed = selectedCampaign?.overview?.emailsToSendWith || [];

    const availEmailsSelected = emailsData?.filter((emAvail) => emailUsed?.some((em) => em?.toLowerCase() === emAvail));
    setEmailsUsedInThisCamp(
      (additionalEmails || [])?.filter((em) => availEmailsSelected?.includes(em?.smtp?.from?.toLowerCase() || em?.email_alias_emailofcal?.toLowerCase()))
    );
  }, [selectedCampaign?.overview?.emailsToSendWith, emailsData, additionalEmails]);
  // const sendStatus = "Max Emails Sent";
  const [sendStatus, setSendStatus] = useState("");
  useEffect(() => {
    let status = `Sending`;
    // const gotToMax = "gotToMax";
    let maxEmCounter = 0;
    let connectedCounter = 0;
    emailsUsedInThisCamp?.forEach((em) => {
      if (em?.connected) {
        connectedCounter += 1;
        const { warm_up_day_unix, sends_per_day } = em;
        const warmUpDaysPassed = warm_up_day_unix?.length || 0;
        // it might be 0 in the beginning

        const possibleSendsToday = sends_per_day?.length >= warmUpDaysPassed ? sends_per_day[sends_per_day?.length - 1] : sends_per_day[warmUpDaysPassed - 1]; // warm up logic
        // if(emailStatus!=='max'){
        // emailStatus==='hasConnected'
        if (possibleSendsToday === em?.sends_in_last_day) {
          maxEmCounter += 1;
        }
        // }
      }
    });
    // console.log(isWithinSchedule, "isWithinSchedule");
    let emailStatus = null;
    console.log(emailsUsedInThisCamp, "emailsUsedInThisCamp");
    if (!campRecord?.overview?.isActive) {
      status = `Inactive Campaign`;
    } else if (!totalAutoEmails) {
      status = `Completed`;
    } else if (!isWithinSchedule) {
      status = `Pending`;
    } else if (!connectedCounter) {
      status = `Disconnected`; // shouldnt happen since if this happens on backend it becomes an inactive campaign
    } else if (maxEmCounter === connectedCounter) {
      status = `Max Emails Sent`;
    }
    // setSendStatus("Sending");
    setSendStatus(status);
  }, [isWithinSchedule, campRecord?.overview?.isActive, emailsUsedInThisCamp]);

  const [willSendInNext24Hours, setWillSendInNext24Hours] = useState(0);
  useEffect(() => {
    const thisCampaign = outreachCampaignsList?.find((camp) => camp?.overview?.campId === selectedCampaignId);
    // selectedEmails;
    const emailsToSendWith = thisCampaign?.overview?.emailsToSendWith || [];
    // console.log(thisCampaign, "outreachCampaignsList", minWorkingInNext24Hrs, selectedCampaignId);

    let totalEmailsWillSendIn24Hours = 0;
    const emailsThatAreGoodToCheck = [];
    // console.log(additionalEmails, "additionalEmails");
    additionalEmails?.forEach((email) => {
      // might not be correct? but i Think this logic should be ok
      const em = email?.smtp?.from?.trim()?.toLowerCase() || email?.email_alias_emailofcal?.trim()?.toLowerCase();
      // console.log(emailsToSendWith, "emailsToSendWith",emailsToSendWith?.some((sendEmail) => sendEmail?.trim()?.toLowerCase() === em),);
      if (email?.connected && emailsToSendWith?.some((sendEmail) => sendEmail?.trim()?.toLowerCase() === em)) {
        emailsThatAreGoodToCheck.push(em);
      }
    });
    console.log(emailsThatAreGoodToCheck, "emailsThatAreGoodToCheck");
    emailsThatAreGoodToCheck?.forEach((emailSendingFrom) => {
      let emailsToSendBeforeSendingHere = 0;
      const priorCampFirst = outreachCampaignsList
        ?.filter((camp) => camp?.overview?.emailsToSendWith?.some((em) => em?.trim()?.toLocaleLowerCase() === emailSendingFrom))
        ?.sort((camp1, camp2) => {
          const camp1Priority = camp1?.overview?.campId ? 1 : 0;
          const camp2Priority = camp2?.overview?.campId ? 1 : 0;
          return camp2Priority - camp1Priority;
        })
        ?.sort((camp1, camp2) => {
          const camp1Priority = camp1?.overview?.prioritizeThisCamp ? 1 : 0;
          const camp2Priority = camp2?.overview?.prioritizeThisCamp ? 1 : 0;
          return camp2Priority - camp1Priority;
        });
      let gotToThisCampaign = false;
      priorCampFirst?.forEach((camp) => {
        if (!gotToThisCampaign) {
          if (camp?.overview?.campId === selectedCampaignId) {
            gotToThisCampaign = true;
            return;
          }
          emailsToSendBeforeSendingHere += camp?.overview?.prosEmailReady || 0;
        }
      });

      const emailDets = additionalEmails?.find(
        (em) => em?.smtp?.from?.trim()?.toLowerCase() === emailSendingFrom || em?.email_alias_emailofcal?.trim()?.toLowerCase() === emailSendingFrom
      );
      console.log(emailDets, "emailDets");
      const amntOfPossibleSendsInNext24Hrs = Math.floor(minWorkingInNext24Hrs / ((emailDets?.minimum_time_between_sends + 15) / 60));
      const { warm_up_day_unix, sends_per_day } = emailDets;
      const warmUpDaysPassed = warm_up_day_unix?.length || 0;
      // it might be 0 in the beginning

      // its for next 24 horus not just today
      const maxSendsIn24HoursAllow = sends_per_day?.length >= warmUpDaysPassed ? sends_per_day[sends_per_day?.length - 1] : sends_per_day[warmUpDaysPassed - 1]; // warm up logic
      // 20 maxSendsIn24HoursAllow
      //  100  amntOfPossibleSendsInNext24Hrs
      //30 emailsToSendBeforeSendingHere
      const possibleAndAllowableMaxSends = maxSendsIn24HoursAllow > amntOfPossibleSendsInNext24Hrs ? amntOfPossibleSendsInNext24Hrs : maxSendsIn24HoursAllow;
      const emailCouldDoInThisCamp = possibleAndAllowableMaxSends - emailsToSendBeforeSendingHere;
      // console.log(emailCouldDoInThisCamp, "emailCouldDoInThisCamp", minWorkingInNext24Hrs, (emailDets?.minimum_time_between_sends + 30) / 60);
      console.log(thisCampaign?.overview, "thisCampaign?.overview");
      if (emailCouldDoInThisCamp > 0 && thisCampaign?.overview?.prosEmailReady) {
        totalEmailsWillSendIn24Hours +=
          emailCouldDoInThisCamp > thisCampaign?.overview?.prosEmailReady ? thisCampaign?.overview?.prosEmailReady : emailCouldDoInThisCamp;
      }
    });

    setWillSendInNext24Hours(totalEmailsWillSendIn24Hours);
  }, [minWorkingInNext24Hrs, outreachCampaignsList, additionalEmails]);

  return (
    <>
      <ContentCard
        customClassName={"all-actions-card"}
        headerIcon={<SvgIcon type={"allActions"} />}
        cardTitle={
          <Flex>
            <div>
              {" "}
              {[0, 6].includes(new Date().getDay()) ? (
                <>
                  Actions for this week{" "}
                  {
                    !!payingClient && (
                      <Tooltip
                        placement={"top"}
                        title={
                          <>
                            Weekend Tip: <br />
                            Outreach is generally more effective during the work week rather than on the weekends 🏖️🌴
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 8 }} />
                      </Tooltip>
                    )
                    // only if unlocked, otherwise they can think to unlock Meetz and pay 1/2 days later to strat camp and forget
                  }
                </>
              ) : (
                `All Actions for Today`
              )}
              {/* {!campRecord?.overview?.isActive && <span style={{color:'orange',fontWeight:450,fontSize:'14px',marginLeft:'8px'}}>- campaign inactive</span>} */}
            </div>{" "}
            {!!payingClient &&
              !!campRecord?.overview?.isActive &&
              !!campRecord?.emailDisconnectedData?.length &&
              !!campRecord?.emailDisconnectedData[0]?.emailDisconnected?.length && (
                <>
                  <Tooltip
                    placement={"top"}
                    title={
                      <>
                        <span style={{ fontWeight: 500 }}>Warning:</span> Some prospects were assigned an email address that is now disconnected from your
                        account and now can't proceed in the step. <br />
                        (Individual prospects are assigned 1 of your email addresses to ensure they get emails only from 1 address throughout the flow)
                        <br />
                        <br />
                        Emails That Are Disconnected:
                        {campRecord?.emailDisconnectedData?.map((em) => (
                          <>
                            {" "}
                            <br />
                            <span style={{ fontWeight: 600 }}>{em.emailDisconnected}</span> - {em.amount} prospect{em.amount > 1 && "s"} associated to this
                            email
                          </>
                        ))}
                        <br />
                        <br />
                        <br />
                        <span style={{ cursor: "pointer", textDecoration: "underline", fontWeight: 500 }} onClick={showDrawer}>
                          Reconnect Email
                        </span>
                        - Recommended
                        <br />
                        <br />
                        <span
                          style={{ cursor: "pointer", textDecoration: "underline", color: "orangered", fontWeight: 500 }}
                          onClick={() => {
                            message.info(`Reset`);
                            dispatch(resetCampIdDiscEmails({ campId: campRecord?.overview?.campId }));
                          }}
                        >
                          Reassign email addresses
                        </span>{" "}
                        - Only if you can't reconnect emails
                      </>
                    }
                  >
                    {/* TODOF add blog link so they can read why this happens not critical now */}
                    <SvgIcon type={"warning"} style={{ marginLeft: 8, cursor: "pointer", width: "18px", height: "18px" }} />
                  </Tooltip>
                </>
              )}
          </Flex>
        }
        headerRightContent={
          !loading && payingClient ? (
            <Flex>
              {/* <Button className="link" style={{ marginRight: "25%" }}>
                <SvgIcon type={"refresh"} width={12} height={12} />
                Refresh
              </Button> */}
              <Button
                className={"link plus-btn"}
                onClick={() => {
                  dispatch(
                    getFilteredLeadsRst({
                      uploadName: "All",
                      recentlyUpdated: false,
                      includeInCampaign: false,
                      includeContactedAlready: false,
                      page: 1,
                      pageChange: false,
                      selectedCampaignId: null,
                    })
                  );
                  navigate(`/campaign/${id}/prospects`);
                }}
              >
                Add Prospects
              </Button>
            </Flex>
          ) : null
        }
      >
        {
          // !payingClient ? (
          //   <Flex vertical justify={"center"}>
          //     <Flex justify={"center"}>
          //       <img src={manWithSkateboard} alt={"Plan not activated"} width={198} height={200} />
          //     </Flex>
          //     <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center", marginTop: 22 }}>
          //       Campaign is not activated
          //     </Typography.Paragraph>
          //     {/* <Button className={"link"} style={{ marginTop: 8 }} onClick={handleUpdatePlanClick}>
          //       Update Plan
          //     </Button> */}
          //   </Flex>
          // ) :
          // actionsForTodayCount && items?.length ? (
          // <Collapse items={items} controlActKeys={controlActKeys} setControlActKeys={setControlActKeys} />
          actionsForTodayCount ? (
            <>
              {phoneStepsOptions?.length > 1 && (
                <div style={{ paddingBottom: "20px" }}>
                  <Flex style={{ justifyContent: "space-between" }}>
                    {" "}
                    <Typography.Title level={4}>Phone Call Steps</Typography.Title>
                    {!phone_numbers?.length ? (
                      <Button className={"link"} onClick={showAddSetUpDialerModal}>
                        No phone number, add it here
                      </Button>
                    ) : dialerCanCallNowInTheirWorkingHours && campRecord?.overview?.isActive ? (
                      <>
                        {" "}
                        {[0, 6].includes(new Date().getDay()) ? (
                          <Tooltip
                            placement={"top"}
                            title={
                              <>
                                Recommended to not call now, unless these are leads that left their details or you found success with calling on the weekends in
                                the past.
                                {/* <br />
                                <br />
                                📲 */}
                              </>
                            }
                          >
                            <div>It's the weekend 🏖️</div>
                          </Tooltip>
                        ) : (
                          ``
                        )}
                        <Button className={"link"} onClick={activatePowerDialer}>
                          {predictiveDialerOn || isPopUpOpen
                            ? `Dialer Popup Is Open Now`
                            : readyToDial
                            ? `Activate parallel dialer`
                            : `Resetting power dialer...`}
                        </Button>
                      </>
                    ) : (
                      <Tooltip
                        placement={"top"}
                        title={
                          <>
                            {!campRecord?.overview?.isActive
                              ? `First activate the campaign above with the blue button`
                              : `No prospects queued, so can't activate dialer. 📵`}{" "}
                          </>
                        }
                      >
                        {" "}
                        <Button className={"link"} onClick={activatePowerDialer}>
                          {predictiveDialerOn || isPopUpOpen
                            ? `Dialer Popup Is Open Now`
                            : readyToDial
                            ? `Activate parallel dialer`
                            : `Resetting power dialer...`}
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>
                  <div className={`infoCards`} style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      // className={`infoCards`}
                      style={
                        {
                          // display: "flex",
                          // gap: "10px", // Adds spacing between the two sections
                          // padding: "10px",
                          // backgroundColor: "#f9f9f9",
                          // borderRadius: "5px",
                          // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                        }
                      }
                    >
                      <div
                        style={{
                          flex: 1, // Equal width
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px",
                          borderBottom: "0.5px solid lightgrey",
                        }}
                      >
                        <span
                          style={{
                            width: "300px",
                          }}
                        >
                          Prospects ready for the dialer step
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginLeft: "2px",
                            // marginRight: "8%",
                            width: "200px",
                          }}
                        >
                          {totalPhones}
                        </span>
                        <Tooltip placement={"top"} title={<>These prospects have completed the delay and are ready to receive a call.</>}>
                          <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 8 }} />
                        </Tooltip>
                      </div>

                      <div
                        style={{
                          flex: 1, // Equal width
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <span
                          style={{
                            width: "300px",
                          }}
                        >
                          Queued for calls now
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginLeft: "2px",
                            // marginRight: "8%",
                            width: "200px",
                          }}
                        >
                          {`${dialerCanCallNowInTheirWorkingHours || 0}`}/{totalPhones}
                        </span>
                        <Tooltip
                          placement={"top"}
                          title={
                            <>
                              Queued prospect are prospects who are ready to get a dialer step and it's in the middle of their working hour times that you set
                              in the "Calls" tab. We check their timezone not yours to guage this. <br />
                              <br />
                              This helps avoid accidentally calling prospects in the middle of the night. 🛌🏻💤
                            </>
                          }
                        >
                          <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 8 }} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {emailStepsOptions?.length > 1 && (
                <>
                  <div style={phoneStepsOptions?.length > 1 ? { paddingTop: "25px", borderTop: "0.5px solid lightgrey" } : { paddingTop: "5px" }}>
                    <Flex style={{ justifyContent: "space-between" }}>
                      {" "}
                      <Typography.Title level={4}>Email Steps</Typography.Title>{" "}
                      <Button
                        className={"link"}
                        onClick={() => (totalNoAutoEmails ? approveEmails("all") : showAddSetUpModal())}
                        style={{ marginLeft: "15px" }}
                      >
                        {totalNoAutoEmails ? `Approve steps & send` : `Manage emails`}
                      </Button>
                    </Flex>
                    <div className={`infoCards`} style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        // className={`infoCards`}
                        style={
                          {
                            // display: "flex",
                            // gap: "10px", // Adds spacing between the two sections
                            // padding: "10px",
                            // backgroundColor: "#f9f9f9",
                            // borderRadius: "5px",
                            // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          }
                        }
                      >
                        <div
                          style={{
                            flex: 1, // Equal width
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                            borderBottom: "0.5px solid lightgrey",
                          }}
                        >
                          <span
                            style={{
                              width: "300px",
                            }}
                          >
                            Email sending status
                          </span>{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginLeft: "2px",
                              width: "200px",
                              color:
                                sendStatus === `Sending`
                                  ? "teal"
                                  : sendStatus === `Completed`
                                  ? "green"
                                  : sendStatus === `Inactive Campaign`
                                  ? "#FAAD14"
                                  : sendStatus === "Max Emails Sent"
                                  ? "darkviolet"
                                  : "grey",
                              // marginRight:
                              //   // sendStatus === `Inactive Campaign`
                              //   //   ? `10%`
                              //   //   : sendStatus === `Completed`
                              //   //   ? "19.5%"
                              //   //   : sendStatus === "Max Emails Sent"
                              //   //   ? "15%":
                              //   "8%",
                            }}
                          >
                            {sendStatus}
                          </span>
                          <Tooltip
                            placement={"top"}
                            title={
                              <>
                                Email Sending Statuses:
                                <br />
                                <br />
                                <b>Completed</b>: Completed planned sends. No prospects marked as "Ready" in a step now.
                                <br />
                                <br />
                                <b>Sending</b>: Emails are scheduled to be sent and no action needs to be done on your end. Check sends in the next 24 hours to
                                see how many will be sent.
                                <br />
                                <br /> <b>Inactive Campaign</b>: Need to activate this campaign to continue email sends.
                                <br />
                                <br /> <b>Pending</b>: Waiting on the next business day to start sending out emails. If you want to adjust your email sending
                                schedule click above this on "Manage emails" {`->`} "Edit Email Addresses" {`->`} then "Email Sending Settings" tab.
                                <br />
                                <br /> <b>Max Emails Sent</b>: All of your email addresses connected to this campaign have maximized their daily email sends,
                                connect more or increase the email sending limitations by clicking above this on "Manage emails" {`->`} "Edit Email Addresses"
                                and increase the limitations.
                              </>
                            }
                          >
                            <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 8 }} />
                          </Tooltip>
                        </div>

                        <div
                          style={{
                            flex: 1, // Equal width
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "0.5px solid lightgrey",
                            padding: "10px",
                          }}
                        >
                          <span
                            style={{
                              width: "300px",
                            }}
                          >
                            Email sends in the next 24 hours
                          </span>{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginLeft: "2px",
                              // marginRight: "8%",
                              width: "200px",
                            }}
                          >
                            {willSendInNext24Hours}
                          </span>
                          <Tooltip
                            placement={"top"}
                            title={
                              <>
                                Estimated email sends to send in the next 24 hours. <br />
                                <br />
                                How to increase email sends:
                                <br />
                                <br />
                                1. Increase your email addresses daily sending limits (Not recommended to go over 50-80 sends per day with Gmail or Outlook)
                                <br />
                                2. Reduce time in between sends
                                <br />
                                3. Add more email addresses to send from (Use SMTP like Sendgrid to increase sends from 1 email to 200-500 sends a day)
                                <br />
                                4. Make the email sending schedule longer every day
                                <br />
                                5. Prioritize this campaign over older campaigns in the "Campaign Settings" then emails will be sent here first
                                <br />
                                6. Add more prospects to this campaign
                              </>
                            }
                          >
                            <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 8 }} />
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            flex: 1, // Equal width
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                            borderBottom: "0.5px solid lightgrey",
                          }}
                        >
                          <span
                            style={{
                              width: "300px",
                            }}
                          >
                            Sends in the last 24 hours
                          </span>{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginLeft: "2px",
                              // marginRight: "8%",
                              width: "200px",
                            }}
                          >
                            {sendsInLast24}
                          </span>
                          <Tooltip placement={"top"} title={<>Counts emails sent in this campaign.</>}>
                            <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 8 }} />
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            flex: 1, // Equal width
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                          }}
                        >
                          <span
                            style={{
                              width: "300px",
                            }}
                          >
                            Emails connected to this campaign
                          </span>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginLeft: "2px",
                              // marginRight: "8%",
                              width: "200px",
                            }}
                          >
                            {selectedEmails.length || 0}
                          </span>
                          <Tooltip
                            placement={"top"}
                            title={
                              <>
                                Your email addresses to send from that are connected to this campaign. Increasing emails connected to this campaign will
                                increase how many sends will be sent per day.
                              </>
                            }
                          >
                            <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 8 }} />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <Flex vertical gap={22} justify={"center"}>
              <Flex justify={"center"}>
                <img src={noActions} alt={"No actions"} width={309} />
              </Flex>
              <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center" }}>
                {!!emailStepsOptions?.length || !!phoneStepsOptions?.length
                  ? `No Actions For Today.${selectedStepNum ? ` (Also step ${selectedStepNum} doesn't need action today 🙂)` : ""}`
                  : 'No steps... add steps by clicking on "Edit Sequence"'}
              </Typography.Paragraph>
            </Flex>
          )
        }
      </ContentCard>
      {/* <SelectEmailModal data={data[0].info} open={openModal} onCancel={onCancel} /> */}
      <ViewAiEmailModal open={!!aiEmailOpen?.pros?.id} prospectId={aiEmailOpen?.pros?.id} isAuto={!!aiEmailOpen?.isAuto} onCancel={() => setAiEmailOpen({})} />
      <MoveProspectsSelectStepModal open={openSelectStepModal} onCancel={onCancel} />

      <CampApproveModal
        tabAppModal={tabAppModal}
        setTabAppModal={setTabAppModal}
        emailStepsOptions={emailStepsOptions}
        phoneStepsOptions={phoneStepsOptions}
        open={openApproveModal}
        onCancel={onCloseAppModal}
      />
      {/* TODOF Add the above option to move the prospects from 1 step to the nex */}

      <ManageEmailDrawer open={openDrawer} onCancel={onClsDrawer} campaign={campaignDisplaying?.campaign} />
      {/* <CallSetupModal data={data[1].info} open={openCallSetupModal} onCancel={onCancel} /> */}
    </>
  );
};
