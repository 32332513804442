import { Form, Input, Radio, Select, TimePicker, Typography } from "antd";
import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { CustomSelect, TextEditor, WeekTimePlanner } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import "./index.less";
import { useSelector } from "react-redux";
import { fullUserSelector } from "src/store/user";
import { isSummerTimeChecker } from "src/store/util";
import { WeekTimePlannerWithTabs } from "src/components/ui/WeekTimePlannerWithTabs";
import { outreachEmailsSelector } from "src/store/outreach-emails";

export const EmailSchedulingTab = ({
  settimezoneSelected,
  timezoneSelected,
  schedAvail,
  setSchedAvail,
  handleAddrChange,
  businessAdd,
  unsubSent,
  handleUnsubChange,
  emailCampDrawerOpen,
}) => {
  const [form] = Form.useForm();
  // const timeFormat = selectedTimeFormat === "24h" ? "HH:mm" : "h:mm A"
  const timeFormat = "h:mm A";
  const [timezoneOptions, settimezoneOptions] = useState<any[]>([]);
  const handleChangeTimeZone = (e) => {
    settimezoneSelected(e.split(" ")[0]);
  };
  const is_dst = isSummerTimeChecker();

  const { timezones } = useSelector(fullUserSelector);
  // const { timezone_abbr } =settings
  const { additionalEmailsTimezone } = useSelector(outreachEmailsSelector);

  const displayValueGetter = (option: { name: any; abbr_summer: any; abbr_winter: any }) => {
    const res = is_dst ? `${option.name} ${option.abbr_summer}` : `${option.name} ${option.abbr_winter}`;

    return res;
  };

  // useEffect(() => {
  //   if (timezones.length) {
  //     console.log(additionalEmailsTimezone, "timezonetimezonetimezone");
  //   }
  // }, [timezones]);
  useEffect(() => {
    if (additionalEmailsTimezone?.length) {
      form.setFieldsValue({ timezone: additionalEmailsTimezone });
      settimezoneSelected(additionalEmailsTimezone);
    }
  }, [additionalEmailsTimezone]);
  useEffect(() => {
    if (timezones?.length) {
      settimezoneOptions([...timezones.map((tmz) => ({ label: displayValueGetter(tmz), value: displayValueGetter(tmz) }))]);
    }
  }, [timezones]);

  const handleUpdateSched = (newSched) => {
    setSchedAvail(newSched);
  };
  useEffect(() => {
    form.setFieldsValue({ busAddress: businessAdd });
  }, [businessAdd]);
  // useEffect(() => {
  //   console.log(unsubSent, "unsubSent");
  //   //  form.setFieldsValue({unsubSent})
  //   //  form.setFieldsValue({unsubSent})
  // }, [unsubSent]);

  // const onContentChange = (change) => {
  //   console.log(change);
  // };

  return (
    <Form
      className={"email-scheduling-form"}
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ timezone: timezoneSelected?.length ? `${timezoneSelected[0]} ${timezoneSelected[1]}` : "", availability: schedAvail }}
    >
      {emailCampDrawerOpen && (
        <>
          Account Settings (Not campaign specific):
          <br />
          <br />
        </>
      )}
      <div style={{ fontSize: "18px", fontWeight: 600 }}>Footer of Emails:</div>
      <br />
      <Form.Item name={"busAddress"} label={"Physical Business Address - Recommended"}>
        <Input placeholder={"Enter your business address"} onChange={handleAddrChange} />
      </Form.Item>
      <Form.Item name={"unsubSent"} label={"Unsubscribe Sentence - Recommended"}>
        {/* <Input placeholder={"Recommended to NOT leave unsubscribe sentence empty"} onChange={handleUnsubChange} /> */}
        {/* <Flex vertical style={{ marginBottom: 22 }}> */}
        <TextEditor content={unsubSent || ""} onContentChange={handleUnsubChange} unsubSentence />
        {/* </Flex> */}
      </Form.Item>
      <br />
      <div style={{ fontSize: "18px", fontWeight: 600 }}>Sending Schedule:</div>
      <br />
      <Form.Item name={"timezone"} label={"Timezone"}>
        {/* <Select placeholder={"American/New_York EST"} /> */}
        <CustomSelect
          options={timezoneOptions}
          onChange={handleChangeTimeZone}
          value={timezoneSelected?.length ? `${timezoneSelected[0]} ${timezoneSelected[1]}` : ""}
        />
      </Form.Item>
      {/* <Form.Item name={"timeFormat"} label={"Select Time Format"}>
        <Radio.Group defaultValue={"24h"} size={"middle"} onChange={e => setSelectedTimeFormat(e.target.value)}>
          <Radio.Button value={"24h"}>24h</Radio.Button>
          <Radio.Button value={"AM/PM"}>AM/PM</Radio.Button>
        </Radio.Group>
      </Form.Item> */}

      <WeekTimePlannerWithTabs
        scheduling={false}
        initialAvail={schedAvail}
        setInitialAvail={handleUpdateSched}
        formItemName={["availability"]}
        className={"meeting-link-week-planner"}
      />
      {/* <WeekTimePlanner timeFormat={timeFormat} />
      <Form.Item name={"workingHours"} label={"Working Hours For Prospect"} style={{ marginTop: 24 }}>
        <TimePicker.RangePicker
          suffixIcon={<SvgIcon type={"arrowSuffix"} />}
          defaultValue={[dayjs("4:00", timeFormat), dayjs("9:00", timeFormat)]}
                  className={"time-picker"}
                  format={"h:mm A"}
                   needConfirm={false} 
                allowClear={false}
                minuteStep={15} 
        /> 
        
      </Form.Item> */}
    </Form>
  );
};
