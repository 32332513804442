import { Form, Input } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";
import TextArea from "antd/es/input/TextArea";

type EditTextModalProps = {
  placeholder?: string;
  initialValue?: string;
  title: string;
  label: string;
  onClose: VoidFunction;
  handleSubmit: (value: any) => void;
};

export const EditTextModal: FC<EditTextModalProps> = ({ title, label, onClose, placeholder, initialValue, handleSubmit }) => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    const formValues = form.getFieldsValue();
    handleSubmit(formValues);
  };
  const [formValues, setFormValues] = useState({ editedtext: initialValue });

  const handleValuesChange = (_, allValues) => {
    setFormValues({ editedtext: allValues.editedtext });
    // console.log(formValues,'formValuesformValues');
  };

  return (
    <Modal width={530} open title={title} onCancel={onClose}>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        initialValues={{ editedtext: initialValue }}
        onValuesChange={handleValuesChange}
        wrapperCol={{ span: 24 }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          name={"editedtext"} // do not change
          label={label}
          // initialValue={initialValue }
          rules={[{ required: true, message: "Please enter a file name" }]}
        >
          <Input placeholder={placeholder} />
        </Form.Item>
        {/* <Form.Item  name={"body"} label={"Body"} initialValue={ "A design system for enterprise-level products. Create an efficient and enjoyable work experience."  }>
     <TextArea placeholder={"A design system for enterprise-level products. Create an efficient and enjoyable work experience."}/>
      </Form.Item> */}
      </Form>
      <ConfirmButtons
        rightButtonTitle={"Save"}
        leftButtonTitle={"Discard Changes"}
        onCancel={onClose}
        disabled={!formValues?.editedtext?.length}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  );
};
