import { Dropdown, Flex, MenuProps, Radio, Row, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { DeleteSavedFilterModal } from "../DeleteSavedFilterModal";
import { SaveFiltersModal } from "../SaveFiltersModal";
import "./index.less";

type ApplyFromSavedModalProps = {
  data: any;
  open: boolean;
  onCancel: VoidFunction;
};

const savedFiltersData = [
  {
    id: 1,
    name: "Summary",
  },
];

export const ApplyFromSavedModal: FC<ApplyFromSavedModalProps> = ({ data, open, onCancel }) => {
  const [openEditFilterModal, setOpenEditFilterModal] = useState(false);
  const [openDeleteSavedFilterModal, setOpenDeleteSavedFilterModal] = useState(false);
  const [savedFilterData, setSavedFilterData] = useState<any>([]);
  const [selectedItemId, setSelectedItemId] = useState("");

  const handleDropdownClick = (itemId: string) => {
    setSelectedItemId(itemId);
  };

  const selectedFilter = savedFilterData.find((item: any) => item.id === selectedItemId);

  useEffect(() => {
    if (data) setSavedFilterData(data);
  }, [data]);

  const items: MenuProps["items"] = [
    {
      label: "Edit",
      key: "0",
      onClick: () => setOpenEditFilterModal(true),
    },
    {
      type: "divider",
    },
    {
      label: "Delete",
      key: "1",
      onClick: () => setOpenDeleteSavedFilterModal(true),
    },
    {
      type: "divider",
    },
    {
      label: "Duplicate",
      key: "0",
    },
  ];

  const closeModal = () => {
    onCancel();
  };
  const closeDropdownModals = () => {
    setOpenDeleteSavedFilterModal(false);
    setOpenEditFilterModal(false);
  };

  const handleFormSubmit = () => {
    // console.log(" test")
    onCancel();
  };

  return (
    <>
      <Modal width={553} customClassName={"from-saved-modal"} open={open} title={"Select From Saved Filters"} onCancel={closeModal}>
        <Flex className={"saved-content-container"} vertical gap={8}>
          <Typography.Paragraph className={"saved-title"}>Saved Value Proposition</Typography.Paragraph>
          {savedFilterData ? (
            <Radio.Group style={{ display: "flex", flexDirection: "column", width: "100%", gap: 8 }}>
              {savedFilterData.map((item: any, index: number) => {
                return (
                  <Row key={`${index}-${item.id}`} justify={"space-between"} align={"middle"} wrap={false}>
                    <Radio value={item.id}>{item.name}</Radio>
                    <Dropdown
                      menu={{ items }}
                      trigger={["click"]}
                      overlayStyle={{ width: 200 }}
                      onOpenChange={(visible) => {
                        if (visible) {
                          handleDropdownClick(item.id);
                        }
                      }}
                    >
                      <SvgIcon type={"action"} style={{ cursor: "pointer" }} />
                    </Dropdown>
                  </Row>
                );
              })}
            </Radio.Group>
          ) : (
            <Typography.Paragraph className={"saved-empty-title"}>No Value Proposition Saved.</Typography.Paragraph>
          )}
        </Flex>
        <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Apply"} onCancel={closeModal} handleSubmit={handleFormSubmit} />
      </Modal>
      <DeleteSavedFilterModal
        setSavedFilterData={setSavedFilterData}
        open={openDeleteSavedFilterModal}
        onCancel={closeDropdownModals}
        itemId={selectedItemId}
      />
      <SaveFiltersModal data={selectedFilter} open={openEditFilterModal} onCancel={closeDropdownModals} isEditModal />
    </>
  );
};
