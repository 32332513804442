import type { GetProp } from "antd";
import { Button, Checkbox, Flex, Form, Input, message, Select, Space, Tag } from "antd";
import React, { FC, useEffect, useState } from "react";
import { CustomLabel, Modal } from "src/components/ui";
import { saveToClipboard } from "src/helpers";

type AddVariableModalProps = {
  open: boolean;
  onSubmit?: (values: any) => void;
  onCancel: VoidFunction;
};

export const AddVariableModal: FC<AddVariableModalProps> = ({ open, onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  const variableOptions = [
    {
      label: "First Name",
      value: "first_name",
    },
    // {
    //   label: "Last Name",
    //   value: "last_name",
    // },
    {
      label: "Company Name",
      value: "company_name",
    },
    {
      label: "Job Title",
      value: "title",
    },
    // {
    //   label: "Email",
    //   value: "email",
    // },
    // {
    //   label: "Company's URL",
    //   value: "company_url",
    // },
  ];

  const closeModal = () => {
    onCancel();
    form.resetFields();
  };

  const handleCopyVariable = () => {
    saveToClipboard(variableValue);
    message.success("Copied successfully!");
  };

  const handleFormSubmit = () => {
    // console.log("Added");
    form
      .validateFields()
      .then((values) => {
        if (onSubmit) {
          onSubmit(values);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
    onCancel();
    form.resetFields();
  };

  // const onChange: GetProp<typeof Checkbox.Group, "onChange"> = checkedValues => {
  //   console.log("checked = ", checkedValues)
  // }

  // useEffect(() => {
  //   form.setFieldsValue({
  //     alternativeVariable:
  //   })

  // }, []);

  const [variable, setVariable] = useState("first_name");
  const [natNum, setNatNum] = useState([]);

  const updateFollowUp = (event) => {
    // const val = event
    // const currentFuIndex = natNum?.indexOf(val);
    //     const newCheckedFu = [...natNum];
    //     if (currentFuIndex === -1) {
    //       newCheckedFu.push(val);
    //     } else {
    //       newCheckedFu.splice(currentFuIndex, 1);
    //     }
    // console.log(event,'eventevent');
    // setNatNum(newCheckedFu);
    setNatNum(event);
  };

  // const [varDisText, setVarDisText] = useState('there');
  const [varAltText, setVarAltText] = useState("there");

  const variableValue = `{{var:${variable};alt:${varAltText?.trim()};nat:${natNum?.length ? natNum.toString() : "0"};}}`;

  const addVarAltText = (e) => {
    const text = e.target.value?.replace(/[\n0-9]/g, "");

    if (text?.trim()?.length) {
      if (text?.trim()?.length <= 20) {
        setVarAltText(text);
        form.setFieldsValue({ alternativeVariable: text });
      } else {
        form.setFieldsValue({ alternativeVariable: text?.slice(0, 20) });
      }
      //   if (text?.trim()?.length) {
      //   if (text?.trim()?.length<=15) {
      //     setVarAltText(text?.trim());
      //    form.setFieldsValue({alternativeVariable:text?.trim()})
      //   } else {
      //  form.setFieldsValue({alternativeVariable:text?.trim()?.slice(0,15)})
      //    }
    } else {
      setVarAltText("NaN");
    }
  };

  return (
    <Modal width={530} open={open} title={"Add Variable"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item name={"variable"} label={"1. Select Variable"} initialValue={"first_name"}>
          <Select options={variableOptions} onChange={(c) => setVariable(c)} />
        </Form.Item>
        <Form.Item
          className={"large-label-item"}
          name={"alternativeVariable"}
          label={"2. If there's no variable value for the contact, what word should be written instead? (Optional)"}
          initialValue={"there"}
        >
          <Input onChange={addVarAltText} placeholder={"Alternative"} />
        </Form.Item>
        <CustomLabel
          title={"3. Naturalize Variable? (Optional)"}
          subtitle={"Select how to naturalize the variable to make it more personalized:"}
          isFontBold={true}
        />

        <Form.Item className={"large-label-item"} name={"naturalizeVariable"}>
          <Checkbox.Group onChange={updateFollowUp}>
            <Space direction={"vertical"}>
              <Checkbox value={3}>No Special Characters</Checkbox>
              <Checkbox value={2}>No Company Initials Ex: Inc. .com</Checkbox>
              <Checkbox value={1}>1 Spelling Mistake</Checkbox>
              <Checkbox value={4}>1 Lower Case</Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <CustomLabel title={"Copy Variable Into The Follow Up Subject Or Body Template:"} isFontBold={true} />
        <Flex gap={16} align={"center"}>
          <Tag color={"processing"} bordered={false} style={{ padding: "10px 16px", borderRadius: 79, fontSize: 14 }}>
            {variableValue}
          </Tag>
          <Button className={"link"} onClick={handleCopyVariable}>
            Copy
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};
