/* eslint-disable no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandler } from "../helpers/apiHandler";
import { stringifyResults } from "../util";

const updateResults = (result: any) => {
  let { schedule, meeting_description, prospect_schedule, default_meeting_title, is_integrated } = result;
  [schedule, meeting_description, prospect_schedule, default_meeting_title, is_integrated] = stringifyResults([
    schedule,
    meeting_description,
    prospect_schedule,
    default_meeting_title,
    is_integrated,
  ]);

  return { ...result, schedule, meeting_description, prospect_schedule, default_meeting_title, is_integrated };
};

export const gettimezones = createAsyncThunk("user/getTimezones", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;

  const result = await apiHandler({
    iframeJwt,
    url: "/reactive-links/gettimezones",
    method: "get",
  });

  if (result.error) {
    // console.log(result.error, "result.error get timezones");
    throw new Error(result.error.message);
  }

  return result;
});

export const setNewAccountFalse = createAsyncThunk("user/setNewAccountFalse", async (data, { getState }: { getState: any }) => {
  const { email } = getState().user.user;
  const { iframeJwt } = getState().helpers;

  const res = await apiHandler({
    iframeJwt,
    url: "/clients/new-account",
    method: "post",
    params: { email },
  });

  return res;
});

export const openedTutorial = createAsyncThunk("user/openedTutorial", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/clients/openedTutorial",
    method: "post",
  });

  return res;
});

export const getUser = createAsyncThunk("user/getUser", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  // console.log("resuserbefore");
  const res = await apiHandler({
    iframeJwt,
    url: "/clients",
    method: "get",
  });
  // console.log(res, "resuser");
  if (`${res.statusCode}`.match(/^4|^5/) || res.message) {
    throw new Error(res.message);
  }

  return res;
});

export const initiateAndGetAllUserInfo = createAsyncThunk("user/initiateAndGetAllUserInfo", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  // console.log(iframeJwt, "initiateAndGetAllUserInfodebug before");
  const res = await apiHandler({
    iframeJwt,
    url: "/clients/initiateAndGetAllUserInfo",
    method: "get",
  });
  // console.log(res, "initiateAndGetAllUserInfodebug answer");
  if (`${res.statusCode}`.match(/^4|^5/) || res.message) {
    throw new Error(res.message);
  }

  const settings = updateResults(res?.settings);

  if (res?.cliForFirstPrmoter) {
    (window as any).fpr("referral", { uid: res?.cliForFirstPrmoter });
  }

  return { ...res, settings };
});

export const patchSettings = createAsyncThunk("user/patchSettings", async (body: NonNullable<unknown>, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const result = await apiHandler({
    iframeJwt,
    url: "/settings",
    body,
    method: "put",
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  const settings = updateResults(result?.settings);

  return { settings, integrations: result?.integrations, schedulingFormated: result?.schedulingFormated };
});

export const softDelete = createAsyncThunk("user/softDelete", async (data, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const { email } = getState().user.user;

  const res = await apiHandler({
    iframeJwt,
    url: "/clients",
    method: "delete",
    params: { email },
  });

  return res;
});

export const reinstateAccount = createAsyncThunk("user/reinstateAccount", async (data, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const { email } = getState().user.user;

  const res = await apiHandler({
    iframeJwt,
    url: "/clients/reinstate",
    method: "post",
    params: { email },
  });

  if (res.error) {
    throw new Error(res.error.message);
  }

  return res;
});

export const setNotificationsTrue = createAsyncThunk("user/setNotificationsTrue", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/clients/notifications",
    method: "post",
  });

  if (res.error || res.message || !res) {
    throw res;
  }

  return res;
});

export const patchUser = createAsyncThunk("user/patchUser", async (data: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  if (data.marked_as_registered) {
    window.fbq("track", "CompleteRegistration");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "signup",
    });

    // window.gtag("event", "conversion", { send_to: "AW-630038230/LEUnCIqDnsgZENa9tqwC" });
    // window?.hubalzscript?.action('CompleteRegistration');
  }
  if (data.marked_as_initiate_checkout) {
    window.fbq("track", "InitiateCheckout");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "initiatecheckout",
    });
    // window.gtag("event", "conversion", { send_to: "AW-630038230/lDvhCI3InsgZENa9tqwC" });
    // window?.hubalzscript?.action('InitiateCheckout');
  }
  const res = await apiHandler({
    iframeJwt,
    url: "/clients",
    method: "post",
    body: data,
  });

  if (res.error || res.message || !res) {
    throw res;
  }

  return res;
});

export const userPatch = createAsyncThunk("settings/user", async (data, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const result = await apiHandler({
    iframeJwt,
    url: "/settings/user",
    body: data,
    method: "put",
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result;
});

// login errAcc handle
export const hardDelete = createAsyncThunk("user/hardDelete", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  await apiHandler({
    iframeJwt,
    url: "/login/eraseMe",
    method: "delete",
  });
});
export const eraseOthers = createAsyncThunk("user/eraseOthers", async (_, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  await apiHandler({
    iframeJwt,
    url: "/login/eraseOthers",
    method: "delete",
  });
});

export const meetzinappanalytics = createAsyncThunk("user/meetzinappanalytics", async (data, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/login/meetzinappanalytics",
    method: "post",
    body: data,
  });
  if (`${res.statusCode}`.match(/^4|^5/) || res.message) {
    throw new Error(res.message);
  }

  return res;
});
