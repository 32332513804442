/* eslint-disable react/jsx-fragments */
import { Flex, Row, Tooltip, Typography } from "antd";
import React, { FC, useContext } from "react";
import { CampaignsContext } from "src/components/app/context/CampaignsContext";
import { Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";

type SelectStepModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  isPersonalizedAiUsed?: boolean;
};

export const SelectStepModal: FC<SelectStepModalProps> = ({ open, onCancel, isPersonalizedAiUsed = false, handleAddStep }) => {
  const { setSelectedStep, setIsStepsInCampaignPage } = useContext(CampaignsContext);

  const createCampaignItem = [
    // { TODON
    //   key: "Phone calls",
    //   icon: <SvgIcon type={"phoneCallSelect"} />,
    //   title: "Phone calls",
    //   subtitle: "      Call prospects at scale with our power dialer",
    //   className: "disabled",
    //   onClick: () => {
    //    handleAddStep('phonecall')
    //     onCancel()
    //   },
    // },

    {
      key: "AI Personalized Email",
      icon: <SvgIcon className={isPersonalizedAiUsed ? "disabled" : undefined} type={"personalizedEmailSelect"} />,
      title: (
        <Row align={"middle"} style={{ gap: 4 }}>
          <Typography.Paragraph className={isPersonalizedAiUsed ? "item-title disabled" : undefined}>AI Personalized Email</Typography.Paragraph>
          <Tooltip
            title={
              <>
                Personalized Ai emails can only be used once in a sequence. Recommended to use at the beginning of the sequence.
                <br />
                <br />
                Meetz will connect between your businesses offer and will personalize the email based on your prospects LinkedIn activity or their companys
                activity.
              </>
            }
            placement={"right"}
            rootClassName={"tooltip"}
            color={"#ECFBFC"}
          >
            <SvgIcon className={"info-tooltip"} type={"tooltip"} />
          </Tooltip>
        </Row>
      ),
      subtitle: (
        <Typography.Paragraph className={isPersonalizedAiUsed ? "item-subtitle disabled" : "item-subtitle"}>
          Personalized Ai Emails uniquely written.
        </Typography.Paragraph>
      ),
      onClick: () => {
        if (isPersonalizedAiUsed) return;
        handleAddStep("aiemail");
        onCancel();
      },
    },
    {
      key: "Templated Emails",
      icon: <SvgIcon type={"emailSelect"} />,
      title: "Templated Emails",
      subtitle: "A/B optimized emails to maximize your leads.",
      onClick: () => {
        handleAddStep("email");
        onCancel();
      },
    },
    {
      key: "Phone calls",
      icon: <SvgIcon type={"phoneCallSelect"} />,
      title: (
        <Row align={"middle"} style={{ gap: 4 }}>
          <Typography.Paragraph>Phone Call</Typography.Paragraph>
          <Tooltip title={<>Use our power dialer to call mutliple people at the same time</>} placement={"right"} rootClassName={"tooltip"} color={"#ECFBFC"}>
            <SvgIcon className={"info-tooltip"} type={"tooltip"} />
          </Tooltip>
        </Row>
      ),
      subtitle: <Typography.Paragraph className={"item-subtitle"}>Call prospects at scale with our power dialer</Typography.Paragraph>,
      onClick: () => {
        handleAddStep("phonecall");
        onCancel();
      },
    },
    // { TODOF add later
    //   key: "SMS",
    //   icon: <SvgIcon type={"smsSelect"} />,
    //   title: "SMS",
    //   subtitle: "Task is created to edit and deliver SMS.",
    //   onClick: () => {
    //     console.log("Step added")
    //     onCancel()
    //   },
    // },
    // {
    //   key: "Task",
    //   icon: <SvgIcon type={"taskSelect"} />,
    //   title: "Task",
    //   subtitle: "Create a task for the team.",
    //   onClick: () => {
    //     console.log("Step added")
    //     onCancel()
    //   },
    // },
  ];

  const closeModal = () => {
    onCancel();
  };

  const isAiPersonalizedDisable = (item: any) => {
    if (!item) return false;
    return isPersonalizedAiUsed && item === "AI Personalized Email";
  };

  return (
    <Modal width={530} centered open={open} title={"Select a Sequence step"} onCancel={closeModal}>
      <Flex vertical gap={10}>
        {createCampaignItem.map((item, index) => (
          <Flex
            key={`${index}-${item.title}`}
            className={`modal-item ${isAiPersonalizedDisable(item.key) ? "disabled-item" : undefined}`}
            align={"center"}
            gap={20}
            onClick={() => {
              item.onClick();
              setSelectedStep(item.key);
              setIsStepsInCampaignPage(true);
            }}
          >
            {item.icon}
            <Flex gap={3} vertical>
              <Typography.Paragraph className={"item-title"}>{item.title}</Typography.Paragraph>
              <Typography.Paragraph className={"item-subtitle"}>{item.subtitle}</Typography.Paragraph>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
};
