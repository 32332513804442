/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { createSlice, createSelector } from "@reduxjs/toolkit";
import { eraseProsDataFile, exportProspects, getFilterProspects, getProsFiles, renameFileName } from "./asyncRequests";
import _ from "lodash";
import { initiateAndGetAllUserInfo } from "../user/asyncRequests";
import { uploadLeadFileAndColumnInd } from "../outreach-campaigns/asyncRequests";
// import { checkIfProspIsGenerating, doNotSell, downloadProspects, getProspects, searchForAvailProsp, searchProsp } from './asyncRequests';

const prospectDataSlice = createSlice({
  name: "prospectdata",
  initialState: {
    loading: false,
    prospectsCanExportDisplayed: [],
    totalProspectsAvailable: 0 as string | number, // string or number since string shows no matches with filter
    updatedDownloadFiles: [],
    totalUpdatedProspectsCredLeft: "none",
    totalUpdatedTrialProspectsCredLeft: "none",
    newFileCreated: null,
    loadCodeDB: "",
    cursorMarkArr: [],
    gotFirstHearbeatFiles: false,
    exportFiles: [],
    deleteFileIds: [],
    editedFileNames: [] as any[],
    searchLoading: false,
    downloadLoading: false,
    noMoreSearchesAllowed: false,
    successfullUpdateMsg: false as boolean | string,
    infoUpdateMsg: false as boolean | string,
    failedUpdateMsg: false as boolean | string,
    // activateProsHeartbeat:false,
    syncedPageWithData: true,
    curProspPages: 1,
    totalProspPages: 1,
    error: null,
    searchRes: [],
    selectProspects: {},
    prospOnCurPage: [],
    latestAppliedFiltersTog: false,
    latestAppliedFilters: {},
    filterFormData: { information: ["Validated Email", "Business Email Address", "Exclude My Saved Contacts", "Recently Updated"] },

    // for do not sell only
    doNotSellSuccess: false,
  },
  reducers: {
    // resetProsHeartbeat: (state,action) => {
    //   state.activateProsHeartbeat =false;
    // },
    setFilterFormData: (state, action) => {
      state.filterFormData = action.payload;
    },
    addToRenameArr: (state, action) => {
      // const { name, id } =action.payload;
      state.editedFileNames = [...state.editedFileNames, { ...action.payload }];
    },
    setDeletedFileIds: (state, action) => {
      state.deleteFileIds = action.payload;
    },
    resetFilterFormData: (state, action) => {
      state.filterFormData = action.payload;
    },
    setAppliedFilters: (state) => {
      state.latestAppliedFilters = _.cloneDeep(state.filterFormData);
      state.latestAppliedFiltersTog = !state.latestAppliedFiltersTog;
    },
    setPageNum: (state, action) => {
      state.curProspPages = action.payload.pageNum;
      state.syncedPageWithData = action.payload.isSynced;
    },
    resetCurPagePros: (state) => {
      state.prospOnCurPage = [];
      state.totalProspectsAvailable = 0;
      state.curProspPages = 1;
      state.totalProspPages = 1;
    },
    resetSelectedRows: (state) => {
      state.selectProspects = {};
    },
    setSelectedRowKeys: (state, action) => {
      state.selectProspects = action.payload;
    },
    setPageNumSync: (state) => {
      state.syncedPageWithData = true;
    },
    resetOutcome: (state) => {
      state.successfullUpdateMsg = false;
      state.failedUpdateMsg = false;
    },

    resetNewFileCreated: (state) => {
      state.newFileCreated = null;
    },
    // setcursorMark: (state, action) => {
    //   state.cursorMarkArr = action.payload;
    // },
    // resetSearchedRes: (state) => {
    //   state.searchRes = [];
    // },
    // setSearchedRes: (state, action) => {
    //   state.searchRes = action.payload;
    // },
    // setSelectedIds: (state, action) => {
    //   state.selectProspects = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        state.exportFiles = action.payload.exportFiles;
      })
      .addCase(getFilterProspects.pending, (state) => {
        if (!state.loading) {
          state.loading = true;
        }
        state.totalProspectsAvailable = 0;
        state.curProspPages = 1;
        state.totalProspPages = 1;
        state.prospOnCurPage = [];
      })
      .addCase(eraseProsDataFile.fulfilled, (state) => {
        state.successfullUpdateMsg = "Erased file";
      })
      .addCase(uploadLeadFileAndColumnInd.fulfilled, (state, action) => {
        if (action.payload?.exportFiles?.length) {
          state.exportFiles = action.payload.exportFiles;
        }
      })
      .addCase(eraseProsDataFile.rejected, (state) => {
        state.failedUpdateMsg = "Failed to erase file, please check your internet";
        state.deleteFileIds = [];
      })
      .addCase(getProsFiles.fulfilled, (state, action) => {
        // console.log('getProsFiles');
        state.gotFirstHearbeatFiles = true; //INTERVIEW QUESTION
        state.exportFiles = action.payload.files; // could be worked on better to be seaprate from client prop but for now ok. its small
      })

      .addCase(getFilterProspects.fulfilled, (state, action) => {
        state.loading = false;
        state.prospOnCurPage = action.payload?.contacts || []; // to show if none fit
        // console.log(action, "actionaction", action.payload?.totalCount);
        state.curProspPages = action.payload?.page;
        // console.log(Math.ceil(action.payload?.totalCount / 25), "actionaction");
        state.totalProspPages = action.payload?.totalCount ? Math.ceil(action.payload?.totalCount / 25) : 1;
        state.totalProspectsAvailable = action.payload?.totalCount || "0"; // to show if none fit
        // state.generatingNow = action.payload.generatingNow;
        // state.updatedDownloadFiles = action.payload.allFiles;
      })
      .addCase(getFilterProspects.rejected, (state, action) => {
        state.loading = false;
        state.failedUpdateMsg = "Failed to get prospects, please check your internet.";
        state.totalProspectsAvailable = 0;
        state.curProspPages = 1;
        state.totalProspPages = 1;
      })
      .addCase(exportProspects.pending, (state) => {
        // state.infoUpdateMsg = `Starting export pending`;
        // console.log(123, "exportProspects.pending");
      })
      .addCase(exportProspects.fulfilled, (state, action) => {
        if (action.payload?.status === "no_credits") {
          state.failedUpdateMsg = "No prospect credits left";
          return;
        }
        // console.log(123, "exportProspects.fulfilled");
        // state.selectProspects = {};
        //  state.exportFiles = action.payload?.client?.new_prospect_data_file_extractions || []
      })
      .addCase(exportProspects.rejected, (state, action) => {
        // state.failedUpdateMsg = "Failed to export prospects, please check your internet.";
        // This is not working now sure why but doesnt affect the logic too much. for some reason is always rejected
        // console.log(123, "exportProspects.rejected");
      })
      .addCase(renameFileName.fulfilled, (state, action) => {
        state.successfullUpdateMsg = "Updated the file's name";
      })
      .addCase(renameFileName.rejected, (state, action) => {
        state.failedUpdateMsg = "Failed to update file name, please check your internet.";
      });
    //  .addCase(searchProsp.pending, (state) => {
    //     if (!state.searchLoading) {
    //       state.searchLoading = true;
    //     }
    //   })
    //  .addCase(searchProsp.fulfilled, (state, action) => {
    //     state.searchLoading = false;
    //     state.searchRes = action.payload.resultsFound;
    //   })
    //  .addCase(searchProsp.rejected, (state, action) => {
    //     state.searchLoading = false;
    //   })
    //  .addCase(getProspects.pending, (state) => {
    //     state.prospectGetLoading = true;
    //   })
    //  .addCase(getProspects.fulfilled, (state, action) => {
    //     state.prospectGetLoading = false;
    //     state.totalUpdatedProspectsCredLeft = action.payload.totalUpdatedProspectsCredLeft || 'none';
    //     state.totalUpdatedTrialProspectsCredLeft = action.payload.totalUpdatedTrialProspectsCredLeft || 'none';
    //     state.newFileCreated = action.payload.newFileCreated || null;
    //     state.updatedDownloadFiles = action.payload.updatedDownloadFiles?.length ? [...action.payload.updatedDownloadFiles] : [];
    //   })
    //  .addCase(getProspects.rejected, (state, action) => {
    //     state.prospectGetLoading = false;
    //   })
    //  .addCase(doNotSell.pending, (state) => {
    //     state.doNotSellLoading = true;
    //     console.log('etstest pending');
    //   })
    //  .addCase(doNotSell.fulfilled, (state, action) => {
    //     console.log('etstest success');
    //     state.doNotSellLoading = false;
    //     state.doNotSellSuccess = true;
    //   })
    //  .addCase(doNotSell.rejected, (state, action) => {
    //     console.log('etstest fail', action.payload);
    //     state.doNotSellLoading = false;
    //   })
    //  .addCase(downloadProspects.pending, (state) => {
    //     state.downloadLoading = true;
    //   })
    //  .addCase(downloadProspects.fulfilled, (state, action) => {
    //     state.downloadLoading = false;
    //     state.newFileCreated = null;
    //   })
    //  .addCase(downloadProspects.rejected, (state, action) => {
    //     state.downloadLoading = false;
    //   })
    //  .addCase(searchForAvailProsp.pending, (state) => {
    //     if (!state.loading) {
    //       state.loading = true;
    //     }
    //   })
    //  .addCase(searchForAvailProsp.fulfilled, (state, action) => {
    //     state.loading = false;
    //     if (action.payload.res === 'NoMoreSearch') {
    //       state.noMoreSearchesAllowed = true;
    //     }
    //     const newRes = action.payload.data || [];
    //     state.prospectsCanExportDisplayed = newRes;

    //     state.prospOnCurPage = newRes;
    //     state.totalProspectsAvailable = action.payload.totalResults || 0;
    //     state.loadCodeDB = action.payload.loadCode || '';
    //     if (action.payload.cursorMark) {
    //       state.cursorMarkArr = [...state.cursorMarkArr, action.payload.cursorMark];
    //     } else {
    //       state.cursorMarkArr = [];
    //     }
    //   })
    //  .addCase(searchForAvailProsp.rejected, (state, action) => {
    //     state.loading = false;
    //     state.cursorMarkArr = [];
    //     state.prospectsCanExportDisplayed = [];
    //     state.totalProspectsAvailable = 0;
    //     state.loadCodeDB = 'fail';
    //   })
  },
});

const { actions, reducer } = prospectDataSlice;

export const {
  setFilterFormData,
  resetOutcome,
  setPageNum,
  setPageNumSync,
  setSelectedRowKeys,
  resetSelectedRows,
  resetCurPagePros,
  setAppliedFilters,
  resetFilterFormData,
  setDeletedFileIds,
  addToRenameArr,
} = actions;
// export const { resetSearchedRes, setcursorMark, setFilterFormData, setSearchedRes, setSelectedIds, resetNewFileCreated } = actions;

export default reducer;

export const prospectDataSelector = createSelector(
  (state) => state.prospectData,
  (prospectdata) => prospectdata
);
