import { Button, Flex, Row, Space, Typography } from "antd";
import moment, { Moment } from "moment";
import { FC, useState } from "react";
import { PlansModal, Timer } from "../../billing/ui/PlansModal";
import { UnlockAccessModal } from "../UnlockMeetzModal";
import "./index.less";
import { setSkipTrial, userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";

export const ExclusiveOfferBanner: FC = () => {
  const [openUnlockAccessModal, setOpenUnlockAccessModal] = useState(false);
  const handleUnlockAccessModalClose = () => {
    setOpenUnlockAccessModal(false);
  };
  const [openPlansAndPricing, setOpenPlansAndPricing] = useState(false);
  const handleSubmit = () => {
    handleUnlockAccessModalClose();
    setOpenPlansAndPricing(true);
    // setIsDashboardLocked(true)
  };
  const dispatch = useDispatch();
  const [midAfter, setMidAfter] = useState<Moment>();
  const [isPriceDis, setIsPriceDis] = useState(false);
  const [promoter, setPromoter] = useState();
  const now = moment();
  // console.log(isPriceDis || (midAfter && !now?.isAfter(midAfter)), "checkthis123");
  let { first_name, new_payment_status } = useSelector(userSelector);
  const isTrial = new_payment_status === "trial";
  //isTrial
  return (
    <Flex
      className={"exclusive-offer-wrapper"}
      justify={"space-between"}
      align={"center"}
      style={{ display: isTrial || isPriceDis || (midAfter && !now?.isAfter(midAfter)) ? "flex" : "none" }}
    >
      <Space direction={"vertical"}>
        <Row style={{ gap: 38 }}>
          <Typography.Paragraph className={"exclusive-offer-title"}>Unlock Meetz to maximize your outreach</Typography.Paragraph>
          {/* <Timer
            midAfter={midAfter}
            setMidAfter={setMidAfter}
            isPriceDis={isPriceDis}
            setIsPriceDis={setIsPriceDis}
            promoter={promoter}
            setPromoter={setPromoter}
          /> */}
        </Row>
        <Typography.Paragraph className={"exclusive-offer-subtitle"}>
          <span className={"exclusive-offer-subtitle-accent"}>Effective outreach to get clients quickly </span> - The Future of Ai Lead Generation
        </Typography.Paragraph>
      </Space>
      <Button className={"activate-btn"} onClick={() => dispatch(setSkipTrial(true))}>
        Skip Trial
      </Button>
      <Row className={"gradient1"}></Row>
      <Row className={"gradient2"}></Row>
      <UnlockAccessModal open={openUnlockAccessModal} onCancel={handleUnlockAccessModalClose} handleSubmit={handleSubmit} />
      {/* 
      this is to show the countdown timer
      <Space direction={"vertical"}>
        <Row style={{ gap: 38 }}>
          <Typography.Paragraph className={"exclusive-offer-title"}>
            Exclusive {isPriceDis ? promoter : !now?.isAfter(midAfter) ? "New User" : ""} Package:
          </Typography.Paragraph>
          <Timer
            midAfter={midAfter}
            setMidAfter={setMidAfter}
            isPriceDis={isPriceDis}
            setIsPriceDis={setIsPriceDis}
            promoter={promoter}
            setPromoter={setPromoter}
          />
        </Row>
        <Typography.Paragraph className={"exclusive-offer-subtitle"}>
          <span className={"exclusive-offer-subtitle-accent"}>{isPriceDis ? "20% Discount" : "20% Extra Credits"}</span> On Your Meetz Plan, For The First 12
          Months!
        </Typography.Paragraph>
      </Space>
      <Button className={"activate-btn"} onClick={() => setOpenUnlockAccessModal(true)}>
        Activate Account
      </Button>
      <Row className={"gradient1"}></Row>
      <Row className={"gradient2"}></Row>
      <UnlockAccessModal open={openUnlockAccessModal} onCancel={handleUnlockAccessModalClose} handleSubmit={handleSubmit} />{" "}
      {openPlansAndPricing && (
        <PlansModal
          onClose={() => {
            setOpenPlansAndPricing(false);
            setOpenUnlockAccessModal(true);
          }}
          onCloseAll={() => {
            setOpenPlansAndPricing(false);
            setOpenUnlockAccessModal(false);
          }}
        />
      )} */}
    </Flex>
  );
};
