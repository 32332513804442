/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, Flex, Form, Input } from "antd";
import { CollapseProps } from "antd/lib";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { Collapse, ContentCard, CustomLabel, TextEditor } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { outreachCampaignSelector, setBackupEmail, setInvalidSteps } from "src/store/outreach-campaigns";
import { AddVariableModal } from "../AddVariableModal";

// TODOC change logo to my branddesign logo

export const AiPersonalizedEmail = ({
  subject,
  body,
  setSubject,
  setBody,
  setTestAiSettingsOpen,
  from,
}: {
  subject;
  body;
  setSubject;
  setBody;
  setTestAiSettingsOpen;
  from;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };
  const onCancel = () => {
    setOpenModal(false);
    // setOpenCreateVariantsModal(false);
  };

  const { backupEmail, invalidDraftStepsScratch, aiSettings, invalidDraftStepsAiGen, invalidDraftStepsEditCampaign } = useSelector(outreachCampaignSelector);
  const invalidSteps = from === "ai" ? invalidDraftStepsAiGen : from === "scratch" ? invalidDraftStepsScratch : invalidDraftStepsEditCampaign;
  const [firstLoad, setFirstLoad] = useState(false);

  // const checkForErrors = (emailvariants: any[]) => {
  useEffect(() => {
    const id = "aiEmail";
    let hasAtLeastOneEmailWithEmailBody = false;
    // emailvariants?.forEach((variant) => {
    if (body?.trim()?.length > 10) {
      hasAtLeastOneEmailWithEmailBody = true;
    }
    // });
    // console.log(hasAtLeastOneEmailWithEmailBody, "hasAtLeastOneEmailWithEmailBody");
    const inInvalidInd = (invalidSteps as any[])?.findIndex((inv) => inv?.id === id);
    const copInvalidSteps = [...(invalidSteps || [])];
    if (hasAtLeastOneEmailWithEmailBody && inInvalidInd !== -1) {
      copInvalidSteps.splice(inInvalidInd, 1);
      dispatch(setInvalidSteps({ copInvalidSteps, from }));
    } else if (!hasAtLeastOneEmailWithEmailBody && inInvalidInd === -1) {
      copInvalidSteps.push({ id, stepIssue: `Ai Personalized Email` });
      dispatch(setInvalidSteps({ copInvalidSteps, from }));
    }
  }, [body]);
  // };

  useEffect(() => {
    if (!firstLoad && backupEmail?.subject) {
      setSubject(backupEmail?.subject);
      setBody(backupEmail?.body);
      setFirstLoad(true);
    }
  }, [backupEmail]);

  const handleUnsubChange = (text, isSubject) => {
    // console.log(text, isSubject, "text,isSubject");
    if (isSubject) {
      setSubject(text);
    } else {
      setBody(text);
    }
  };

  const itemsBackup: CollapseProps["items"] = [
    {
      key: "1",
      label: <>Backup email (If you run out of Ai Personalized Emails)</>,
      children: (
        <>
          {" "}
          {/* <Flex justify="center" align="center"> */}
          <Button className={"link"} style={{ marginRight: "15px" }} onClick={showModal}>
            Add Variable
          </Button>{" "}
          <br />
          <br />
          {/* </Flex> */}
          <CustomLabel isFontBold title={"Backup Email Subject Line"} />
          <Input onChange={(event) => handleUnsubChange(event.target.value, true)} className={"content-input"} value={subject || ""} />
          <br />
          <br />
          <CustomLabel isFontBold title={"Backup Email Body"} />
          <TextEditor content={body || ""} onContentChange={(e) => handleUnsubChange(e, false)} />
        </>
      ),
    },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Why do personalized emails work?",
      children: (
        <>
          <b>Personalized emails mainly work for 2 main reasons:</b>
          <br />
          <br />
          1. Personalized emails increase opens dramatically since Meetz is A/B testing every email going out
          <br />
          <br />
          2. By using researched information about your prospect and their company, the Ai email provides the <b>best possible introduction</b> to a cold
          audience since it talks about <b>their</b> pain points and how to specifically help <b>them</b> with your solution
          <br />
        </>
      ),
    },
    {
      key: "2",
      label: "How are the Meetz emails personalized?",
      children: (
        <>
          <b> All of Meetz personalization is done automatically with no need for any work from your end!</b> <br />
          <br />
          1. We use your prospects LinkedIn information to check if they commented/liked/shared any post that is relevant to your business. <br />
          <br />
          2. If they are not active in LinkedIn we'll use their bio/ company information/ or use their job title to word the email according to whether they are
          an individual contributor or a manager who's in charge of a team and use your value proposition accordingly.
          <br />
          <br />
          3. Every email will be personalized on a one to one basis for every prospect
        </>
      ),
    },
    {
      key: "3",
      label: `What if I want to read the personalized emails before they are sent out?`,
      children: (
        <>
          You can test the Ai Emails above to find the output that you like and matches your tone. If you want to review the emails before they are sent out you
          can disable "Automatic Start" to read them before it's sent out.
        </>
      ),
    },
    {
      key: "4",
      label: `What if I run out of "Ai Personalized Email" credits?`,
      children: (
        <>
          Meetz will automatically use the above templated "Backup email" - this way your outreach won't stop. You can always add more credits if you need too,
          but we will never charge you automatically to top up credits.
        </>
      ),
    },
  ];

  return (
    <ContentCard headerIcon={<SvgIcon type={"personalizedEmail"} />} cardTitle={"AI Personalized Email"}>
      {/* <CustomLabel title={"Additionally"} />
      <Form.Item name={"aiAdditionally"}>
        <Checkbox defaultChecked>Reply to incoming email</Checkbox>
      </Form.Item> */}
      <CustomLabel
        title={"AI Personalized Email settings"}
        subtitle={"AI personalized email will be customized for each prospect individually through Meetz. Below, you can fine-tune the advanced settings."}
      />
      <Button className={"link"} style={{ marginBottom: 22 }} onClick={() => setTestAiSettingsOpen(true)}>
        Test & Setup AI Email{" "}
        {!aiSettings?.current_value_prop?.trim()?.length || !aiSettings?.seller_company_name?.trim()?.length ? (
          <span style={{ color: "orange", marginLeft: "10px", fontWeight: 500 }}> - Ai Email won't send, set it up here</span>
        ) : (
          ``
        )}
      </Button>
      <CustomLabel
        title={
          <>
            Backup Email{" "}
            {body?.trim()?.length > 10 || (!firstLoad && backupEmail?.body?.length) ? (
              ``
            ) : (
              <span style={{ color: "orange", marginLeft: "10px" }}> - Empty, please add an email template</span>
            )}
          </>
        }
      />
      <Collapse items={itemsBackup} />
      FAQ:
      <br />
      <br />
      <Collapse items={items} />
      <AddVariableModal open={openModal} onCancel={onCancel} />
    </ContentCard>
  );
};
