import { Flex, message, notification, Row, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmButtons, Modal } from "src/components/ui";
import "./index.less";
import { userIsPayingSelector, userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { PlansModal } from "src/components/pages/billing/ui/PlansModal";
import { skipTrialNow } from "src/store/stripe/asyncRequests";

type ActivateForAccessModalProps = {
  open: boolean;
  onCancel: VoidFunction;
};

export const ActivateForAccessModal: FC<ActivateForAccessModalProps> = ({ open, onCancel }) => {
  const navigate = useNavigate();

  const [sentToSkipTrial, setSentToSkipTrial] = useState(false);
  const [showedSucessSkip, setShowedSucessSkip] = useState(false);
  const [showedFailedSkip, setShowedFailedSkip] = useState(false);

  const closeModal = () => {
    if (!sentToSkipTrial) {
      onCancel();
    }
  };

  const payingClient = useSelector(userIsPayingSelector);
  const { new_payment_status } = useSelector(userSelector);

  const dispatch = useDispatch();
  const handleSkipTrial = () => {
    setSentToSkipTrial(true);
    notification.open({
      message: "⚡ Activating account ⚡",
      duration: 6, // The notification will disappear after 6 seconds
      // style: {
      //   backgroundColor: "#ffffff", // Background color (white in this case)
      //   border: "1px solid #d9d9d9", // Border color to match the background
      //   boxShadow: "none", // Removing the box shadow
      // },
      closeIcon: false,
    });
    dispatch(skipTrialNow());
    // closeModal();
  };

  useEffect(() => {
    // console.log(sentToSkipTrial, !showedSucessSkip, payingClient, "payingClientpayingClient");
    if (sentToSkipTrial && !showedSucessSkip && !showedFailedSkip && payingClient) {
      onCancel();
      setShowedSucessSkip(true);
      message.destroy();
      message.success(`Unlocked account, let's get leads!`);
    }
  }, [payingClient]);

  const checkIfUpdaetdOtherwiseRefresh = () => {
    if (!showedSucessSkip && !setShowedFailedSkip) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (sentToSkipTrial) {
      setTimeout(() => {
        checkIfUpdaetdOtherwiseRefresh();
      }, 6000);
    }
  }, [sentToSkipTrial]);

  useEffect(() => {
    // console.log(sentToSkipTrial, !showedSucessSkip, payingClient, "payingClientpayingClient", new_payment_status);
    const errorInPayment = new_payment_status === "error_in_charging" || new_payment_status === "error_in_charging_temp_skip";
    if (errorInPayment && sentToSkipTrial && !showedSucessSkip && !showedFailedSkip) {
      onCancel();
      setShowedFailedSkip(true);
      message.destroy();
      message.error(`Error in your payment, please fix to unlock Meetz`);
    }
  }, [new_payment_status]);

  const [openPlans, setOpenPlans] = useState(false);

  return (
    <Modal customClassName={"activate-access-wrapper"} open={open} width={663} onCancel={closeModal} hasCloseIcon={false}>
      <Row className={"background-content-wrapper"}></Row>
      <Flex className={"info-wrapper"} vertical gap={16}>
        <Typography.Paragraph className={"info-title"}>
          {new_payment_status === "successfully_on" ? `Upgrade Your Account` : `Unlock Your Account for Full Access!`}
        </Typography.Paragraph>
        <Typography.Paragraph className={"info-subtitle"}>
          {new_payment_status === "successfully_on"
            ? `Upgrade to get more prospect data from our 250+ Million real-time verified contact database by activating your account.`
            : `Maximize your outreach with Meetz to generate leads and clients for your business with ease.`}
        </Typography.Paragraph>
        <ConfirmButtons
          leftButtonTitle={"Cancel"}
          rightButtonTitle={"Skip Trial and Unlock"} // TODO change this to a module to upgrade to the next one
          onCancel={closeModal}
          handleSubmit={() => !sentToSkipTrial && handleSkipTrial()}
        />
      </Flex>
      {/* {openPlans && (
        <PlansModal
          onClose={() => {
            setOpenPlans(false);
          }}
        />
      )} */}
      <div className="content-footnote">
        *When you skip trial you will be charged your plan's subscription and your account will get all the plans prospect data + Ai personalization credits.
        Get ready for leads!
      </div>
    </Modal>
  );
};
