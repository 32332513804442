import React, { FC, useState, useEffect } from "react";
import { Button, Col, Flex, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { ConfirmButtons, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { useBreakpoints } from "../../../../helpers/useBreakpoints";
import DashboardsImg from "src/shared/images/dashboards.webp";
import "./index.less";

import moment, { Moment } from "moment";
import { Timer } from "../../billing/ui/PlansModal";
import { logout } from "src/store/user";
import { useDispatch } from "react-redux";

// const CountdownTimer: FC<any> = ({ initialTime, onTimeUp }) => {
//   const [timeLeft, setTimeLeft] = useState(initialTime);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(prevTime => {
//         if (prevTime === 0) {
//           clearInterval(timer);
//           onTimeUp();
//           return 0;
//         }
//         return prevTime - 1;
//       });
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [initialTime, onTimeUp]);

//   const formatTime = (seconds: number): string => {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const remainingSeconds = seconds % 60;
//     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
//   };

//   return (
//     <Typography.Paragraph className={"mobile-timer"}>
//       {formatTime(timeLeft)}
//     </Typography.Paragraph>
//   );
// };

type UnlockAccessModalProps = {
  open: boolean;
  onCancel?: VoidFunction;
  handleSubmit: VoidFunction;
};

export const UnlockAccessModal: FC<UnlockAccessModalProps> = ({ open, onCancel, handleSubmit }) => {
  const { isDesktop } = useBreakpoints();

  const closeModal = () => {
    onCancel && onCancel();
  };

  const dispatch = useDispatch();

  const [midAfter, setMidAfter] = useState<Moment>();
  const [isPriceDis, setIsPriceDis] = useState(false);
  const [promoter, setPromoter] = useState();

  const now = moment();
  // console.log(1123123123);
  // console.log(midAfter && !now?.isAfter(midAfter), midAfter, !now?.isAfter(midAfter), "accCreatTime3", isPriceDis);

  return isDesktop ? (
    <Modal customClassName={"unlock-modal"} width={567} open={open} onCancel={onCancel && closeModal} closable={!!onCancel}>
      {/* <Modal customClassName={"unlock-modal"} width={567} hasCloseIcon={false} open={open} onCancel={closeModal}> */}
      <Flex vertical align={"center"}>
        {/* <Typography.Paragraph className={"unlock-modal-title"}>Activate Meetz</Typography.Paragraph>
        <Typography.Paragraph className={"unlock-modal-subtitle"}>The Ultimate Al Lead Generation Platform</Typography.Paragraph>
        <Typography.Paragraph className={"unlock-modal-description"}>
          Meetz Makes Lead Generation Super Easy And Effective With Al Personalization And Accurate Prospect Data. Watch how you'll be getting interested leads
          and your future clients with Meetz.
        </Typography.Paragraph> */}
        <div style={{ fontWeight: 500, color: "darkblue", fontSize: "20px", textAlign: "center" }}>
          {" "}
          Meetz Makes Lead Generation
          <div className="sweet-titleConfClsUnlock" style={{ margin: "6px 0 12px 0" }}>
            <span data-text="SUPER EASY">SUPER EASY</span>
          </div>{" "}
          And Effective With AI Personalization And Accurate Outreach Data.
        </div>
        <br />
        <br /> <span style={{ fontWeight: 600 }}> How you&apos;ll be getting leads with Meetz:</span>
        <div className={"player-box"}>
          <iframe
            src="https://player.vimeo.com/video/996987329?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            // frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{ borderRadius: "16px", border: "1px solid #fff", height: "100%", width: "100%", padding: "10px" }}
            title="Meetz 1 Minute Overview"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        {/* <div className={"player-box"}>
          <iframe
            title="intro"
            src="https://player.vimeo.com/video/991164535"
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ borderRadius: "16px", border: "1px solid #01aeef" }}
          />
          
        </div> */}
        {/* <Row justify={"space-between"} align={"middle"} wrap={false} style={{ width: "100%" }}> */}
        <Row justify={"center"} align={"middle"} wrap={false} style={{ width: "100%" }}>
          {/* <Button className={"link"} style={{ padding: "0 17px" }} onClick={onCancel}>
            Log Out
          </Button> bring back in case this will be a locked screen */}
          <Button className={"primary"} htmlType={"submit"} onClick={handleSubmit}>
            Click Here To Unlock Meetz Now
          </Button>{" "}
        </Row>
        <div className={"deal-package-wrapper"} style={{ display: isPriceDis || (midAfter && !now?.isAfter(midAfter)) ? "inline" : "none" }}>
          <Typography.Paragraph className={"deal-package-title"}>
            {isPriceDis
              ? `Exclusive ${promoter} Package • 20% Discount On Your Meetz Plan, For The First 12 Months when you activate!`
              : !now?.isAfter(midAfter)
              ? `New User Discount 🚀 20% Extra Credits On Your Meetz Plan, For The First 12 Months when you activate!`
              : ""}
            <div className={"package-countdown"} style={{ display: !now?.isAfter(midAfter) ? "inline" : "none" }}>
              <Timer
                midAfter={midAfter}
                setMidAfter={setMidAfter}
                isPriceDis={isPriceDis}
                setIsPriceDis={setIsPriceDis}
                promoter={promoter}
                setPromoter={setPromoter}
                // align="flex-end"
              />
            </div>
          </Typography.Paragraph>
          <Row className={"gradient1"}></Row>
          <Row className={"gradient2"}></Row>
        </div>{" "}
        <br />
        <Typography.Paragraph
          className={"profile-menu-title"}
          onClick={() => {
            dispatch(logout({}));
            window.location.href = "/login";
          }}
          style={{ cursor: "pointer", fontSize: "13px" }}
        >
          Logout
        </Typography.Paragraph>
      </Flex>
    </Modal>
  ) : (
    <Col className={"mobile-unlock-modal"}>
      <Image className={"mobile-banner"} preview={false} src={DashboardsImg} alt={"Dashboards"} />
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <div className={"deal-package-wrapper"} style={{ display: isPriceDis || (midAfter && !now?.isAfter(midAfter)) ? "inline" : "none" }}>
        <Typography.Paragraph className={"deal-package-title"}>
          {isPriceDis
            ? `Exclusive ${promoter} Package • 20% Discount On Your Meetz Plan, For The First 12 Months when you activate!`
            : !now?.isAfter(midAfter)
            ? `New User Discount 🚀 20% Extra Credits On Your Meetz Plan, For The First 12 Months when you activate when you activate!`
            : ""}
          <div className={"package-countdown"}>
            <Timer
              midAfter={midAfter}
              setMidAfter={setMidAfter}
              isPriceDis={isPriceDis}
              setIsPriceDis={setIsPriceDis}
              promoter={promoter}
              setPromoter={setPromoter}
              // align="flex-end"
            />
          </div>
        </Typography.Paragraph>
        <Row className={"gradient1"}></Row>
        <Row className={"gradient2"}></Row>
      </div>
      <br />
      <Flex className={"mobile-box"} vertical={true} align={"center"}>
        <div className={"deal-package-wrapper"} style={{ display: isPriceDis || (midAfter && !now?.isAfter(midAfter)) ? "inline" : "none" }}>
          <Typography.Paragraph className={"deal-package-title"}>
            {isPriceDis
              ? `Exclusive ${promoter} Package • 20% Discount On Your Meetz Plan, For The First 12 Months when you activate!`
              : !now?.isAfter(midAfter)
              ? `New User Discount 🚀 20% Extra Credits On Your Meetz Plan, For The First 12 Months when you activate!`
              : ""}
            <div className={"package-countdown"} style={{ display: !now?.isAfter(midAfter) ? "inline" : "none" }}>
              <Timer
                midAfter={midAfter}
                setMidAfter={setMidAfter}
                isPriceDis={isPriceDis}
                setIsPriceDis={setIsPriceDis}
                promoter={promoter}
                setPromoter={setPromoter}
                // mobile
                // align="flex-end"
              />
            </div>
          </Typography.Paragraph>
          <Row className={"gradient1"}></Row>
          <Row className={"gradient2"}></Row>
        </div>
        {/* <Typography.Title level={1} className={"mobile-main-title"}>
          Activate Meetz Ai
          <Typography.Paragraph className={"unlock-modal-subtitle"}>The Ultimate Al Lead Generation Platform</Typography.Paragraph> 
        </Typography.Title> 
        <Typography.Paragraph className={"mobile-description"}>
          Meetz Makes Lead Generation <Typography.Text className={"accent-text-color"}>Super Easy And Effective</Typography.Text> With Al Personalization And
          Accurate Prospect Data.{" "}
        </Typography.Paragraph>
        <br />
        <br />
        <Typography.Paragraph className={"mobile-description"} style={{ marginTop: "auto" }}>
          <b> Watch below</b> how you'll be getting interested leads and your future clients with Meetz.
        </Typography.Paragraph> */}
        <div style={{ fontWeight: 500, color: "darkblue", fontSize: "20px", textAlign: "center" }}>
          {" "}
          Meetz Makes Lead Generation
          <div className="sweet-titleConfClsUnlockMob" style={{ margin: "6px 0 12px 0" }}>
            <span data-text="SUPER EASY">SUPER EASY</span>
          </div>{" "}
          And Effective With AI Personalization And Accurate Outreach Data.
        </div>
        <br />
        <br /> <span style={{ fontWeight: 600 }}> How you&apos;ll be getting leads with Meetz:</span>
        <br />
        <div className={"player-box"}>
          <iframe
            src="https://player.vimeo.com/video/996987329?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            // frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{ borderRadius: "16px", border: "1px solid #fff", height: "100%", width: "100%", padding: "1px" }}
            title="Meetz 1 Minute Overview"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        {/* <Flex className={"video-player"} vertical={true} justify={"center"} align={"center"}>
          <SvgIcon type={"play2"} width={24} height={24} />
        </Flex> */}
        <ConfirmButtons className={"confirm-buttons"} rightButtonTitle={"Click Here To Unlock Meetz Now"} handleSubmit={handleSubmit} />
        <br />
        <br />
        <Typography.Paragraph
          className={"profile-menu-title"}
          onClick={() => {
            dispatch(logout({}));
            window.location.href = "/login";
          }}
          style={{ cursor: "pointer", fontSize: "13px" }}
        >
          Logout
        </Typography.Paragraph>
        {/* <div className={"link meetz-link"} /> */}
        {/* Meetz.ai
        </div> */}
      </Flex>
    </Col>
  );
};
