import { Checkbox, Flex, Form, GetProp, Input, Radio, Typography } from "antd"
import React, { FC, useEffect, useState } from "react"
import { ConfirmButtons, CustomSelect, Loader, Modal, TableHeader } from "src/components/ui"
import { addressesAvailable } from "../../../info"
import { useDispatch, useSelector } from "react-redux"
import { outreachPhonesSelector, updAdress } from "src/store/outreach-phones"
import { ISOCOUNTRIES } from "src/pages/constant"
import { checkAddress, getAddressInformation } from "src/store/outreach-phones/asyncRequests"

type CheckAddressModalProps = {
  open: boolean
  onCancel: VoidFunction
}

const countriesOptions = [
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "Ukraine",
    value: "Ukraine",
  },
  {
    label: "United Arabian Emirates",
    value: "United Arabian Emirates",
  },
]

export const CheckAddressModal: FC<CheckAddressModalProps> = ({ open, onCancel }) => {
  const [compeleteAddress, setCompeleteAddress] = useState({})
  const [addressId, setAddressId] = useState('');
  const [form] = Form.useForm()

  const {addressComplete,loadingcheckAddress,guessedAddresses}= useSelector(outreachPhonesSelector)

  const [countrySelected, setCountrySelected] = useState("")

  const handleCountryChange = (value: string) => {
    setCountrySelected(value)
  }


  useEffect(() => {
    if(addressComplete?.street!=='notloaded'){
      // console.log(addressComplete,'addressComplete2');
       const foundIsoCode = ISOCOUNTRIES.find((country) => country.label === (addressComplete?.iso_country||addressComplete?.isoCountry)?.trim())?.value;
      form.setFieldsValue({
        iso_country:foundIsoCode || (addressComplete?.iso_country||addressComplete?.isoCountry)?.trim(),
region:addressComplete?.region?.trim(),
city:addressComplete?.city?.trim(),
street:addressComplete?.street?.trim(),
postalCode:(addressComplete?.postal_code||addressComplete?.postalCode)?.trim(),
      })
    }
  // setCompeleteAddress(addressComplete)
  }, [addressComplete]);

  const closeModal = () => {
    // form.resetFields()
    onCancel()
  }
  const handleFormSubmit = () => {
    const values = form.getFieldsValue()
    // console.log(values,"valuestest")
    dispatch(updAdress(values))
    onCancel()
  }

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = checkedValues => {
    // console.log("checked = ", checkedValues)
  }
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const [formData, setFormData] = useState('');
  const debouncedFormData = useDebounce(formData, 1500); // Debounce delay of 500 milliseconds

  const dispatch = useDispatch()

    useEffect(() => {
    if (debouncedFormData && debouncedFormData?.length > 5) {
      dispatch(checkAddress({ text: debouncedFormData }));
    }
  }, [debouncedFormData, dispatch]);

  const handleSearchChange = (e) => {
    setFormData(e?.target?.value);
  };
// console.log(address?.place_id,'address?.place_id');

//  const handleClick = (num) => {
//     const foundIsoCode = ISOCOUNTRIES.find((country) => country.name === compeleteAddress.isoCountry)?.code;
//     // console.log(
//     //   { ...compeleteAddress, isoCountry: ISOCOUNTRIES.find((country) => country.name === compeleteAddress.isoCountry) },
//     //   'iso check'
//     // );
//     dispatch(
//       addFreeNum({
//         compeleteAddress: { ...compeleteAddress, isoCountry: foundIsoCode },
//         phoneCountry: num?.isoCountry,
//         phoneNum: num?.phoneNumber,
//       })
//     );
    
//   };

  return (
    <Modal width={530} open={open} title={"Business Address"} onCancel={closeModal} >
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item name={"address"} label={"Look Up Your Address"} style={{ marginBottom: 0 }} >
          <TableHeader searchPlaceholder={"New York"} hasMaxWidth={false} handleChange={handleSearchChange} />
        </Form.Item>
        {/* <div style={{height:'10px',alignItems:'flex-start',justifyContent:'flex-start',display:'flex'}}> */}
          {!formData?.length || formData?.length > 5
              ? loadingcheckAddress
                ? <div style={{height:'15px'}}><Loader style={{height:'100%'}} /></div>
                : <div style={{height:'15px'}}/>
              :<span style={{position: 'relative', top: '-10px',height:'15px',fontWeight:500}}>Keep typing for suggested addresses...</span>}
              {/* </div> */}
        {guessedAddresses ? (
          <Radio.Group className={"phones-available-radio-group"} style={{ width: "100%" }} value={addressId}>
            <Flex vertical gap={8} style={{ maxHeight: 104, overflowY: "scroll",width:'100%', marginBottom: 28 }}>
              {guessedAddresses.map((address, index) => (
                <Radio value={address?.place_id}    onClick={() => {
                        setAddressId(address?.place_id);
                        dispatch(getAddressInformation({ text: address?.place_id }));
                      }} key={address?.place_id}>
                  {address?.description}
                </Radio>
              ))}
            </Flex>
          </Radio.Group>
        ) : (
          <Typography.Paragraph className={"empty-state"}>
            No address found
          </Typography.Paragraph>
        )}
        <div style={{display: 'grid',
    gridTemplateColumns: 'auto auto'}}>
        <Form.Item name={"iso_country"} rules={[{required:true}]} style={{width:'200px'}} label={"Country"} >
          <CustomSelect options={ISOCOUNTRIES}  onChange={handleCountryChange} value={countrySelected} />
         
        </Form.Item>
        <Form.Item name={"region"} rules={[{required:true}]} style={{width:'200px'}} label={"State/Region"}>
          <Input placeholder={"New York"} />
        </Form.Item>
        <Form.Item name={"city"} rules={[{required:true}]} style={{width:'200px'}} label={"City"}>
          <Input placeholder={"New York City"} />
        </Form.Item>
       
        <Form.Item name={"postalCode"} rules={[{required:true}]} style={{width:'200px'}} label={"Postal Code"}>
          <Input placeholder={"10001"} />
        
        </Form.Item></div> <Form.Item name={"street"} rules={[{required:true}]} style={{width:'300px'}} label={"Street - Must include address number"}>
          <Input placeholder={"5th Avenue apartment 123"}  />
        </Form.Item>
        <ConfirmButtons rightButtonTitle={"Save and Continue"} leftButtonTitle={"Back"} onCancel={closeModal} />
      </Form>
    </Modal>
  )
}
