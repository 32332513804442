/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, Divider, Form, Row, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";
import TextArea from "antd/es/input/TextArea";
import { ApproveAiVariantsModal } from "../ApproveAiVariantsModal";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { SaveValueModal } from "../SaveValueModal";
import { SelectFromSavedModal } from "../SelectFromSavedModal";
import { getNewABTests } from "src/store/outreach-campaigns/asyncRequests";

type CreateVariantsModalProps = {
  open: boolean;
  newSeq?: boolean;
  onCancel: VoidFunction;
};

export const CreateVariantsModal: FC<CreateVariantsModalProps> = ({ open, onCancel, newSeq }) => {
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [form] = Form.useForm();

  // useEffect(() => {
  //   const formValues = form.getFieldsValue()
  //   setFormData(formValues)
  // }, [form])

  const { campaignDisplaying, aiSettings } = useSelector(outreachCampaignSelector);

  const [selectedValProp, setSelectedValProp] = useState("");
  useEffect(() => {
    if (selectedValProp?.length) {
      form.setFieldsValue({
        valueProp: selectedValProp || "",
      });
    }
  }, [selectedValProp]);

  useEffect(() => {
    // const valueProp = (campaignDisplaying?.campaign?.ai_campaign_sales_settings?.id ? campaignDisplaying?.campaign?.ai_campaign_sales_settings : aiSettings)?.current_value_prop || ''
    const updValue = newSeq ? aiSettings : campaignDisplaying?.campaign?.ai_campaign_sales_settings || aiSettings;

    setSavedValueProposition(aiSettings?.value_props || []); // this one is always up to date

    form.setFieldsValue({ valueProp: updValue.current_value_prop || "" });
  }, [newSeq, campaignDisplaying, aiSettings]);

  const closeModalSved = () => {
    setOpenSaveValueModal(false);
    setOpenSelectFromSavedModal(false);
    // onCancel()
    // form.resetFields()
  };
  const closeModal = () => {
    setOpenSaveValueModal(false);
    setOpenSelectFromSavedModal(false);
    onCancel();
    // form.resetFields()
  };

  const getTemplates = () => {
    const values = form.getFieldsValue();
    // console.log(values, "valuesvalues");
  };

  const [savedValueProposition, setSavedValueProposition] = useState([]);
  const handleApproveModalClose = () => {
    setOpenApproveModal(false);
  };

  const dispatch = useDispatch();

  const handleFormSubmit = () => {
    const values = form.getFieldsValue();
    // console.log(values, "valuesvalues");
    dispatch(getNewABTests({ ...values, campaignSettingId: campaignDisplaying?.id }));
    setOpenApproveModal(true);
    closeModal();
  };
  const [openSaveValueModal, setOpenSaveValueModal] = useState(false);
  const [openSelectFromSavedModal, setOpenSelectFromSavedModal] = useState(false);
  const showSaveValueModal = () => {
    setOpenSaveValueModal(true);
  };

  const showSelectFromSavedModal = () => {
    setOpenSelectFromSavedModal(true);
  };

  const onValueChange = (changedValues: any, allValues: any) => {
    setFormData(allValues);
  };

  return (
    <>
      <Modal width={530} open={open} title={"Regenerate A/B Emails"} onCancel={closeModal}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleFormSubmit}
          onValuesChange={(changedValues, allValues) => onValueChange(changedValues, allValues)}
        >
          <Row justify={"space-between"} align={"middle"} style={{ marginBottom: 6 }}>
            <Typography.Title level={4}>Value Proposition</Typography.Title>
            <Row align={"middle"}>
              <Button className={"link"} onClick={showSaveValueModal}>
                Save Value Prop
              </Button>
              <Divider type={"vertical"} />
              <Button className={"link"} onClick={showSelectFromSavedModal}>
                Select From Saved
              </Button>
            </Row>
          </Row>
          <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginBottom: 8 }}>
            Add The One Main Key Benefit Clients Would Get By Using Your Company And The Specific Feature That Delivers It.
          </Typography.Paragraph>
          <Form.Item name={"valueProp"} rules={[{ required: true, message: "Must have a value proposition to generate A/B emails" }]}>
            <TextArea placeholder={"Meetz easily optimizes outreach with automated A/B tests and using Ai personalized emails."} />
          </Form.Item>
          <Form.Item name={"specificInstructions"} label={"Any specific instructions to give to the AI?"}>
            <TextArea placeholder={"Ex: Mention that I tried calling them"} />
          </Form.Item>
          <Form.Item name={"emailBody"} label={"Email body to create email variations based off of (Optional)"}>
            <TextArea placeholder={"Keep It Brief"} />
          </Form.Item>
          {/* <Form.Item name={"additionally"} label={"Additionally"}  valuePropName="checked" >
            <Checkbox>Reply to incoming email</Checkbox>
          </Form.Item> */}
          <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Create"} onCancel={closeModal} />
        </Form>
        <SaveValueModal defaultVal={form.getFieldValue("valueProp")} open={openSaveValueModal} onCancel={closeModalSved} />
        <SelectFromSavedModal open={openSelectFromSavedModal} onCancel={closeModalSved} data={savedValueProposition} setSelectedValProp={setSelectedValProp} />
      </Modal>
      <ApproveAiVariantsModal open={openApproveModal} onCancel={handleApproveModalClose} formData={formData} setFormData={setFormData} />
    </>
  );
};
