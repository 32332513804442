/* eslint-disable react/jsx-fragments */
import { Button, Flex, Form, Input, Row, Space, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ProspectsTable } from "src/components/pages/campaigns/ui";
import { ContentCard, CustomLabel } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import "./index.less";
import { ExtractionFileModal } from "./ui/ExtractionFileModal";
import { UploadProspectsModal } from "./ui/UploadProspectsModal";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, resetUploadedInfo, setAiGenProp, warnRegenerateSequence } from "src/store/outreach-campaigns";
import { Loader } from "src/components/layout";
import { prospectDataSelector } from "src/store/prospect_data";
import { userSelector } from "src/store/user";
import { validateNoSpecialChars } from "../StepOne";
import TextArea from "antd/es/input/TextArea";
import { autoSave, createCampaignStepsWithAi } from "src/store/outreach-campaigns/asyncRequests";

const { Text } = Typography;

const UploadingLeads = ({ prospectAmountExtracting }) => {
  return (
    <Space direction={"vertical"}>
      <Row align={"middle"} style={{ gap: 8, margin: "10px" }}>
        <Loader size="large" style={{ marginRight: "25px" }} />
        <Typography.Paragraph className={"bulk-message-title"}>{`Uploading ${prospectAmountExtracting} Potential Future Clients`}</Typography.Paragraph>
        <SvgIcon type={"ultimate"} />
      </Row>
    </Space>
  );
};

const UploadingLeadsFulfill = ({ leadsLeftOut, leadsUploaded }) => {
  //  if you show how many were not uploaded so too many questions (even the column title is counted as not uploaded)
  return (
    <Space direction={"vertical"}>
      <Row align={"middle"} style={{ gap: 8, margin: "10px" }}>
        {leadsUploaded ? <SvgIcon type={"success"} /> : <SvgIcon type={"alarm"} />}
        <div>
          {!!leadsLeftOut && !leadsUploaded && (
            <Typography.Paragraph className={"bulk-message-title-warn"} style={{ color: "#ff772ed9" }}>
              {`${
                leadsLeftOut === 1 ? `1 Prospect Was` : `${leadsLeftOut} Prospects Were `
              } Not Uploaded, Deselect the "Filter" Checkboxes In The Upload Section If Necessary.`}
            </Typography.Paragraph>
          )}
          {!!leadsUploaded && (
            <Typography.Paragraph className={"bulk-message-title-warn"} style={{ color: "#4ec352d9" }}>
              {`${leadsUploaded === 1 ? `1 Prospect Was` : `${leadsUploaded} Prospects Were`} Successfully Uploaded!`}
            </Typography.Paragraph>
          )}
        </div>
      </Row>
    </Space>
  );
};

export const StepTwo: FC = ({ onNext, step, toggleHandleNextStep, setToggleHandleNextStep }) => {
  const [openExtractionFileModal, setOpenExtractionFileModal] = useState(false);
  const [openUploadProspectsModal, setOpenUploadProspectsModal] = useState(false);
  const [prospectAmountExtracting, setProspectAmountExtracting] = useState(0);
  const { loadingUploadPros, leadsLeftOut, aiSettings, leadsUploaded, leadsIdSelected, aiGenProps, draftOutreachSeqAi, autoSaveToggle } =
    useSelector(outreachCampaignSelector);

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  useEffect(() => {
    if (prospectAmountExtracting && loadingUploadPros) {
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <UploadingLeads prospectAmountExtracting={prospectAmountExtracting} />,
        className: "custom-message",
        duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
    }
  }, [prospectAmountExtracting, loadingUploadPros]);

  useEffect(() => {
    // console.log(leadsLeftOut, leadsUploaded, "leadsLeftOutleadsLeftOut");
    if (leadsLeftOut || leadsUploaded) {
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <UploadingLeadsFulfill leadsLeftOut={leadsLeftOut} leadsUploaded={leadsUploaded} />,
        className: "custom-message",
        duration: 7,
        // duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
      dispatch(resetUploadedInfo());
    }
    // }, []);
  }, [leadsLeftOut, leadsUploaded]);

  const showExtractionFileModal = () => {
    setOpenExtractionFileModal(true);
  };

  const closeExtractionFileModal = () => {
    setOpenExtractionFileModal(false);
  };

  const showUploadProspectsModal = () => {
    setOpenUploadProspectsModal(true);
  };

  const closeUploadProspectsModal = () => {
    setOpenUploadProspectsModal(false);
  };
  const { exportFiles } = useSelector(prospectDataSelector);

  const client = useSelector(userSelector);

  const [form] = Form.useForm();
  // unique_offer
  // unique_why_you

  useEffect(() => {
    if (toggleHandleNextStep && step === 1) {
      autoSaveInput();
      setTimeout(() => {
        setToggleHandleNextStep(false);
      }, 600);
      // if (!leadsIdSelected?.length) {
      //   message.warning("Add and select prospects to add to the campaign");
      //   form.validateFields();

      //   return;
      // }
      form.submit();
    }
  }, [toggleHandleNextStep]);

  const [loadedOnce, setLoadedOnce] = useState(false);

  useEffect(() => {
    if (aiSettings?.id && !loadedOnce) {
      form.setFieldsValue({
        unique_offer: aiSettings?.unique_offer || "",
        unique_why_you: aiSettings?.unique_why_you || "",
      });
      // keep below the updates
      setLoadedOnce(true);
    }
  }, [aiSettings]);

  const onFinishFailed = (errorInfo) => {
    // setCheckFailed(true)
    // console.log("Failed:", errorInfo);
    message.warning(`Fill out your unique offer`);
  };

  const onFinish = (vals) => {
    // const vals= form.getFieldsValue()
    // console.log(vals, "valsvals");
    // TODON if already gen pop up to regeenrate or just move them to the next page no problem.
    // after step 1 gen outreach
    if (!draftOutreachSeqAi?.length) {
      dispatch(createCampaignStepsWithAi({ leadsIdSelected, infoForCampaign: { ...(aiGenProps || {}), ...(vals || {}) } }));
    } else {
      dispatch(warnRegenerateSequence(true));
    }
    onNext();
    dispatch(setAiGenProp({ ...(aiGenProps || {}), ...(vals || {}) }));
  };

  const autoSaveInput = () => {
    if (loadedOnce && step === 1) {
      const vals = form.getFieldsValue();
      dispatch(autoSave({ ...(vals || {}), step: 2 }));
    }
  };

  useEffect(() => {
    autoSaveInput();
  }, [autoSaveToggle]);

  if (step !== 1) {
    return;
  }

  return (
    <Flex vertical gap={25}>
      {" "}
      {contextHolder}
      <ExtractionFileModal open={openExtractionFileModal} onCancel={closeExtractionFileModal} setProspectAmountExtracting={setProspectAmountExtracting} />
      <UploadProspectsModal onCancel={closeUploadProspectsModal} open={openUploadProspectsModal} setProspectAmountExtracting={setProspectAmountExtracting} />
      <Flex className={"card-container"} gap={30}>
        <ContentCard headerIcon={<SvgIcon type={"contactInfo"} />} cardTitle={"Add Prospects"}>
          <Flex className={"step-two-card"} align={"flex-start"} vertical gap={12}>
            <Flex vertical>
              <Text>Upload prospects</Text>
              <small>Add prospects from a file you have</small>
            </Flex>
            <Button onClick={showUploadProspectsModal} className={"link"} style={{ marginTop: "-5px" }}>
              Upload prospects file
            </Button>
            {/* <Text style={{ margin: "40px 0" }}>or</Text>
            <Text>Add prospects from your integrated CRM or data provider</Text>
            <Button
              onClick={() => exportFiles?.length && showExtractionFileModal()}
              className={"link"}
              style={{ marginTop: "-7px", pointerEvents: !exportFiles?.length ? "none" : "auto", color: !exportFiles?.length ? "orange" : "default" }}
            >
              k
            
            </Button> */}
          </Flex>
        </ContentCard>
        <ContentCard
          headerIcon={<SvgIcon type={"starShine"} />}
          cardTitle={
            <>
              Your <span style={{ color: "#FFBF00", fontWeight: 600 }}>Unique</span> Offer
            </>
          }
        >
          <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Flex className={"step-two-card"} vertical gap={10}>
              <Flex vertical gap={5}>
                <CustomLabel
                  title={
                    <>
                      Unique Offer{" "}
                      <Tooltip
                        placement={"topLeft"}
                        title={
                          <>
                            This field will improve the outreach campaigns messsaging and help guide Meetz automated A/B testing. This helps us create a unique
                            outreach flow that captures prospects interest.
                            <br />
                            <br />
                            It's different from the "Value Proposition" you filled out, this should clearly outline what makes your company different—see the
                            example below. <br />
                            <br />
                            <b>Format:</b> What's in it for them? <br />
                            <br />
                            <b>Example:</b> Our platform can be set up very quickly using Ai, so that the client can focus on their business instead of chasing
                            after leads or manually testing out what and reviewing marketing performance every day.
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                      </Tooltip>
                    </>
                  }
                  subtitle={"Describe why your prospects take interest in your service? Explain what makes it unique or simply how it helps their life."}
                />
                <Form.Item
                  name={"unique_offer"}
                  rules={[{ required: true, message: `Please briefly explain what your unique offer is` }, { validator: validateNoSpecialChars }]}
                >
                  <TextArea placeholder="Explain in depth, the more details the better." rows={2} />
                </Form.Item>
              </Flex>
              <Flex vertical gap={5}>
                <CustomLabel
                  title={
                    <>
                      Why your company? (Optional)
                      <Tooltip
                        placement={"bottomRight"}
                        title={
                          <>
                            What's unique about your company and how does it help your clients? (Easy onboarding/ white glove customer success/ experienced
                            team)
                            <br />
                            <br />
                            <b>Format:</b> What's your companies edge? <br />
                            <br />
                            <b>Example:</b> Our starting team came from a background in consulting and actually generating outbound systems for companies. So
                            with the help of Ai engineers and web crawlers they built Meetz to automate personalization and use best practices in reaching out
                            to clients.
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                      </Tooltip>
                    </>
                  }
                  subtitle={"Why do prospects choose your company over competitors? Don't discuss your features, rather how your client benefits."}
                />
                <Form.Item name={"unique_why_you"} rules={[{ validator: validateNoSpecialChars }]}>
                  <TextArea placeholder="Optional: Keep this 2-3 sentences max" rows={2} />{" "}
                </Form.Item>
              </Flex>
            </Flex>
          </Form>
        </ContentCard>
      </Flex>
      <ProspectsTable />
    </Flex>
  );
};
