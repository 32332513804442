import { Button, Flex, Form, Input, List, Row, Tooltip, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import { StatusTag } from "src/components/ui";
import { SignInWithButtons } from "src/components/ui/SignInWithButtons";
import { SvgIcon } from "src/shared/icons";
import { ConnectedEmailsModal, EmailSendingLimitsModal } from "./ui";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { deleteOptimisticEmail, outreachEmailsSelector } from "src/store/outreach-emails";
import { userSelector } from "src/store/user";
import { AppDispatch } from "src/store";
import { deleteEmailConn } from "src/store/outreach-emails/asyncRequests";
import { useLocation } from "react-router";
import { AreYouSureDeleteModal } from "./ui/AreYouSureDeleteModal";

export const EmailAddressesTab: FC<{ data: any; handleNameChange; firstName; emailCampDrawerOpen }> = ({
  data,
  handleNameChange,
  firstName,
  emailCampDrawerOpen,
}) => {
  const [openModal, setOpenModal] = useState(false);
  // const [openWarmUpModal, setOpenWarmUpModal] = useState(false)
  const [openConnectedEmailsModal, setOpenConnectedEmailsModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailsData, setEmailsData] = useState<any>();
  const [handleAreYouSureDelete, setHandleAreYouSureDelete] = useState<any>(null);
  const { smtp_custom_from_verified_arr } = useSelector(userSelector);
  // useEffect(() => {
  //   if(data) setEmailsData(data)
  // }, [data, !openModal])

  const showModal = (emailData: any) => {
    setSelectedEmail(emailData);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const location = useLocation();
  const setLatestPage = () => {
    const path = location.pathname;
    const pathSegment = path.substring(1);

    localStorage.setItem("latestMeetzPg", pathSegment);
  };

  const iFrame = window.self !== window.top;
  const onGoogleClick = () => {
    if (iFrame) {
      window.parent.postMessage({ type: "REDIRECT_REQUEST", url: `${import.meta.env.VITE_REACT_APP_SERVER_API}/aiSales/addEmailGoogle/dealai` }, "*");
    } else {
      setLatestPage();
      window.location.href = `${import.meta.env.VITE_REACT_APP_SERVER_API}/aiSales/addEmailGoogle`;
    }
  };

  const onMicrosoftClick = () => {
    if (iFrame) {
      window.parent.postMessage({ type: "REDIRECT_REQUEST", url: `${import.meta.env.VITE_REACT_APP_SERVER_API}/aiSales/addEmailmicrosoft/dealai` }, "*");
    } else {
      setLatestPage();
      window.location.href = `${import.meta.env.VITE_REACT_APP_SERVER_API}/aiSales/addEmailmicrosoft`;
    }
  };

  // smtp_custom_from_verified_arr;

  const showConnectedEmailsModal = () => {
    setOpenConnectedEmailsModal(true);
  };

  // const showWarmUpModal = (emailData: any) => {
  //   setSelectedEmail(emailData)
  //   setOpenWarmUpModal(true)
  // }

  // const closeWarmUpModal = () => {
  //   setOpenWarmUpModal(false)
  // }

  const onCancel = () => {
    setOpenModal(false);
    // setOpenWarmUpModal(false)
    setOpenConnectedEmailsModal(false);
    setSelectedEmail(null);
  };
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = (emailId: string) => {
    dispatch(deleteOptimisticEmail(emailId));
    dispatch(deleteEmailConn({ emailId }));
  };
  const delEmail = (emailId) => {
    handleDelete(handleAreYouSureDelete);
    setHandleAreYouSureDelete(false);
  };
  // const test = useSelector(outreachCampaignSelector);
  const { additionalEmails, loading } = useSelector(outreachEmailsSelector);
  // const [emailsFromOptionCopy, setEmailsFromOptionCopy] = useState<any[]>([]);
  useEffect(() => {
    // console.log(additionalEmails, "additionalEmails");
    setEmailsData(
      [...additionalEmails]?.sort((a, b) => {
        return a?.id - b?.id;
      })
    );
  }, [additionalEmails]);

  const [form] = Form.useForm();

  const [openSMTPSetUp, setOpenSMTPSetUp] = useState(false);

  const handleReconnect = (emailToRec) => {
    // console.log(emailToRec, "emailToRec");
    switch (emailToRec?.email_alias_calendar_type) {
      case "google":
        onGoogleClick();
        break;
      case "microsoft":
        onMicrosoftClick();
        break;
      case "smtp":
        setOpenSMTPSetUp(true);
        break;
    }
  };
  // console.log(emailsData, "emailsData");
  useEffect(() => {
    form.setFieldsValue({ first_name: firstName });
  }, [firstName]);

  return (
    <div className={"emails-addresses-wrapper"}>
      <Form form={form}>
        {emailCampDrawerOpen && (
          <>
            Account Settings (Not campaign specific):
            <br />
          </>
        )}
        <Flex vertical gap={8} style={{ marginTop: "20px" }}>
          <Typography.Paragraph className={"label"}>
            <span style={{ fontWeight: 600 }}>Your First Name</span>
          </Typography.Paragraph>
          <Form.Item name={"first_name"} rules={[{ required: true, message: "Please enter your first name" }]}>
            <Input placeholder={"Enter your name"} onChange={handleNameChange} />
          </Form.Item>
        </Flex>
      </Form>
      <Row className={"email-details-title-row"} justify={"space-between"} align={"middle"}>
        {/* TODOF not critical now, but list the emails that are not connecteed to prospects anymore make it easier for them */}
        <Typography.Paragraph className={"emails-addresses-title"}>Your Email Addresses To Send Emails From</Typography.Paragraph>
        <Button className={"link"} onClick={showConnectedEmailsModal}>
          Email Settings
        </Button>
      </Row>
      <div className={"emails-addresses-content"}>
        {emailsData?.length ? (
          <List
            dataSource={emailsData}
            renderItem={(item: any) => {
              const isCustomFromWithSMTP = !!item?.smtp?.from?.trim()?.length;
              let emailToShow = isCustomFromWithSMTP ? item?.smtp?.from : item.email_alias_emailofcal;
              let warnThisItemIsNotVerified = false;

              // console.log(smtp_custom_from_verified_arr, "smtp_custom_from_verified_arr");
              if (
                isCustomFromWithSMTP &&
                (!smtp_custom_from_verified_arr?.length ||
                  !smtp_custom_from_verified_arr?.some((em) => em?.trim()?.toLowerCase() === emailToShow?.trim()?.toLowerCase()))
              ) {
                warnThisItemIsNotVerified = true;
              }
              emailToShow =
                emailToShow?.length > 30 ? (
                  <Tooltip placement={"top"} title={emailToShow}>
                    {emailToShow?.slice(0, 30) + "..."}
                  </Tooltip>
                ) : (
                  emailToShow
                );
              return (
                <List.Item key={item.id}>
                  <Flex vertical gap={8}>
                    <Typography.Paragraph className={"email-title"}>
                      <Flex align="center">
                        {" "}
                        <div> {emailToShow} </div>
                        {/* <div style={{ margin: "auto" }}> */}{" "}
                        {!!warnThisItemIsNotVerified && (
                          <Tooltip
                            placement={"left"}
                            title={
                              <div>
                                Please verify your email address by checking your inbox (including other folders) at {emailToShow}. Look for an email from
                                Notifications@Meetz.Ai with the subject line "Verify this email | Meetz Ai".
                                <br />
                                <br /> Once you find the email, click on the verification link to complete the process and refresh the browser.
                                <br />
                                <br />
                                Troubleshooting:
                                <br />
                                1. If you already clicked on the link refresh the browser to see if it was updated properly. <br />
                                2. If you still don't see the email in {emailToShow} inbox, click on "Edit SMTP" and click on "Save and Continue" again to
                                resend the email again.
                              </div>
                            }
                          >
                            {" "}
                            <SvgIcon type={"warning"} style={{ marginLeft: 7, marginTop: "3px", width: "15px", height: "15px" }} />
                          </Tooltip>
                        )}
                        {/* </div> */}
                      </Flex>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      {/* {item.warmUpMode ? (
                      <Button className={"link"} style={{ padding: 0 }} onClick={() => showWarmUpModal(item)}>
                        Warm Up Mode
                      </Button>
                    )  */}
                      <Button className={"link"} style={{ padding: 0 }} onClick={() => showModal(item)}>
                        {item?.sends_per_day?.length === 1 ? `${item?.sends_per_day[0]} sends per day` : "Warm up mode 🔥"}
                      </Button>
                    </Typography.Paragraph>
                  </Flex>
                  <Row align={"middle"} wrap={false} style={{ gap: 8 }}>
                    {/* <StatusTag
                    onClick={() => handleReconnect(item)}
                    value={item.connected ? "Edit" : <u>Reconnect</u>}
                    color={  "error"}
                    style={{ cursor:   "pointer" }}
                  /> */}
                    {!!item?.smtp?.port && (
                      <Button className={"link"} style={{ padding: 0 }} onClick={() => setOpenSMTPSetUp(item)}>
                        Edit SMTP
                      </Button>
                    )}
                    <StatusTag
                      onClick={() => handleReconnect(item)}
                      value={item.connected ? "Connected" : <u>Reconnect</u>}
                      color={item.connected ? "success" : "error"}
                      style={{ cursor: item.connected ? "default" : "pointer" }}
                    />
                    <SvgIcon
                      type={"closeTag"}
                      style={{ cursor: "pointer" }}
                      onClick={() => (item.connected ? setHandleAreYouSureDelete(item.id) : handleDelete(item.id))}
                    />
                    {/* handleDelete(item.id) */}
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Typography.Paragraph className={"emails-addresses-empty"}>No email is currently connected. Add an email below.</Typography.Paragraph>
        )}
      </div>
      <SignInWithButtons
        title={`Connect ${emailsData?.length >= 1 ? `Another ` : ""}Email With`}
        onGoogleClick={onGoogleClick}
        onMicrosoftClick={onMicrosoftClick}
        smtp
        openSMTPSetUp={openSMTPSetUp}
        setOpenSMTPSetUp={setOpenSMTPSetUp}
      />
      {openModal && <EmailSendingLimitsModal onCancel={closeModal} emailData={selectedEmail} />}
      {!!handleAreYouSureDelete && <AreYouSureDeleteModal onCancel={() => setHandleAreYouSureDelete(false)} onSubmit={delEmail} />}
      {!openModal && openConnectedEmailsModal && <ConnectedEmailsModal open={true} onCancel={onCancel} data={data} showModal={showModal} />}
    </div>
  );
};
