import { DatePicker, Flex, Form, Input, InputNumber } from "antd";
import { FC, useContext } from "react";
import { generateRandomDigits } from "../../../../../helpers";
import { DealsContext } from "../../../../app/context/DealsContext";
import { ConfirmButtons, Modal } from "../../../../ui";
import "./index.less";

interface AddDealModalProps {
  onClose: () => void;
}

export const AddDealModal: FC<AddDealModalProps> = ({ onClose }) => {
  const { addDeal } = useContext(DealsContext);
  const [form] = Form.useForm();

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then((values) => {
        const newDeal = { ...values, price: Number(values.price), id: generateRandomDigits(10) };
        // console.log(newDeal)
        addDeal(newDeal);
        onClose();
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  return (
    <Modal open={true} title={"Add Deal"} onCancel={onClose} rootClassName={"add-deal-modal"}>
      <Form form={form} layout={"vertical"} requiredMark={false} className={"add-deal-form"}>
        <Flex vertical={true} gap={16}>
          <Form.Item
            name={"name"}
            label={"Title"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={"Enter Text"} />
          </Form.Item>
          <Form.Item
            name={"date"}
            label={"Date"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name={"price"}
            label={"Potential deal size"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber placeholder={"Enter Text"} controls={false} />
          </Form.Item>
          <Form.Item
            name={"description"}
            label={"Notes"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={"Enter Text"} />
          </Form.Item>
        </Flex>
      </Form>
      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Add"} className={"confirm-buttons"} handleSubmit={handleSubmitBtnClick} onCancel={onClose} />
    </Modal>
  );
};
