import { Flex, Form, Input, Radio, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfirmButtons, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { addNewCampaignV2 } from "src/store/outreach-campaigns/asyncRequests";

type CreateCampaignsModalProps = {
  open: boolean;
  onCancel: VoidFunction;
};

export const CreateFromSavedModal: FC<CreateCampaignsModalProps> = ({ open, onCancel }) => {
  const { allCampsList } = useSelector(outreachCampaignSelector);

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const closeModal = () => {
    onCancel();
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [campToCopId, setCampToCopId] = useState<string | undefined>();

  const handleRadioBtnChange = (e: any) => {
    setCampToCopId(e.target.value);
    form.setFieldsValue({ camptocopy: e?.target?.value }); // Ensure form updates with selected campaign
  };

  const filteredCampaigns = allCampsList?.filter((item) => item?.campName?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.slice(0, 5);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (vals) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    // console.log(vals, "VALSVALS");

    dispatch(addNewCampaignV2({ copyCampaign: { campName: vals?.name, campIdToCopy: vals?.camptocopy } }));
    closeModal();
  };

  return (
    <Modal
      open={open}
      title={"Select previous campaign to copy"}
      onCancel={closeModal}
      styles={{ header: { maxWidth: "400px" }, body: { minHeight: "380px" } }}
    >
      <Form form={form} layout={"vertical"} className={"form"} onFinish={onFinish}>
        <Flex vertical gap={15}>
          <Flex vertical gap={10}>
            <Typography.Paragraph>New campaign's name</Typography.Paragraph>
            <Form.Item
              name={"name"}
              // label={"New campaign's name"}
              rules={[{ required: true, message: "Please enter a campaign name" }]}
              style={{ width: "350px" }}
            >
              <Input placeholder={"Enter name"} />
            </Form.Item>
          </Flex>
          <Flex vertical gap={10}>
            <Typography.Paragraph>Search for campaign</Typography.Paragraph>
            <Input.Search
              className={"search-input"}
              placeholder={"Search previous campaigns name..."}
              allowClear
              prefix={<SvgIcon type={"search"} />}
              enterButton={false}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Form.Item name={"camptocopy"} rules={[{ required: true, message: "Select campaign to copy" }]} style={{ width: "350px" }}>
              <Radio.Group value={campToCopId} onChange={handleRadioBtnChange} style={{ padding: "10px" }}>
                <Flex gap={10} vertical>
                  {filteredCampaigns?.length ? (
                    filteredCampaigns.map((item, index) => (
                      <Radio key={`${index}-${item.id}`} value={item.id}>
                        {item.campName}
                      </Radio>
                    ))
                  ) : (
                    <div>No campaign found</div>
                  )}
                </Flex>
              </Radio.Group>
            </Form.Item>
          </Flex>
          <div style={{ position: "absolute", width: "85%", left: "auto", right: "auto", bottom: 20 }}>
            <ConfirmButtons
              handleSubmit={() => form.submit()} // Ensure form submission is triggered
              onCancel={closeModal}
              rightButtonTitle={"Create campaign"}
              leftButtonTitle={"Back"}
              disabled={!allCampsList?.length}
              disabledExplanation={`No campaigns found to copy`}
            />
          </div>
        </Flex>
      </Form>
    </Modal>
  );
};
