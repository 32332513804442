/* eslint-disable react/jsx-fragments */
import { Button, Flex, Form, Input, TimePicker, Tooltip, Typography, message, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";
import React, { FC, useContext, useEffect, useState } from "react";
import { Collapse, ConfirmButtons, Drawer, Loader, Modal, TextEditor } from "src/components/ui";
import { generateRandomDigits, saveToClipboard } from "../../../../../helpers";
import { SvgIcon } from "../../../../../shared/icons";
import { MeetingLinksContext } from "../../../../app/context/MeetingLinksContext";
import { CustomSelect } from "../../../../ui";
import { WeekTimePlannerWithTabs } from "../../../../ui/WeekTimePlannerWithTabs";
import { AddVariableModal } from "../../../campaigns/CreateCampaign/ui/AIGenerated/ui/StepThree/ui";
import "./index.less";
import dayjs from "dayjs";
import { fullUserSelector, settingsSelector, userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { isSummerTimeChecker } from "src/store/util";
import { SelectFilterItem } from "src/components/pages/prospect-data/ui/ProspectFilterCollapse/ui";
import { checkLinkAvailability, updateMeeting } from "src/store/reactive-links-settings/asyncRequests";
import { reactiveLinksSelector, resetCheckingcustomlinkavail } from "src/store/reactive-links-settings";

interface MeetingLinkDrawerProps {
  isEditAiMeeting: boolean;
  isNewMeeting?: boolean;
  onClose: () => void;
}
const reminderTimeOptions = [
  // {
  //   label: "None",
  //   value: "None",
  // },
  {
    label: "5 Min",
    value: 5,
  },
  {
    label: "10 Min",
    value: 10,
  },
  {
    label: "15 Min",
    value: 15,
  },
  {
    label: "30 Min",
    value: 30,
    // disabled: true,
  },
  {
    label: "1 Hour",
    value: 60,
  },
  {
    label: "2 Hours",
    value: 120,
  },
  {
    label: "3 Hours",
    value: 180,
  },
  {
    label: "8 Hours",
    value: 480,
  },
  {
    label: "12 Hours",
    value: 720,
  },
  {
    label: "1 Day",
    value: 1440,
  },
  {
    label: "1.5 Days",
    value: 2160,
  },
  {
    label: "2 Days",
    value: 2880,
  },
  {
    label: "7 Days",
    value: 10080,
  },
  // {
  //   label: "1 Day",
  //   value: "1 Day",
  // },
  // {
  //   label: "2 Days",
  //   value: "2 Days",
  // },
];
const meetingTimeOptions = [
  // {
  //   label: "None",
  //   value: "None",
  // },
  {
    label: "15 Min",
    value: 900000,
  },
  {
    label: "30 Min",
    value: 1800000,
  },
  {
    label: "1 Hour",
    value: 3600000,
  },
  {
    label: "2 Hours",
    value: 7200000,
  },
  {
    label: "3 Hours",
    value: 10800000,
  },
  {
    label: "8 Hours",
    value: 28800000,
  },
  // {
  //   label: "1 Day",
  //   value: "1 Day",
  // },
  // {
  //   label: "2 Days",
  //   value: "2 Days",
  // },
];

// const timezoneOptions = [
//   {
//     label: "American/New_York EST",
//     value: "American/New_York EST",
//   },
//   {
//     label: "Option 2",
//     value: "Option 2",
//   },
//   {
//     label: "Option 3",
//     value: "Option 3",
//   },
// ]

const maximumDaysToScheduleForwardOptions = [
  {
    label: "No Max Time",
    value: 0,
  },
  {
    label: "3 Days",
    value: "3 Days",
  },
  {
    label: "4 Days",
    value: "4 Days",
  },
  {
    label: "5 Days",
    value: "5 Days",
  },
  {
    label: "6 Days",
    value: "6 Days",
  },
  {
    label: "7 Days",
    value: "7 Days",
  },
  {
    label: "8 Days",
    value: "8 Days",
  },
  {
    label: "9 Days",
    value: "9 Days",
  },
  {
    label: "10 Days",
    value: "10 Days",
  },
  {
    label: "15 Days",
    value: "15 Days",
  },
  {
    label: "30 Days",
    value: "30 Days",
  },
  {
    label: "60 Days",
    value: "60 Days",
  },
  {
    label: "120 Days",
    value: "120 Days",
  },
  {
    label: "180 Days",
    value: "180 Days",
  },
  {
    label: "1 Year",
    value: "1 Year",
  },
];

const minimumTimeToBookInAdvanceOptions = [
  {
    label: "0 Min",
    value: 0,
  },
  {
    label: "5 Min",
    value: 300,
  },
  {
    label: "10 Min",
    value: 600,
  },
  {
    label: "15 Min",
    value: 900,
  },
  {
    label: "30 Min",
    value: 1800,
  },
  {
    label: "45 Min",
    value: 2700,
  },
  {
    label: "1 Hour",
    value: 3600,
  },
  {
    label: "2 Hours",
    value: 7200,
  },
  {
    label: "3 Hours",
    value: 10800,
  },
  {
    label: "4 Hours",
    value: 14400,
  },
  {
    label: "5 Hours",
    value: 18000,
  },
  {
    label: "6 Hours",
    value: 21600,
  },
  {
    label: "7 Hours",
    value: 25200,
  },
  {
    label: "8 Hours",
    value: 28800,
  },
  {
    label: "9 Hours",
    value: 32400,
  },
  {
    label: "10 Hours",
    value: 36000,
  },
  {
    label: "11 Hours",
    value: 39600,
  },
  {
    label: "12 Hours",
    value: 43200,
  },
  {
    label: "16 Hours",
    value: 57600,
  },

  {
    label: "20 Hours",
    value: 72000,
  },
  {
    label: "1 Day",
    value: 86400,
  },
  {
    label: "2 Days",
    value: 172800,
  },
  {
    label: "3 Days",
    value: 259200,
  },
  {
    label: "1 Week",
    value: 604800,
  },
  {
    label: "2 Weeks",
    value: 1209600,
  },
];

const bufferTimeBetweenMeetings = [
  {
    label: "0 Min",
    value: 0,
  },

  {
    label: "5 Min",
    value: 300000,
  },
  {
    label: "10 Min",
    value: 600000,
  },
  {
    label: "15 Min",
    value: 900000,
  },
  {
    label: "30 Min",
    value: 1800000,
  },
  {
    label: "45 Min",
    value: 2700000,
  },
  {
    label: "1 Hour",
    value: 3600000,
  },
  {
    label: "2 Hours",
    value: 7200000,
  },
  {
    label: "3 Hours",
    value: 10800000,
  },
];

const questionRequiredOptions = [
  {
    label: "Required",
    value: "Required",
  },
  {
    label: "Not Required",
    value: "Not Required",
  },
];

const questionTypeOptions = [
  {
    label: "Opened Question",
    value: "Opened Question",
  },
  {
    label: "Multiple Choice Question",
    value: "Multiple Choice Question",
  },
];

export const MeetingLinkDrawer: FC<MeetingLinkDrawerProps> = ({ isEditAiMeeting, isNewMeeting, onClose }) => {
  const [form] = Form.useForm();
  const { meetingLinks, selectedMeetingLinkId, addMeetingLink, editMeetingLink } = useContext(MeetingLinksContext);
  const handleCopyMeetingDetBtnClick = () => {
    if (meetingLinkData) {
      saveToClipboard(`{{meeting_details}}`);
      notification.info({
        message: "Copied",
        description: false,
        duration: 5,
        icon: <SvgIcon type={"infoFilled"} />,
        closeIcon: false,
        placement: "bottomLeft",
      });
    }
  };
  // const [meetingLinkData, setMeetingLinkData] = useState<any>(
  //   isNewMeeting
  //     ? {
  //         id: generateRandomDigits(10),
  //         name: "Intro Meeting",
  //         description: "Meeting powered by Meetz.Ai",
  //         tags: [],
  //         links: [
  //           {
  //             text: "",
  //             href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
  //           },
  //         ],
  //         reminders: {},
  //         meetingLength: "15 Min",
  //         timezone: "American/New_York EST",
  //         maximumDaysToScheduleForward: "15 Days",
  //         minimumTimeToBookInAdvance: "0 Min",
  //         bufferTimeBetweenMeetings: "0 Min",
  //         isOutlined: false,
  //       }
  //     : meetingLinks.find(el => el.id === selectedMeetingLinkId)
  // )

  const is_dst = isSummerTimeChecker();
  const { user, settings, timezones } = useSelector(fullUserSelector);
  const { checkingcustomlinkavail } = useSelector(reactiveLinksSelector);
  const displayValueGetter = (option: { name: any; abbr_summer: any; abbr_winter: any }) => {
    const res = is_dst ? `${option.name} ${option.abbr_summer}` : `${option.name} ${option.abbr_winter}`;

    return res;
  };
  const { timezone, timezone_abbr } = settings;
  const { first_name } = user;
  const dispatch = useDispatch<AppDispatch>();

  const [reminders, setReminders] = useState([]);
  const [reminderEditingInfo, setReminderEditingInfo] = useState<any>();
  const [reminderEditing, setReminderEditing] = useState<any>();
  // const handleOpenEditReminder = (e) => {
  //   handleOpenEditReminder2(e);
  // };
  // useEffect(() => {
  //   // console.log(
  //     // reminders,
  //     // "reminderEditing3"
  //     // first_name
  //   );
  // }, [reminders]);

  const handleOpenEditReminder = (e) => {
    // console.log(
    //   // reminders?.find((rm) => rm?.id === e),
    //   reminders,
    //   "reminderEditing1"
    //   // first_name
    // );
    // setReminderEditingInfo(reminders?.find((rm) => rm?.id === e));
    setReminderEditing(e);
  };
  const [timezoneSelected, settimezoneSelected] = useState<any>("");
  const [timezoneOptions, settimezoneOptions] = useState<any[]>([]);

  const handleChangeTimeZone = (e) => {
    settimezoneSelected(e.split(" ")[0]);
  };
  // useEffect(() => {
  //   if (reminderEditingInfo) {
  //     console.log(reminderEditingInfo, "reminderEditing2 ");
  //   }
  // }, [reminderEditingInfo]);

  useEffect(() => {
    if (timezone?.length) {
      form.setFieldsValue({ timezone });
      settimezoneSelected(timezone);
    }
  }, [timezone]);

  useEffect(() => {
    // console.log(timezones, "timezones");
    if (timezones?.length) {
      settimezoneOptions([...timezones.map((tmz) => ({ label: displayValueGetter(tmz), value: displayValueGetter(tmz) }))]);
    }
  }, [timezones]);

  const getReminderLabel = () => {
    setIsAddVariableModalOpened(true);
  };

  const openAddVariableModal = () => {
    setIsAddVariableModalOpened(true);
  };

  const closeAddVariableModal = () => {
    setIsAddVariableModalOpened(false);
  };
  //   const [controlActKeys, setControlActKeys] = useState();
  // useEffect(() => {console.log(controlActKeys, "controlActKeys");
  // }, [controlActKeys]);
  const [changeReminderMinutesBef, setChangeReminderMinutesBef] = useState();
  const [changeReminderOnlyOnConfirmation, setChangeReminderOnlyOnConfirmation] = useState(false);
  const [changeReminderType, setChangeReminderType] = useState("email");
  const [changeReminderSubject, setChangeReminderSubject] = useState("");
  const [changeReminderBody, setChangeReminderBody] = useState("");
  const [reminderTimesNotAvail, setReminderTimesNotAvail] = useState([]);

  useEffect(() => {
    const reminderWorkingOn = reminders?.find((rm) => rm?.id === reminderEditing)?.value || {};
    const reminderNotWorkingOnMeetings = (reminders?.filter((rm) => rm?.id !== reminderEditing) || [])?.map((rm) => rm?.value?.minutes_before_meeting);
    // console.log(reminderNotWorkingOnMeetings, "reminderNotWorkingOnMeetings");
    setReminderTimesNotAvail(reminderNotWorkingOnMeetings);
    setChangeReminderMinutesBef(reminderWorkingOn?.minutes_before_meeting);
    setChangeReminderOnlyOnConfirmation(reminderWorkingOn?.only_if_didnt_confirm_meeting_send);
    setChangeReminderType(reminderWorkingOn?.type);
    setChangeReminderSubject(reminderWorkingOn?.email_subject);
    setChangeReminderBody(reminderWorkingOn?.email_body);

    // console.log(reminderWorkingOn, "reminderWorkingOn");
  }, [reminderEditing]);

  // useEffect(() => {
  //   console.log(changeReminderBody, "changeReminderBody");
  // }, [changeReminderBody]);
  const handleUpdateReminderStep = () => {
    // console.log(changeReminderMinutesBef, "changeReminderMinutesBef");

    const remindersUpd = reminders?.map((rm, i) => {
      if (rm?.id === reminderEditing) {
        return {
          ...rm,
          label: `Reminder ${reminderTimeOptions?.find((opt) => opt?.value === changeReminderMinutesBef)?.label} before the meeting - ${
            changeReminderType === "email" ? `Email Reminder` : `Calendar Ping`
          }`,
          // children: reminderCollapseItemMarkup(met),
          children: reminderCollapseItemMarkup(
            {
              only_if_didnt_confirm_meeting_send: changeReminderOnlyOnConfirmation,
              type: changeReminderType,
              minutes_before_meeting: changeReminderMinutesBef,
              email_body: changeReminderBody,
              email_subject: changeReminderSubject,
              id: rm.id,
            },
            i + 1
          ),
          value: {
            minutes_before_meeting: changeReminderMinutesBef,
            only_if_didnt_confirm_meeting_send: changeReminderOnlyOnConfirmation,
            type: changeReminderType,
            email_body: changeReminderBody,
            email_subject: changeReminderSubject,
          }, // Store the met object itself for future use if necessary
          time: changeReminderMinutesBef, // Add a time field to track the time
          text: "",
        };
      } else {
        return { ...rm };
      }
    });

    const reminderFix = remindersUpd?.sort((a, b) => b?.time - a?.time);
    // ?.map((met, i) => {
    //   // const id = [...Array(10)].map(() => Math.random().toString(36)[2]).join("");
    //   return {
    //     key: i + 1,
    //     id: met?.id,
    //     label: `Reminder ${reminderTimeOptions?.find((opt) => opt?.value === met?.minutes_before_meeting)?.label} before the meeting - ${
    //       met?.type === "email" ? `Email Reminder` : `Calendar Ping`
    //     }`,
    //     // children: reminderCollapseItemMarkup(met),
    //     children: reminderCollapseItemMarkup({ ...met, id }, i + 1),
    //     value: met, // Store the met object itself for future use if necessary
    //     time: met?.minutes_before_meeting, // Add a time field to track the time
    //     text: "",
    //   };
    // });
    setReminders(reminderFix);

    setReminderEditing(null);
  };

  const [remindersInfo, setRemindersInfo] = useState([]);

  // useEffect(() => {
  //   const reminderFix = remindersInfo
  //     ?.sort((a, b) => b?.minutes_before_meeting - a?.minutes_before_meeting)
  //     ?.map((met, i) => {
  //       return {
  //         key: i + 1,
  //         id: i + 1,
  //         // id: [...Array(10)].map(() => Math.random().toString(36)[2]).join(""),
  //         label: `Reminder ${reminderTimeOptions?.find((opt) => opt?.value === met?.minutes_before_meeting)?.label} before the meeting - ${
  //           met?.type === "email" ? `Email Reminder` : `Calendar Ping`
  //         }`,
  //         // children: reminderCollapseItemMarkup(met),
  //         children: reminderCollapseItemMarkup(met, i + 1),
  //         value: met, // Store the met object itself for future use if necessary
  //         time: met?.minutes_before_meeting, // Add a time field to track the time
  //         text: "",
  //       };
  //     });

  //   console.log(reminderFix, "minutes_before_meeting2");
  //   setReminders(reminderFix || []);
  // }, [remindersInfo]);

  // const handleReminderChange = (id, field, value) => {
  //   setRemindersInfo((prevReminders) => prevReminders.map((reminder) => (reminder.id === id ? { ...reminder, [field]: value } : reminder)));

  //   // setRemindersInfo(reminderFix || []);
  // };

  const reminderCollapseItemMarkup = (met, index) => {
    return (
      <Flex vertical gap={11} key={met.id}>
        {/* CustomSelect for minutes_before_meeting */}
        {/* <CustomSelect
          options={reminderTimeOptions}
          isSearchSelect={false}
          value={met?.minutes_before_meeting}
          // onChange={(value) => handleReminderChange(met.id, "minutes_before_meeting", value)}
        /> */}

        {/* TextArea for email_subject */}
        {/* <Flex vertical gap={8}>
          <Typography.Paragraph className={"label"}>Email Subject</Typography.Paragraph>
          <TextArea
            placeholder={"Enter the text of reminder"}
            value={met?.email_subject || ""}
            // onChange={(e) => handleReminderChange(met.id, "email_subject", e.target.value)}
          />
        </Flex> */}

        {/* TextArea for email_body */}
        {/* <Flex vertical gap={8}>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"label"}>Email Body</Typography.Paragraph> */}
        {/* <Typography.Paragraph className={"variable"} onClick={handleCopyMeetingDetBtnClick}>
          Copy meeting details variable
        </Typography.Paragraph> */}
        {/* </Flex>
          <TextArea
            placeholder={"Enter the text of reminder"}
            value={met?.email_body || ""}
            // onChange={(e) => handleReminderChange(met.id, "email_body", e.target.value)}
          />
        </Flex> */}

        <Flex vertical gap={8}>
          <Button className="link" onClick={() => handleOpenEditReminder(met?.id)}>
            Edit reminder
          </Button>
          <div>
            {`${reminderTimeOptions?.find((opt) => opt?.value === met?.minutes_before_meeting)?.label} before the meeting          
          `}{" "}
            {met?.type === "email" ? (
              `the below email reminder will be sent.`
            ) : (
              <>
                a native reminder will come from Google/Outlook calendar.
                <Tooltip
                  placement={"bottom"}
                  title={
                    <>
                      This reminder is a gentle reminder that comes from Google or Outlook calendar depending on your calendar. It is allows for your prospects
                      to be reminded easily and add it to their calendar in case it wasn't added yet.
                      <br />
                      <br />
                      This can help improve meeting attendance rates significantly.
                    </>
                  }
                >
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                </Tooltip>
              </>
            )}
            <br />
            <br />
            {met?.only_if_didnt_confirm_meeting_send ? (
              <>
                {" "}
                This will <b>only</b> be sent if the main meeting guest did not approve the meeting.{" "}
              </>
            ) : (
              ""
            )}
          </div>
          {met?.type === "email" ? (
            <>
              {" "}
              <Typography.Paragraph className={"label"} style={{ marginTop: "8px" }}>
                <b>Email Subject</b>
              </Typography.Paragraph>
              <TextArea rows={1} value={met?.email_subject} style={{ backgroundColor: "#f7f7f7", marginBottom: "5px" }} />
              <Typography.Paragraph className={"label"}>
                <b>Email Body</b>
              </Typography.Paragraph>
              {/* <TextArea rows={6} value={met?.email_body} disabled /> */}
              <TextEditor
                content={met?.email_body?.replace(
                  /{{meeting_details}}/g,
                  `<b>Meeting details</b> <br />Meeting title: Example<br />When: August 16th @ 5 PM Est.<br />Meeting guests: john@gmail.com, and mark@gmail.com <br /><u>Meeting Platform Link</u><br />`
                )}
                readOnly
              />
            </>
          ) : (
            ""
          )}
        </Flex>
      </Flex>
    );
  };

  // const reminderCollapseItemMarkup = (met, index) => {
  //   console.log(met?.minutes_before_meeting, "minutes_before_meeting123");
  //   return (
  //     <Flex vertical gap={11} key={generateRandomDigits(10)}>
  //       {/* <Form.Item name={["reminders", index, "time"]} initialValue={met?.minutes_before_meeting}> */}
  //       <CustomSelect options={reminderTimeOptions} isSearchSelect={false} value={met?.minutes_before_meeting} />
  //       {/* </Form.Item> */}
  //       <Flex vertical gap={8}>
  //         {" "}
  //         {/* <Flex justify={"space-between"} align={"center"}> */}
  //         <Typography.Paragraph className={"label"}>Email Subject</Typography.Paragraph>{" "}
  //         {/* <Form.Item name={["reminders", index, "emailsubject"]} initialValue={met?.email_subject || ""}> */}
  //         <TextArea placeholder={"Enter the text of reminder"} value={met?.email_subject || ""} />
  //         {/* </Form.Item> */}
  //         {/* </Flex> */}
  //       </Flex>
  //       <Flex vertical gap={8}>
  //         <Flex justify={"space-between"} align={"center"}>
  //           <Typography.Paragraph className={"label"}>Email Body</Typography.Paragraph>
  //           <Typography.Paragraph className={"variable"} onClick={handleCopyMeetingDetBtnClick}>
  //             Copy meeting details variable
  //           </Typography.Paragraph>
  //         </Flex>
  //         {/* <Form.Item name={["reminders", index, "emailbody"]} initialValue={met?.email_body || ""}> */}
  //         <TextArea placeholder={"Enter the text of reminder"} value={met?.email_body || ""} />
  //         {/* </Form.Item> */}
  //       </Flex>
  //       <Flex vertical gap={8}>
  //         <Typography.Paragraph className={"label"}>Example</Typography.Paragraph>
  //         <TextArea value={"Hi Iron, our meeting is coming up in 2 hours here is the meeting link: App.Meetz.Ai/Cal/Tom-reactivelink-2282."} disabled />
  //       </Flex>
  //     </Flex>
  //   );
  // };

  const [stupidFormHelper, setstupidFormHelper] = useState(["1", "2", "3", "4", "5", "6", "7"]);

  useEffect(() => {
    setstupidFormHelper(["1", "2", "3", "4", "5", "6", "7"]);

    setTimeout(() => {
      setstupidFormHelper(undefined);
    }, 500);
  }, [reminders]);

  // const [reminders, setReminders] = useState<any>([])

  const [meetingLinkData, setMeetingLinkData] = useState<any>(() => {
    const meting = meetingLinks.find((el) => el.id === selectedMeetingLinkId);

    if (meting) {
      const reminder_of_meeting_coming_up = [...(meting?.reminder_of_meeting_coming_up || [])];
      // console.log(meting, "meting.minimumTimeToBookInAdvance");

      const reminders = [];

      const reminderFix = reminder_of_meeting_coming_up
        ?.sort((a, b) => b?.minutes_before_meeting - a?.minutes_before_meeting)
        ?.map((met, i) => {
          const id = [...Array(10)].map(() => Math.random().toString(36)[2]).join("");
          return {
            key: i + 1,
            id,
            label: `Reminder ${reminderTimeOptions?.find((opt) => opt?.value === met?.minutes_before_meeting)?.label} before the meeting - ${
              met?.type === "email" ? `Email Reminder` : `Calendar Ping`
            }`,
            // children: reminderCollapseItemMarkup(met),
            children: reminderCollapseItemMarkup({ ...met, id }, i + 1),
            value: met, // Store the met object itself for future use if necessary
            time: met?.minutes_before_meeting, // Add a time field to track the time
            text: "",
          };
        });

      // console.log(reminderFix, "minutes_before_meeting2");
      // console.log(reminderFix, "reminderEditing2");
      setReminders(reminderFix || []);
      // setRemindersInfo(reminder_of_meeting_coming_up?.map((rm)=>{id: [...Array(10)].map(() => Math.random().toString(36)[2]).join(""),...rm}) || []);
      //       {
      //   key: "1",
      //   label: "Reminder 1",
      //   children: reminderCollapseItemMarkup(1),
      // },
      return {
        first_name: first_name || "",
        links: meting?.links || "",
        name: meting?.name?.value || meting?.name || "",
        description: meting?.description || "",
        meetingLength: meting.meetingLength || 0,
        // schedule:meting.schedule,
        schedule: Object.fromEntries(
          Object.entries(meting?.schedule).map(([key, value]) => [
            key,
            value.map((timerange) => [dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")]),
          ])
        ),
        prospectSchedule: [dayjs(meting.prospectSchedule?.start, "H:mm"), dayjs(meting.prospectSchedule?.end, "H:mm")],
        amountOfFollowUps: meting.amountOfFollowUps || 0,
        timezone,
        followDelays: meting.followDelays || 0,
        maximumTimeslotsToOfferPerDay: meting.maxTimeslotsPerDay,
        maximumDaysToScheduleForward: meting.maximumDaysToScheduleForward || 0,
        minimumTimeToBookInAdvance: meting.minimumTimeToBookInAdvance || 0,
        bufferTimeBetweenMeetings: meting.bufferTimeBetweenMeetings || 0,
        questionsForGuests: meting.questions || [],
      };
    } else {
      return {};
    }
  });

  const handleUpdateSched = (newSched) => {
    const deepCopiedData = _.cloneDeep(meetingLinkData);

    deepCopiedData.schedule = newSched;
    setMeetingLinkData(deepCopiedData);
  };

  //  useEffect(() => { BUG tried making this update in real time but doesnt work might be too complex, might need to optimistically update it in the redux object
  // const meting=meetingLinks.find(el => el.id === selectedMeetingLinkId)
  // if(meting){
  //   const newSched = {schedule:Object.fromEntries(Object.entries(meting?.schedule).map(([key, value]) => [key, value.map(timerange => ([  dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")] ))]))}
  //   form.setFieldsValue(newSched)

  //     const deepCopiedData = _.cloneDeep(meetingLinkData);

  //   deepCopiedData.schedule = newSched.schedule
  // setMeetingLinkData(deepCopiedData)
  // }
  //  }, [meetingLinks]);
  // useEffect(() => {
  //   console.log(meetingLinkData?.schedule, "testings123 data updated on main screen");
  // }, [meetingLinkData]);

  //  useEffect(() => {

  // const meting=meetingLinks.find(el => el.id === selectedMeetingLinkId)
  // if(meting){

  // setMeetingLinkData({
  //   links:meting?.links,
  //   name:meting?.name?.value||meting?.name ,
  //   description:meting?.description,
  //   meetingLength:meting.meetingLength,
  //   schedule:meting.schedule,
  //   prosSchedule:meting.prospectSchedule,
  //   amountOfFollowUps:meting.amountOfFollowUps,
  //   followDelays:meting.followDelays,
  //   maximumTimeslotsToOfferPerDay:meting.maxTimeslotsPerDay,
  //   maximumDaysToScheduleForward:meting.maximumDaysToScheduleForward,
  //   minimumTimeToBookInAdvance:meting.minimumTimeToBookInAdvance,
  //   bufferTimeBetweenMeetings:meting.bufferTimeBetweenMeetings,
  //   questionsForGuests:meting.questions || []
  // })}
  //  }, [meetingLinks]);

  const [questionsForGuests, setQuestionsForGuests] = useState(
    meetingLinkData.questionsForGuests || [
      {
        id: generateRandomDigits(10),
        text: "",
        type: "Opened Question",
        choices: [
          {
            id: generateRandomDigits(10),
            text: "",
          },
        ],
        isRequired: false,
      },
    ]
  );
  const [isAddVariableModalOpened, setIsAddVariableModalOpened] = useState(false);

  useEffect(() => {
    if (meetingLinks && !isNewMeeting) {
      const selectedMeetingLink = meetingLinks.find((el) => el.id === selectedMeetingLinkId);
      if (selectedMeetingLink) {
        setMeetingLinkData(selectedMeetingLink);
      }
    }
  }, [meetingLinks, selectedMeetingLinkId]);

  // const defaultReminders = [
  //   {
  //     key: "1",
  //     label: "Reminder 1",
  //     children: reminderCollapseItemMarkup(1),
  //   },
  //   {
  //     key: "2",
  //     label: "Reminder 2",
  //     children: reminderCollapseItemMarkup(2),
  //   },
  // ];

  // const [reminders, setReminders] = useState(defaultReminders);
  // const [remindersActiveKeys, setRemindersActiveKeys] = useState<string[]>(defaultReminders.map((item) => item.key));

  const handleFormSubmit = () => {
    // console.log(reminders, "remindersabouttogoabup");
    form
      .validateFields()
      .then((values) => {
        message.destroy();
        message.info("Updating meeting settings");
        dispatch(
          updateMeeting({
            ...values,
            timezone: timezoneSelected,
            id: meetingLinkData?.id,
            prospectSchedule:
              values?.prospectSchedule?.length > 1
                ? { start: values?.prospectSchedule[0]?.format("H:mm"), end: values?.prospectSchedule[1]?.format("H:mm") }
                : {},
            schedule: meetingLinkData?.schedule,
            isEditAiMeeting,
            meetingReminders: reminders?.map((rm) => ({ ...rm?.value })),
          })
        );

        // if (!isNewMeeting) {
        //   editMeetingLink({
        //     ...meetingLinkData,
        //     ...values,
        //     questionsForGuests,
        //     tags: meetingLinkData.tags.map((tag: any) =>
        //       tag.type === "Meeting Length"
        //         ? { text: values.meetingLength, color: "Green", type: "Meeting Length" }
        //         : tag
        //     ),
        //   })
        // } else {
        //   addMeetingLink({
        //     ...meetingLinkData,
        //     ...values,
        //     questionsForGuests,
        //     tags: [{ text: values.meetingLength, color: "Green", type: "Meeting Length" }],
        //     links: [{ text: "Copy link", href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}` }],
        //   })
        // }

        onClose();
      })
      .catch((errorInfo) => {
        message.destroy();
        message.error(`Fill out the missing fields.`);
        console.error("Validation failed:", errorInfo);
      });
  };

  const handleResetBtnClick = () => {
    form.resetFields();
  };

  const handleAddVariableSubmit = (values: any) => {
  console.log(values);
  };

  const checkLinkAvail = () => {
    dispatch(checkLinkAvailability({ custom_url: form.getFieldValue("link") }));
    // if (meetingLinkData) {
    //   saveToClipboard(`https://app.meetz.ai/cal/${form.getFieldValue("link")}`);
    //   notification.info({
    //     message: "Copied",
    //     description: false,
    //     duration: 5,
    //     icon: <SvgIcon type={"infoFilled"} />,
    //     closeIcon: false,
    //     placement: "bottomLeft",
    //   });
    // }
  };
  const handleCopyMeetingLinkBtnClick = () => {
    if (meetingLinkData) {
      saveToClipboard(`https://app.meetz.ai/cal/${form.getFieldValue("link")}`);
      notification.info({
        message: "Copied",
        description: false,
        duration: 5,
        icon: <SvgIcon type={"infoFilled"} />,
        closeIcon: false,
        placement: "bottomLeft",
      });
    }
  };

  const handleAddReminder = () => {
    // const newReminderIndex = reminders.length + 1;
    // const newReminder = {
    //   key: newReminderIndex.toString(),
    //   label: `Reminder ${newReminderIndex}`,
    //   children: reminderCollapseItemMarkup(newReminderIndex),
    // };
    // setReminders((prevReminders) => [...prevReminders, newReminder]);
    // setRemindersActiveKeys((prevActiveKeys) => [...prevActiveKeys, newReminder.key]);
  };

  const handleQuestionInputChange = (questionId: string, e: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? {
            ...question,
            text: e.target.value,
          }
        : question
    );
    setQuestionsForGuests(updatedQuestions);
  };

  const handleAddQuestionBtnClick = () => {
    setQuestionsForGuests((prevState: any) => [
      ...prevState,
      {
        id: generateRandomDigits(10),
        text: "",
        type: "Opened Question",
        choices: [{ id: generateRandomDigits(10), text: "" }],
        isRequired: false,
      },
    ]);
  };

  const handleIsRequiredQuestionChange = (questionId: string, value: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, isRequired: value === "Required" } : question
    );
    setQuestionsForGuests(updatedQuestions);
  };

  const handleQuestionTypeChange = (questionId: string, value: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) => (question.id === questionId ? { ...question, type: value } : question));
    setQuestionsForGuests(updatedQuestions);
  };

  const handleAddChoiceBtnClick = (questionId: string) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, choices: [...question.choices, { id: generateRandomDigits(10), text: "" }] } : question
    );
    setQuestionsForGuests(updatedQuestions);
  };

  const handleDeleteChoiceBtnClick = (questionId: string, choiceId: string) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, choices: question.choices.filter((choice: any) => choice.id !== choiceId) } : question
    );
    setQuestionsForGuests(updatedQuestions);
  };

  const handleChoiceInputChange = (questionId: string, choiceId: string, e: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? {
            ...question,
            choices: question.choices.map((choice: any) => (choice.id === choiceId ? { ...choice, text: e.target.value } : choice)),
          }
        : question
    );
    setQuestionsForGuests(updatedQuestions);
  };

  //   const updateSchedule = (schedule )=>{
  // console.log(schedule,'schedule');
  // const newSchedule = []

  // setMeetingLinkData({...meetingLinkData,newSchedule})
  //   }

  // useEffect(() => {

  // console.log(timezoneSelected,timezone,'timezoneSelectedtimezoneSelected');
  // }, [timezoneSelected,timezone]);

  const [hasErrors, setHasErrors] = useState(false);
  const handleValuesChange = (value) => {
    let hasErrorsNow = false;
    form
      .validateFields()
      .catch((errorInfo) => {
        if (errorInfo?.errorFields?.length) {
          setHasErrors(true);
          hasErrorsNow = true;
        }
      })
      .finally(() => {
        if (!hasErrorsNow) {
          setHasErrors(false);
        }
      });

    // setUserData
  };
  // console.log(checkingcustomlinkavail, "checkingcustomlinkavail");

  useEffect(() => {
    if (checkingcustomlinkavail && checkingcustomlinkavail !== "checking") {
      setTimeout(() => {
        dispatch(resetCheckingcustomlinkavail());
      }, 2500);
    }
  }, [checkingcustomlinkavail]);

  return (
    <>
      <Drawer
        open
        title={isNewMeeting ? "Add Meeting Link" : isEditAiMeeting ? "Default Ai Booked Meeting" : "Edit Meeting Link"}
        leftButtonTitle={"Close"}
        rightButtonTitle={"Save"}
        rightButtonDis={hasErrors}
        onBtnPrimaryClick={handleFormSubmit}
        onBtnSecondaryClick={handleResetBtnClick}
        onCancel={onClose} // TODOF add are you sure you want to discard changes if form is dirty
        rootClassName={"MeetingLinkDrawer"}
        // width={'550px'}
      >
        <Form
          form={form}
          layout={"vertical"}
          className={"edit-meeting-link-form"}
          //  value={meetingLinkData}
          initialValues={meetingLinkData}
          onValuesChange={handleValuesChange}
        >
          <Flex vertical gap={24}>
            <Flex vertical gap={8} style={{ marginTop: "20px" }}>
              <Typography.Paragraph className={"label"}>Your First Name</Typography.Paragraph>
              <Form.Item name={"first_name"} rules={[{ required: true, message: "Please enter your first name" }]}>
                <Input placeholder={"Enter your name"} />
              </Form.Item>
            </Flex>
            {!isEditAiMeeting && (
              <Flex vertical gap={8}>
                <Flex justify={"space-between"} align={"center"}>
                  <Flex>
                    <Typography.Paragraph className={"label"}>Meeting Link</Typography.Paragraph>
                    <Button
                      className={"link"}
                      style={{ marginLeft: "6px", pointerEvents: checkingcustomlinkavail === "checking" ? "none" : "auto" }}
                      onClick={checkLinkAvail}
                    >
                      Check{checkingcustomlinkavail === "checking" ? "ing" : ""} link availability{" "}
                      {!checkingcustomlinkavail ? "" : checkingcustomlinkavail === "checking" ? <Loader size={"small"} style={{ marginLeft: "10px" }} /> : ""}
                    </Button>
                    {checkingcustomlinkavail === "avail" ? (
                      <Flex justify={"center"} align="center" style={{ marginLeft: "5px" }}>
                        <SvgIcon type={"success"} width={15} height={15} />
                        <span style={{ color: "lightgreen", fontWeight: 500, fontSize: "12px", margin: "auto 0 auto 5px" }}>Available</span>
                      </Flex>
                    ) : checkingcustomlinkavail === "youhaveitalr" ? (
                      <span style={{ color: "violet", fontWeight: 500, fontSize: "12px", margin: "auto 0 auto 5px" }}>Available (Your link already)</span>
                    ) : checkingcustomlinkavail === "notavail" ? (
                      <span style={{ color: "red", fontWeight: 500, fontSize: "12px", margin: "auto 0 auto 5px" }}>Not Available</span>
                    ) : (
                      ""
                    )}
                  </Flex>
                  <Button className={"copy-btn"} icon={<SvgIcon type={"copy"} />} onClick={handleCopyMeetingLinkBtnClick} />
                </Flex>
                <Flex align={"center"} gap={8}>
                  <Typography.Paragraph className={"label"} style={{ flexShrink: 0 }}>
                    App.Meetz.Ai/Cal/
                  </Typography.Paragraph>
                  <Form.Item name={"link"} initialValue={meetingLinkData.links[0].href.replace("App.Meetz.Ai/Cal/", "")} style={{ width: "100%" }}>
                    <Input
                      placeholder={"Enter the path"}
                      // onChange={(e) => {
                      //   const value = e.target.value.replace(/\s+/g, "");
                      //   e.target.value = value;
                      // }}
                      onKeyDown={(e) => {
                        const invalidChars = [
                          " ",
                          "!",
                          "@",
                          "#",
                          "$",
                          "%",
                          "^",
                          "&",
                          "*",
                          "(",
                          ")",
                          "=",
                          "{",
                          "}",
                          "[",
                          "]",
                          "|",
                          "\\",
                          ":",
                          ";",
                          "'",
                          '"',
                          "<",
                          ">",
                          ",",
                          "?",
                          "`",
                          "~",
                          "/",
                        ];
                        // If the pressed key is an invalid character, prevent input
                        if (invalidChars.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Flex>
              </Flex>
            )}
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>
                Meeting Title{" "}
                {!!isEditAiMeeting && (
                  <Tooltip
                    placement={"bottom"}
                    title={
                      <>
                        All of the below properties are dynamic, what you set here will be the default values.
                        <br />
                        <br />
                        Example: If you tell your assistant to book a meeting titled &quot;Onboarding call with John&quot;. So in this case your assistant will
                        book the meeting with your title, many times you won&apos;t have to specifically ask for customizations, simply based on the
                        conversations context your assistant will do this automatically!{" "}
                      </>
                    }
                  >
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                  </Tooltip>
                )}
              </Typography.Paragraph>
              <Form.Item name={"name"} rules={[{ required: true, message: "Please enter a default meeting title" }]}>
                <Input placeholder={"Enter the meeting name"} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting Description</Typography.Paragraph>
              <Form.Item name={"description"}>
                <TextArea rows={4} placeholder={"Enter the meeting description"} />
              </Form.Item>
            </Flex>

            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting Length</Typography.Paragraph>
              <Form.Item name={"meetingLength"}>
                <CustomSelect options={meetingTimeOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Timezone</Typography.Paragraph>
              <Form.Item name={"timezone"}>
                <CustomSelect
                  options={timezoneOptions}
                  onChange={handleChangeTimeZone}
                  value={timezoneSelected?.length ? `${timezoneSelected[0]} ${timezoneSelected[1]}` : ""}
                />
                {/* <SelectFilterItem data={timezoneOptions} form={form} fieldName={"lastName"} placeholder={"Smith"}/> TODOF later switch tz to this so they can search it quickly just change the field name which is in there */}
                {/* // TODOF add foolwup and delay between followups */}
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <WeekTimePlannerWithTabs
                scheduling
                initialAvail={meetingLinkData.schedule}
                setInitialAvail={handleUpdateSched}
                formItemName={["availability"]}
                className={"meeting-link-week-planner"}
                //  onChange={updateSchedule}
              />
            </Flex>
            {isEditAiMeeting ? (
              <Flex vertical gap={8}>
                <Typography.Paragraph className={"label"}>
                  Guests working hours{" "}
                  <Tooltip
                    placement={"bottom"}
                    title={
                      <>
                        If your Ai Email Scheduling Assistant finds out that your guest is based in a different timezone than you mid-conversation we will offer
                        times between these times in your guests working hours.
                        <br />
                        <br />
                        *Recommended to keep this range as wide as possible so that the assistant will have enough cross times to offer availability and easily
                        book a meeting.
                      </>
                    }
                  >
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                  </Tooltip>
                </Typography.Paragraph>
                <Form.Item name={"prospectSchedule"} rules={[{ required: true, message: "Add times that we can book in your guests timezone" }]}>
                  <TimePicker.RangePicker
                    needConfirm={false}
                    allowClear={false}
                    minuteStep={15}
                    className={"time-picker"}
                    format={"h:mm A"}
                    placeholder={["No min time", "No max time"]}
                  />
                </Form.Item>
              </Flex>
            ) : (
              <>
                {/* TODOF <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Maximum Timeslots To Offer Per Day</Typography.Paragraph>
              <Form.Item name={"maximumTimeslotsToOfferPerDay"}>
                <TimePicker.RangePicker
                  className={"time-picker"}
                  format={"h"}
                  placeholder={["No Max time", "No Max time"]}
                />
              </Form.Item>
            </Flex> */}
                <Flex vertical gap={8}>
                  <Typography.Paragraph className={"label"}>Maximum Days Guest Can Schedule In The Future</Typography.Paragraph>
                  <Form.Item name={"maximumDaysToScheduleForward"}>
                    <CustomSelect options={maximumDaysToScheduleForwardOptions} />
                  </Form.Item>
                </Flex>{" "}
              </>
            )}
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Minimum Time To Book In Advance</Typography.Paragraph>
              <Form.Item name={"minimumTimeToBookInAdvance"}>
                <CustomSelect options={minimumTimeToBookInAdvanceOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Buffer Time Between Meetings</Typography.Paragraph>
              <Form.Item name={"bufferTimeBetweenMeetings"}>
                <CustomSelect options={bufferTimeBetweenMeetings} />
              </Form.Item>
            </Flex>
            {!isEditAiMeeting && (
              <Flex vertical>
                <Typography.Paragraph className={"label"} style={{ marginBottom: "12px" }}>
                  {reminders?.length ? `Reminders before the meeting` : `Reminders before the meeting - None set yet`}
                </Typography.Paragraph>
                {/* <Form.Item name={"reminders"}> */}
                {reminders?.length ? (
                  <Collapse
                    // initialOpenKey={stupidFormHelper}
                    items={reminders}
                    // controlActKeys={controlActKeys}
                    // setControlActKeys={setControlActKeys}
                    // onChange={(keys) => setRemindersActiveKeys(keys as string[])}
                  />
                ) : (
                  ""
                )}
                {/* </Form.Item> */}
                {reminders?.length <= 6 && (
                  <Button className={"link"} onClick={handleAddReminder}>
                    Add Reminder
                  </Button>
                )}
              </Flex>
            )}
            {/* TODOF and in schedule add this too {!isEditAiMeeting && <>   <Flex vertical gap={8}>
            <Typography.Paragraph className={"label"}>Questions For Guests</Typography.Paragraph>
              <Flex vertical={true} gap={16}>
                { questionsForGuests.map((question: any, index: number) => (
                  <Flex vertical={true} gap={8} key={index}>
                    <Flex align={"center"}>
                      <Typography.Paragraph className={"question-count"}>{index + 1} Question</Typography.Paragraph>
                      <Flex gap={8} style={{ marginLeft: "auto" }}>
                        <CustomSelect
                          options={questionRequiredOptions}
                          defaultValue={question.isRequired ? "Required" : "Not Required"}
                          className={"question-select"}
                          onChange={value => handleIsRequiredQuestionChange(question.id, value)}
                        />
                        <CustomSelect
                          options={questionTypeOptions}
                          defaultValue={question.type || "Opened Question"}
                          className={"question-select"}
                          onChange={value => handleQuestionTypeChange(question.id, value)}
                        />
                      </Flex>
                    </Flex>
                    {question.type === "Opened Question" && (
                      <Input defaultValue={question.text || ""} placeholder={"Enter the question"} />
                    )}
                    {question.type === "Multiple Choice Question" && (
                      <>
                        <Flex vertical={true} gap={4}>
                          <Typography.Paragraph className={"small-label"}>Title</Typography.Paragraph>
                          <Input
                            defaultValue={question.text || ""}
                            placeholder={"Title"}
                            onChange={e => handleQuestionInputChange(question.id, e)}
                          />
                        </Flex>
                        <Flex vertical={true} gap={4} style={{ width: "100%" }}>
                          <Flex justify={"space-between"} align={"center"}>
                            <Typography.Paragraph className={"small-label"}>Choices</Typography.Paragraph>
                            <Button className={"question-btn"} onClick={() => handleAddChoiceBtnClick(question.id)}>
                              Add Choice
                            </Button>
                          </Flex>
                          {question.choices.map((choice: any,i) => (
                            <Flex align={"center"} gap={20} key={i}>
                              <Input
                                defaultValue={choice.text || ""}
                                placeholder={"Choice"}
                                onChange={e => handleChoiceInputChange(question.id, choice.id, e)}
                              />
                              <SvgIcon
                                type={"close"}
                                className={"delete-choice-close-btn"}
                                onClick={() => handleDeleteChoiceBtnClick(question.id, choice.id)}
                              />
                            </Flex>
                          ))}
                        </Flex>
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
              <Button className={"link"} style={{ marginTop: 8 }} onClick={handleAddQuestionBtnClick}>
                Add
              </Button>
            </Flex></>} */}
            <Modal open={!!reminderEditing} onCancel={() => setReminderEditing(null)} title={"Edit reminder"}>
              {/* {reminders?.find((rm) => rm?.id === reminderEditing)?.value?.email_body} */}
              <Flex justify={"space-between"} style={{ marginBottom: "15px" }} align={"center"}>
                <Typography.Paragraph className={"label"}>
                  Time to remind before the meeting{" "}
                  {reminderTimesNotAvail?.length && (
                    <Tooltip title={"Some reminder times are already taken from other reminders you have."}>
                      {" "}
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>
                  )}
                </Typography.Paragraph>{" "}
                <CustomSelect
                  options={reminderTimeOptions?.map((opt) => ({ ...opt, disabled: reminderTimesNotAvail?.some((rm) => rm === opt?.value) }))}
                  isSearchSelect={false}
                  value={changeReminderMinutesBef}
                  onChange={(value) => setChangeReminderMinutesBef(value)}
                  style={{ width: "150px" }}
                />
              </Flex>
              <Flex justify={"space-between"} style={{ marginBottom: "15px" }} align={"center"}>
                <Typography.Paragraph className={"label"}>
                  Send this reminder only if the meeting guests did not confirm meeting yet{" "}
                  {/* {reminderTimesNotAvail?.length && (
                    <Tooltip title={"Some reminder times are already taken from other reminders you have."}>
                      {" "}
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>
                  )} */}
                </Typography.Paragraph>{" "}
                <CustomSelect
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  isSearchSelect={false}
                  value={changeReminderOnlyOnConfirmation ? "Yes" : "No"}
                  onChange={(value) => setChangeReminderOnlyOnConfirmation(value === "Yes" ? true : false)}
                  style={{ width: "150px" }}
                />
              </Flex>
              <Flex justify={"space-between"} style={{ marginBottom: "15px" }} align={"center"}>
                <Typography.Paragraph className={"label"}>
                  Reminder type
                  <Tooltip
                    title={
                      <>
                        <b>Email:</b> Email reminder sent to you and the guests to remind of the meeting coming up. Meeting details variable will include all of
                        the meeting information in your guests timezone. <br />
                        <br />
                        <b>Native:</b> A native reminder that comes directly from Google/Outlook calendar which is more organic and subtle.
                      </>
                    }
                  >
                    {" "}
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                  </Tooltip>
                </Typography.Paragraph>{" "}
                <CustomSelect
                  options={[
                    { label: "Email", value: "email" },
                    { label: "Native", value: "naturalping" },
                  ]}
                  isSearchSelect={false}
                  value={changeReminderType}
                  onChange={(value) => setChangeReminderType(value)}
                  style={{ width: "150px" }}
                />
              </Flex>
              {changeReminderType === "email" && (
                <>
                  {" "}
                  <Flex justify={"space-between"} style={{ marginBottom: "5px" }}>
                    <Typography.Paragraph className={"label"}>Email Subject</Typography.Paragraph>{" "}
                  </Flex>
                  <TextArea value={changeReminderSubject} onChange={(e) => setChangeReminderSubject(e?.target?.value)} rows={1} />
                  <Flex justify={"space-between"} style={{ marginBottom: "5px", marginTop: "15px" }}>
                    <Typography.Paragraph className={"label"}>
                      Email Body{" "}
                      <Tooltip
                        title={
                          "FAQ: Why are there no first name or job title variables here? Most meetings you will book here Meetz will only have their email and not all of their information."
                        }
                      >
                        {" "}
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                      </Tooltip>
                    </Typography.Paragraph>{" "}
                    <Button className={"link"} onClick={handleCopyMeetingDetBtnClick}>
                      Copy meeting details variable
                    </Button>
                  </Flex>
                  <TextEditor isMultiLined={false} content={changeReminderBody} onContentChange={(e) => setChangeReminderBody(e)} isMenuBar={false} />{" "}
                </>
              )}
              <ConfirmButtons
                className={"confirm-buttons"}
                leftButtonTitle={"Discard changes"}
                rightButtonTitle={"Update"}
                handleSubmit={handleUpdateReminderStep}
                // onCancel={handlePrevStepClick}
                style={{ marginTop: "20px" }}
              />
            </Modal>
          </Flex>
        </Form>
      </Drawer>
      {isAddVariableModalOpened && <AddVariableModal open={true} onSubmit={handleAddVariableSubmit} onCancel={closeAddVariableModal} />}
    </>
  );
};
