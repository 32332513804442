import { Radio } from "antd";
import React, { FC } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";

type MoveProspectsSelectStepModalProps = {
  open: boolean;
  onCancel: VoidFunction;
};

export const MoveProspectsSelectStepModal: FC<MoveProspectsSelectStepModalProps> = ({ open, onCancel }) => {
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6", "Step 7"];
  const closeModal = () => {
    onCancel();
  };

  const handleFormSubmit = () => {
    // console.log("Created")
    onCancel();
  };

  return (
    <Modal width={530} open={open} title={"Select Step"} onCancel={closeModal}>
      <Radio.Group style={{ display: "flex", flexDirection: "column", gap: 8, marginBottom: 28 }}>
        {steps.map((step, index) => (
          <Radio key={`${index}-${step}`} value={step}>
            {step}
          </Radio>
        ))}
      </Radio.Group>
      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Continue"} onCancel={closeModal} handleSubmit={handleFormSubmit} />
    </Modal>
  );
};
