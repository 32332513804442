import { Cascader, Checkbox, Flex, Tooltip, Typography } from "antd";
import { FC } from "react";
import { CustomLabel, CustomSelect, Drawer } from "src/components/ui";
import "./index.less";
import { SvgIcon } from "src/shared/icons";

interface ProspectsFilterProps {
  onClose: () => void;
  uplNameOptions: string[];
  uploadNameToSearch: string;
  recentlyUpdatedToSearch: string;
  includeInCampaignToSearch: string;
  includeContactedAlreadyToSearch: string;
  addProsToCamp: boolean;
  crm: boolean;
  setUploadNameToSearch: () => void;
  setRecentlyUpdatedToSearch: () => void;
  setIncludeInCampaignToSearch: () => void;
  setIncludeContactedAlreadyToSearch: () => void;
  submitFilters: () => void;
  setExcludeDoNotContact: () => void;
  campaignInName;
  setCampaignInName;
  campaignNames;
  excludeDoNotContact;
  setProsStatus;
  prosStatusArr;
}

const { Text } = Typography;

export const ProspectsFilter: FC<ProspectsFilterProps> = ({
  onClose,
  uplNameOptions,
  uploadNameToSearch,
  setUploadNameToSearch,
  setRecentlyUpdatedToSearch,
  // setIncludeInCampaignToSearch,
  setIncludeContactedAlreadyToSearch,
  recentlyUpdatedToSearch,
  includeInCampaignToSearch,
  includeContactedAlreadyToSearch,
  submitFilters,
  addProsToCamp,
  crm,
  campaignInName,
  setCampaignInName,
  campaignNames,
  setExcludeDoNotContact,
  excludeDoNotContact,
  setProsStatus,
  prosStatusArr,
}) => {
  return (
    <Drawer
      open={true}
      title={"Filter Uploaded Prospects"}
      leftButtonTitle={"Close"}
      rightButtonTitle={"Search"}
      onBtnPrimaryClick={submitFilters}
      // rightButtonDis={!selectedMeetingTool}
      onBtnSecondaryClick={onClose}
      onCancel={onClose}
      rightButtonDis={!prosStatusArr?.length}
      className={"filter-drawer"}
    >
      <div className={"filter-drawer-inside"}>
        <>
          <Flex vertical gap={8}>
            <Typography.Paragraph className={"label"}>Prospects in a campaign</Typography.Paragraph>
            <CustomSelect options={campaignNames} value={campaignInName} onChange={setCampaignInName} />
          </Flex>
        </>
        {!!crm && (
          <>
            <br />
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>
                Prospect status{" "}
                <Tooltip
                  placement={"right"}
                  title={
                    <>
                      {" "}
                      <b>Can be contacted:</b> Contacts just uploaded, in a campaign, or didn't take any action.
                      <br />
                      <br />
                      <b>Taken out of campaign:</b> Contacts that either you marked as do not contact, unsubscribed, or any of the subcategory reasons for not
                      being able to continue getting campaigns in Meetz. You can still contact them manually in Meetz or "Mark as allow contact" in the CRM.
                      <br />
                      <br />
                      <br />
                      Advanced FAQ: <br />
                      <b> Why can't I see the prospects I'm filtering for?</b> If you're trying to filter for "Good call outcome" and you marked the prospect as
                      "Do not call" you will need to find them by allowing a search of "Good call outcome" and "Marked here as DNC" to see them. <br />
                      <b>Solution: </b>
                      You can <u>mark the whole "Taken out of campaign" category</u> to see all prospects +{" "}
                      <u>Check below filter "Also include prospects that you emailed/called"</u>.
                    </>
                  }
                >
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 3, marginRight: 10, cursor: "pointer" }} />
                </Tooltip>
                <span style={{ fontSize: "12px" }}> - Select at least 1</span>
              </Typography.Paragraph>{" "}
              {/* <CustomSelect options={campaignNames} value={campaignInName} onChange={setCampaignInName} /> */}
              <Cascader
                style={{ width: "100%", borderRadius: "8px", border: !prosStatusArr?.length ? ".5px solid red" : "0px solid #d9d9d9" }}
                options={[
                  {
                    label: "Can be contacted",
                    value: "canbecontacted",
                    // children: [
                    //   {
                    //     label: "Bed-and-Breakfasts, Hostels, Homestays",
                    //     value: "Bed-and-Breakfasts, Hostels, Homestays",
                    //   },
                    // ],
                  },
                  // {
                  //   label: "Already got email or call",
                  //   value: "alreadygotemailorcall",
                  //   // children: [
                  //   //   {
                  //   //     label: "Bed-and-Breakfasts, Hostels, Homestays",
                  //   //     value: "Bed-and-Breakfasts, Hostels, Homestays",
                  //   //   },
                  //   // ],
                  // },
                  {
                    label: "Taken out of campaigns",
                    value: "not_in_campaign",
                    children: [
                      {
                        label: "Unsubscribed",
                        value: "unsub",
                      },
                      {
                        label: "Bounced",
                        value: "bounced",
                      },
                      {
                        label: "Good call outcome",
                        value: "goodcalloutcome",
                      },
                      {
                        label: "Bad call outcome",
                        value: "badcalloutcome",
                      },
                      {
                        label: "Replied with SMS",
                        value: "smsin",
                      },
                      {
                        label: "Replied with email",
                        value: "emailin",
                      },
                      {
                        label: "You marked as DNC after call",
                        value: "markeddnc",
                      },
                      {
                        label: "Part of your suppression list",
                        value: "suppressed",
                      },
                      {
                        label: "Marked here as DNC",
                        value: "markedmanuallyasdonotcontact",
                      },
                    ],
                  },
                ]}
                onChange={setProsStatus}
                placement={"bottomRight"}
                // tagRender={() => <></>}
                // searchValue={prosStatusArr}
                value={prosStatusArr}
                // onSearch={onIndustrySearch}
                multiple
                // displayRender={prosStatusArr.length > 0 ? `${prosStatusArr.length} selected` : "Please select"}
                placeholder="Select at least one"
                // showSearch
              />
            </Flex>
          </>
        )}
        <br />{" "}
        <Flex vertical gap={8}>
          <Typography.Paragraph className={"label"}>Upload name</Typography.Paragraph>
          <CustomSelect options={uplNameOptions} value={uploadNameToSearch} onChange={setUploadNameToSearch} />
        </Flex>
        <br />
        <br />
        <Flex vertical gap={8}>
          <CustomLabel title={"Additional Filters"} />
          <Checkbox checked={recentlyUpdatedToSearch} onChange={setRecentlyUpdatedToSearch}>
            Keep only most recently uploaded prospects
          </Checkbox>
        </Flex>{" "}
        {/* backend needs to get contacts going into a campaign that didnt unsub or opt out this will ensure that */}
        {/* {!!crm && (
          <>
            {" "}
            <br />
            <Flex vertical gap={8}>
              <Checkbox checked={excludeDoNotContact} onChange={setExcludeDoNotContact}>
                Exclude prospects marked as do not contact{" "}
                <Tooltip
                  placement={"right"}
                  title={
                    'This includes prospects that are marked as "Do Not Contact", unsubscribed, responded to your email, had a positive or negative call with you, or sent you an SMS. All of these actions will automatically move your prospects to "DNC" in the campaigns to prevent overlap or annoying your prospects.'
                  }
                >
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                </Tooltip>
              </Checkbox>
            </Flex>
          </>
        )} */}
        <br />
        {/*
        <Flex vertical gap={8}>
          <Checkbox checked={includeInCampaignToSearch} onChange={setIncludeInCampaignToSearch}>
            Also include prospects that are now in a campaign
          </Checkbox>
        </Flex> 
        <br />*/}
        <Flex vertical gap={8}>
          <Checkbox checked={includeContactedAlreadyToSearch} onChange={setIncludeContactedAlreadyToSearch}>
            Also include prospects that you emailed/called
          </Checkbox>
        </Flex>
        <br />
        <Text className={"content-footnote"}>
          *Prospect's that unsusbcribe or are marked as "Do Not Call" by you are automatically not included in the results
          {!!addProsToCamp && ". Also any prospect in this campaign will be excluded automatically."}
        </Text>
      </div>
    </Drawer>
  );
};
