import { Form, TabsProps, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Drawer } from "src/components/ui/Drawer";
import { emailsAddressesData } from "../../info";
import { EmailAddressesTab, EmailSchedulingTab } from "./ui";
import { useDispatch, useSelector } from "react-redux";
import { fullUserSelector } from "src/store/user";
import { removeQueryFromUrl } from "src/components/app/AuthRoutes";
import { outreachEmailsSelector } from "src/store/outreach-emails";
import dayjs from "dayjs";
import { patchEmailSettings } from "src/store/outreach-emails/asyncRequests";
import { AppDispatch } from "src/store";
import { CampaignTabModal } from "./ui/EmailAddressesTab/ui/CampaignTabModal";

type ExploreFilterProps = {
  open: boolean;
  onCancel: VoidFunction;
  campaign: any;
};

export const ManageEmailDrawer: FC<ExploreFilterProps> = ({ open, onCancel, campaign }) => {
  const [form] = Form.useForm();
  // console.log(campaign, "campaigncampaign");

  const emailCampDrawerOpen = !!campaign?.id;

  const closeModal = () => {
    onCancel();
    form.resetFields();
  };

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(fullUserSelector);

  const { first_name } = user;
  const [firstName, setFirstName] = useState("");
  const [businessAdd, setBusinessAdd] = useState("");
  const [unsubSent, setUnsubSent] = useState("");
  const [timezoneSelected, settimezoneSelected] = useState<any>("");
  const [schedAvail, setSchedAvail] = useState<any>([]);

  const { additionalEmailsSched, unsub_sentence: unsubDb, business_addr: busAddDb } = useSelector(outreachEmailsSelector);

  useEffect(() => {
    // TODO work on why its not workign for this to update. consoel log the working meeting link and this one + when i dont change teh amount it gets stuck
    // console.log(additionalEmailsSched,'additionalEmailsSched',Object.fromEntries(Object.entries(additionalEmailsSched).map(([key, value]) => [key, value.map(timerange => ([dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")] ))])));
    // console.log(additionalEmailsSched, "additionalEmailsSched");
    setSchedAvail(
      Object.fromEntries(
        Object.entries(additionalEmailsSched).map(([key, value]) => [
          key,
          value.map((timerange) => [dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")]),
        ])
      )
    );
    // setSchedAvail(Object.fromEntries(Object.entries(additionalEmailsSched).map(([key, value]) => [key, value.map(timerange => ([timerange.start, timerange.end] ))])))
  }, [additionalEmailsSched, open]);

  useEffect(() => {
    // console.log(first_name, "first_name");
    setFirstName(first_name);
  }, [first_name, open]);

  useEffect(() => {
    setBusinessAdd(busAddDb);
  }, [busAddDb, open]);

  const replaceLinkPlaceholders = (content) => {
    return content?.replace(/{{startlink}}(.*?){{endlink}}/g, '<a class="link" href="" target="_blank" rel="noopener noreferrer nofollow">$1</a>');
  };
  const replaceLinksWithPlaceholders = (content) => {
    // removes more than 1 link in unsub
    let processedContent = content?.replace(/<a\s+[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi, "{{startlink}}$2{{endlink}}");

    // Remove start and end <p> tags
    processedContent = processedContent
      ?.replace(/^<p>|<\/p>$/g, "") // Remove start and end <p> tags
      ?.replace(/<\/p>\s*<p>/g, "\n"); // Replace remaining <p> tags with newlines

    // Remove duplicate placeholders after the first occurrence
    const firstLinkIndex = processedContent.indexOf("{{startlink}}");
    if (firstLinkIndex !== -1) {
      const firstEndLinkIndex = processedContent.indexOf("{{endlink}}", firstLinkIndex) + 11;
      const beforeFirstLink = processedContent.slice(0, firstEndLinkIndex);
      const afterFirstLink = processedContent.slice(firstEndLinkIndex).replace(/{{startlink}}.*?{{endlink}}/g, "");
      processedContent = beforeFirstLink + afterFirstLink;
    }

    return processedContent;
  };

  // useEffect(() => {
  // console.log(unsubSent, "unsubSent");
  // }, [unsubSent, open]);

  useEffect(() => {
    // console.log(unsubDb, "unsubDb");
    setUnsubSent(`<p>${unsubDb?.length ? replaceLinkPlaceholders(unsubDb) : " "}</p>`);
  }, [unsubDb, open]);

  const handleNameChange = (event) => {
    // setFirstName('1')
    setFirstName(event?.target?.value);
  };
  const handleAddrChange = (event) => {
    // setFirstName('1')
    setBusinessAdd(event?.target?.value);
  };
  const handleUnsubChange = (event) => {
    // setFirstName('1')
    //  console.log(event,'unsubSent1')
    setUnsubSent(event);
  };

  const items: TabsProps["items"] = [
    //  emailCampDrawerOpen && {
    //   key: "1",
    //   label: "Campaign",
    //   children: <CampaignTabModal  />,
    //

    // }, causes tabs to shake, antd bug. not good
    {
      key: "1",
      label: "Email Addresses",
      children: (
        <EmailAddressesTab handleNameChange={handleNameChange} firstName={firstName} data={emailsAddressesData} emailCampDrawerOpen={emailCampDrawerOpen} />
      ),
    },
    {
      key: "2",
      label: "Email Sending Settings",
      children: (
        <EmailSchedulingTab
          settimezoneSelected={settimezoneSelected}
          timezoneSelected={timezoneSelected}
          schedAvail={schedAvail}
          setSchedAvail={setSchedAvail}
          handleAddrChange={handleAddrChange}
          businessAdd={businessAdd}
          unsubSent={unsubSent}
          handleUnsubChange={handleUnsubChange}
          emailCampDrawerOpen={emailCampDrawerOpen}
        />
      ),
    },
  ]?.filter(Boolean);

  const handleSubmit = () => {
    let schedResult = {};
    for (const [day, periods] of Object.entries(schedAvail)) {
      if (periods?.length > 0) {
        schedResult[day] = periods.map((period) => {
          if (period.start) {
            return period;
          } else {
            const [start, end] = period;
            return {
              start: dayjs(start, "HH:mm").format("HH:mm"),
              end: dayjs(end, "HH:mm").format("HH:mm"),
            };
          }
        });
      }
    }
    // console.log(schedAvail, "afsdasdf", schedResult);
    // console.log(firstName,
    // timezoneSelected,
    // schedAvail,'handleSubmithandleSubmit');

    const isEmpty = Object.keys(schedResult).length === 0 && schedResult.constructor === Object;
    if (isEmpty) {
      schedResult = {
        monday: [
          {
            start: "9:00",
            end: "16:00",
          },
        ],
        tuesday: [
          {
            start: "9:00",
            end: "16:00",
          },
        ],
        wednesday: [
          {
            start: "9:00",
            end: "16:00",
          },
        ],
        thursday: [
          {
            start: "9:00",
            end: "16:00",
          },
        ],
        friday: [
          {
            start: "9:00",
            end: "16:00",
          },
        ],
      };
      message.error("Sending times must have days available. Autopopulating it with times.");
    } else {
      message.info("Updating email settings");
    }

    const unsubscribe = replaceLinksWithPlaceholders(unsubSent);
    // console.log(unsubscribe, "fixunsubscribe");
    dispatch(patchEmailSettings({ firstName, timezoneSelected, schedAvail: schedResult, businessAdd, unsubscribe }));
    onCancel();
  };

  return (
    <Drawer
      width={"550px"}
      open={open}
      onCancel={closeModal}
      leftButtonTitle={"Close"}
      rightButtonTitle={"Save"}
      onBtnPrimaryClick={handleSubmit}
      rightButtonDis={!firstName?.length}
      onBtnSecondaryClick={closeModal}
      tabItems={items}
      title={emailCampDrawerOpen && "Manage Account Email Settings"}
      // defaultActiveKey={null}
    />
  );
};
