import { MenuProps, Row, Typography, Tooltip, Flex, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { SvgIcon } from "src/shared/icons";
import { DotsMenuDropdown } from "../DotsMenuDropdown";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, resetOutcome, setOpenCampSettings } from "src/store/outreach-campaigns";
import { getFilteredLeads, getFilteredLeadsRst, pauseCampaign } from "src/store/outreach-campaigns/asyncRequests";
import { userIsPayingSelector, userIsTrialSelector } from "src/store/user";
import { useParams } from "react-router";

interface PageTitleProps {
  title: string;
  onClick?: VoidFunction;
  hasAction?: boolean;
  status?: string;
}

export const PageTitle: FC<PageTitleProps> = ({ title, onClick, hasAction = false, status }) => {
  const payingClient = useSelector(userIsPayingSelector);
  const trialClient = useSelector(userIsTrialSelector);
  const dispatch = useDispatch();
  const handleInactiveBadgeClick = () => {
    if (!payingClient && !trialClient) {
      message.info(`Upgrade account to activate campaign`);
      return;
    }
    dispatch(setOpenCampSettings(true));
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Activate campaign",
      onClick: handleInactiveBadgeClick,
    },
    // {
    //   key: "2",
    //   label: "Rename campaign",
    // }, TODOnn
    // {
    //   key: "3",
    //   label: "Turn on autobooking",
    // },
  ];
  const deactItems: MenuProps["items"] = [
    {
      key: "1",
      label: "Pause campaign",
      onClick: () => dispatch(pauseCampaign({ campId: campaignDisplaying?.campaign?.id })),
    },
    {
      key: "2",
      label: "Rename campaign",
    },
    // {
    //   key: "3",
    //   label: "Turn on autobooking",
    // },
  ];
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [truncationLength, setTruncationLength] = useState(45);

  const updateTruncationLength = () => {
    const width = window.innerWidth;
    // console.log(width, "width");
    if (width < 1300) {
      setTruncationLength(45);
    } else if (width < 1500) {
      setTruncationLength(57);
    } else if (width < 2000) {
      setTruncationLength(105);
    } else if (width < 3000) {
      setTruncationLength(135);
    } else {
      setTruncationLength(395);
    }
  };

  useEffect(() => {
    updateTruncationLength(); // Initial calculation
    window.addEventListener("resize", updateTruncationLength);
    return () => {
      window.removeEventListener("resize", updateTruncationLength);
    };
  }, []);

  const { successfullUpdateMsg, failedUpdateMsg, infoUpdateMsg, outreachCampaignsList, campaignDisplaying } = useSelector(outreachCampaignSelector);
  useEffect(() => {
    if (successfullUpdateMsg) {
      message.destroy();
      message.success(successfullUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [successfullUpdateMsg]);
  useEffect(() => {
    if (failedUpdateMsg) {
      message.destroy();
      message.error(failedUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [failedUpdateMsg]);
  useEffect(() => {
    if (infoUpdateMsg) {
      message.destroy();
      message.info(infoUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [infoUpdateMsg]);

  const campRecord = outreachCampaignsList?.find((oc) => oc?.overview?.campId === campaignDisplaying?.campaign?.id);

  const currentUrl = window.location.href;
  const onProsPage = currentUrl.endsWith("/prospects");
  // console.log(currentUrl, "currentUrl", onProsPage);
  const handleResetAndGoBack = () => {
    dispatch(
      getFilteredLeadsRst({
        uploadName: "All",
        recentlyUpdated: false,
        includeInCampaign: false,
        includeContactedAlready: false,
        page: 1,
        pageChange: false,
        selectedCampaignId: null,
      })
    );
    onClick && onClick();
  };
  const { id } = useParams();
  const selectedCampaignId = (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000;
  const selectedCampaign = outreachCampaignsList?.find((item) => +item?.overview?.campId === selectedCampaignId);

  return (
    <Row align={"middle"} style={{ gap: 16 }}>
      {onClick ? <SvgIcon type={"arrowBack"} onClick={handleResetAndGoBack} style={{ cursor: "pointer" }} /> : null}
      {/* {status ? ( */}
      <Flex align={"center"} gap={8}>
        <Tooltip title={selectedCampaign?.overview?.isActive ? "Active Campaign" : "Inactive Campaign "} placement={"bottom"}>
          <Row
            style={{
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: selectedCampaign?.overview?.isActive ? "#0FCA7A" : "#FAAD14",
              cursor: selectedCampaign?.overview?.isActive || onProsPage ? "default" : "pointer",
            }}
            onClick={!onProsPage && !selectedCampaign?.overview?.isActive && handleInactiveBadgeClick}
          />
        </Tooltip>
        <Flex>
          <div>
            <Typography.Title level={3} className={"page-title"} style={{ marginTop: 0 }}>
              {status ? (
                "Campaign: "
              ) : selectedCampaign?.overview?.isActive ? (
                "Campaign: "
              ) : (
                <span style={{ color: `#FAAD14`, cursor: onProsPage ? "default" : "pointer" }} onClick={handleInactiveBadgeClick}>
                  Inactive Campaign:{" "}
                </span>
              )}
              {title?.length > truncationLength ? (
                <Tooltip placement={"bottom"} title={`${title}`}>
                  {title?.substring(0, truncationLength)}...
                </Tooltip>
              ) : (
                title
              )}
              {/* sss */}
            </Typography.Title>
          </div>
          {hasAction ? <DotsMenuDropdown menuItems={!campRecord?.overview?.isActive ? items : deactItems} /> : null}
        </Flex>
      </Flex>
      {/* ) : (
        <Typography.Title level={2} className={"page-title"}>
          {title}
        </Typography.Title>
      )} */}
    </Row>
  );
};
